import React from 'react';

export const powerTooltips = {
  amps: (
    <div className="space-y-2">
      <h4 className="font-semibold text-gray-900">Amperage (Amps): The Flow of Electricity</h4>
      <p>Think of electricity like water flowing through a garden hose:</p>
      <ul className="list-disc pl-4 space-y-1">
        <li>Amperage is the amount of water flowing through the hose at a time</li>
        <li>More amps mean more "electric water" flowing</li>
      </ul>
      <p className="font-medium mt-2">Practical Example:</p>
      <p>A toaster might use 10 amps of electricity, while a small LED light uses less than 1 amp.</p>
    </div>
  ),
  watts: (
    <div className="space-y-2">
      <h4 className="font-semibold text-gray-900">Wattage (Watts): The Power Being Used</h4>
      <p>Imagine a water wheel powered by the flow of water:</p>
      <ul className="list-disc pl-4 space-y-1">
        <li>Wattage is the strength of the water hitting the wheel, turning it to do work</li>
        <li>It combines the flow (amperage) and the pressure (voltage) to measure total power</li>
        <li>The formula is Watts = Volts × Amps</li>
      </ul>
      <p className="font-medium mt-2">Practical Example:</p>
      <p>A 60-watt lightbulb uses 60 watts of power, while a hairdryer might use 1,200 watts.</p>
    </div>
  ),
  kwh: (
    <div className="space-y-2">
      <h4 className="font-semibold text-gray-900">Kilowatt-Hours (kWh): The Energy You've Used Over Time</h4>
      <p>Imagine a car driving on a highway:</p>
      <ul className="list-disc pl-4 space-y-1">
        <li>Kilowatt-hours measure how far you've driven based on your speed (wattage) and how long you've been driving</li>
        <li>It's a measure of total energy consumed</li>
        <li>1 kilowatt-hour = 1,000 watts used for 1 hour</li>
      </ul>
      <p className="font-medium mt-2">Practical Example:</p>
      <p>If you run a 1,000-watt space heater for 3 hours, you'll use 3 kWh of electricity.</p>
    </div>
  ),
  inverter: (
    <div className="space-y-2">
      <h4 className="font-semibold text-gray-900">What is an Inverter?</h4>
      <p>Think of an inverter like a universal translator for electricity:</p>
      <ul className="list-disc pl-4 space-y-1">
        <li>Converts battery power into the type your home can use - like translating one language to another</li>
        <li>Works as a traffic controller, managing power flow between your home, batteries, and solar</li>
        <li>Automatically switches to battery power during outages - like a backup generator</li>
      </ul>
      <p className="font-medium mt-2">Each inverter can handle:</p>
      <p>33.3 amps of continuous power at 240V - enough to run multiple major appliances at once</p>
    </div>
  )
};