import React from 'react';
import { useNavigate } from 'react-router-dom';
import MegaMenuSection from './MegaMenuSection';
import { solutions, products, resources, partnerships } from '../../utils/navigation';

export default function MegaMenu() {
  const navigate = useNavigate();

  const handleNavigation = (href, close) => {
    close();
    if (href.startsWith('http')) {
      window.open(href, '_blank', 'noopener noreferrer');
    } else if (href === '#calculator') {
      // Do nothing, the calculator button handles its own state
    } else {
      navigate(href);
      window.scrollTo(0, 0);
      if (href.includes('#')) {
        const id = href.split('#')[1];
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  return (
    <div className="flex gap-x-8">
      <MegaMenuSection
        title="Solutions"
        items={solutions}
        additionalLinks={[]}
      />
      <MegaMenuSection
        title="Products"
        items={products}
        additionalLinks={[]}
      />
      <MegaMenuSection
        title="Partnerships"
        items={partnerships}
        additionalLinks={[]}
      />
      <MegaMenuSection
        title="Resources"
        items={resources}
        additionalLinks={[]}
      />
    </div>
  );
}