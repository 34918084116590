import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import DocumentationSection from '../components/documentation/DocumentationSection';
import { documentationData } from '../utils/documentationData';

export default function Documentation() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Documentation"
        description="Access product manuals, technical specifications, and installation guides for all Agilio solutions."
        canonical="/documentation"
      />
      
      <Container className="py-24">
        <div className="grid grid-cols-4 gap-8">
          {/* Left Navigation */}
          <div className="col-span-1">
            <div className="sticky top-24 space-y-1">
              <h2 className="text-sm font-semibold text-gray-900 mb-4">Documentation</h2>
              <nav className="space-y-1">
                {documentationData.map((section) => (
                  <a
                    key={section.id}
                    href={`#${section.id}`}
                    className="block px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 rounded-lg"
                  >
                    {section.title}
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="col-span-3 space-y-16">
            <div>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-8">
                Technical Documentation
              </h1>
              <p className="text-xl text-gray-600 mb-16">
                Access comprehensive documentation for all Agilio products and solutions. Find manuals, 
                specifications, installation guides, and more.
              </p>
            </div>

            {documentationData.map((section) => (
              <DocumentationSection 
                key={section.id}
                {...section}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}