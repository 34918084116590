import React from 'react';
import PageHeader from '../components/PageHeader';
import Container from '../components/layout/Container';
import SEO from '../components/SEO';
// ... rest of the imports

export default function Solutions() {
  return (
    <div className="bg-white">
      <SEO 
        title="Solutions"
        description="Experience true energy independence with our comprehensive home energy solutions. From backup power to cost savings, we have everything you need."
        canonical="/solutions"
      />
      <PageHeader
        title="Smart Energy Solutions for Your Home"
        description="Experience true energy independence with our comprehensive solutions. From backup power to cost savings, we have everything you need to power your home smarter."
        showCalculator={true}
      />
      
      {/* Rest of the component remains the same */}
    </div>
  );
}