import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import ArticleGrid from '../components/learning/ArticleGrid';
import TopicNav from '../components/learning/TopicNav';
import FeaturedArticle from '../components/learning/FeaturedArticle';
import { learningCenterData } from '../utils/learningCenterData';

export default function LearningCenter() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Learning Center"
        description="Explore our comprehensive guides and articles about home energy solutions, efficiency tips, and technology explanations."
        canonical="/learning-center"
      />
      
      <Container className="py-24">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Learning Center
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Discover in-depth articles, guides, and resources to help you understand home energy solutions 
            and make informed decisions about your home improvements.
          </p>
        </div>

        {/* Featured Article */}
        <FeaturedArticle article={learningCenterData.featured} />

        <div className="grid grid-cols-4 gap-8 mt-16">
          {/* Left Navigation */}
          <div className="col-span-1">
            <TopicNav topics={learningCenterData.topics} />
          </div>

          {/* Main Content */}
          <div className="col-span-3">
            <ArticleGrid articles={learningCenterData.articles} />
          </div>
        </div>
      </Container>
    </div>
  );
}