import React, { useState } from 'react';
import { ArrowRightIcon, ShieldCheckIcon, SparklesIcon } from '@heroicons/react/24/outline';
import Button from './ui/Button';
import Container from './layout/Container';
import BatteryCalculator from './calculator/BatteryCalculator';
import GetStartedButton from './ui/GetStartedButton';

export default function Hero() {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  return (
    <div className="relative isolate pt-14">
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-green-200 to-green-600 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      
      <Container className="py-24 sm:py-32 lg:py-40">
        <div className="relative mx-auto max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-8 inline-flex items-center rounded-full bg-green-50 px-3 py-1 text-sm leading-6 text-gray-600">
                <SparklesIcon className="h-4 w-4 text-green-600 mr-2" />
                Stop struggling with home improvement projects
              </div>
              
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Your Home Improvement Journey, <span className="text-green-600">Made Simple</span>
              </h1>
              
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Tired of endless research and unreliable contractors? Let Agilio be your trusted partner. 
                We handle everything from product selection to installation, so you can focus on enjoying 
                your improved home.
              </p>
              
              <div className="mt-10 flex items-center gap-x-6">
                <GetStartedButton source="home_hero" />
                <Button 
                  href="/how-it-works" 
                  variant="text"
                  icon={ArrowRightIcon}
                >
                  See How It Works
                </Button>
              </div>

              <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div className="rounded-lg bg-green-50 p-4">
                  <div className="font-semibold text-green-600">Expert Selection</div>
                  <div className="text-sm text-gray-600">Right products, right price</div>
                </div>
                <div className="rounded-lg bg-green-50 p-4">
                  <div className="font-semibold text-green-600">Vetted Installers</div>
                  <div className="text-sm text-gray-600">Quality guaranteed</div>
                </div>
                <div className="rounded-lg bg-green-50 p-4">
                  <div className="font-semibold text-green-600">One Contact</div>
                  <div className="text-sm text-gray-600">We handle everything</div>
                </div>
              </div>
            </div>

            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80"
                alt="Modern residential home exterior"
                className="rounded-2xl shadow-xl aspect-[4/3] object-cover"
              />
              <div className="absolute inset-0 ring-1 ring-inset ring-gray-900/10 rounded-2xl" />
            </div>
          </div>
        </div>
      </Container>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </div>
  );
}