export const stateData = [
  { name: 'Alabama', avgPeakSunHours: 4.7, netMetering: true },
  { name: 'Alaska', avgPeakSunHours: 3.2, netMetering: true },
  { name: 'Arizona', avgPeakSunHours: 6.5, netMetering: true },
  { name: 'Arkansas', avgPeakSunHours: 4.8, netMetering: true },
  { name: 'California', avgPeakSunHours: 5.8, netMetering: true },
  { name: 'Colorado', avgPeakSunHours: 5.4, netMetering: true },
  { name: 'Connecticut', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Delaware', avgPeakSunHours: 4.3, netMetering: true },
  { name: 'Florida', avgPeakSunHours: 5.2, netMetering: true },
  { name: 'Georgia', avgPeakSunHours: 4.8, netMetering: true },
  { name: 'Hawaii', avgPeakSunHours: 6.0, netMetering: true },
  { name: 'Idaho', avgPeakSunHours: 4.9, netMetering: true },
  { name: 'Illinois', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Indiana', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Iowa', avgPeakSunHours: 4.4, netMetering: true },
  { name: 'Kansas', avgPeakSunHours: 5.0, netMetering: true },
  { name: 'Kentucky', avgPeakSunHours: 4.3, netMetering: true },
  { name: 'Louisiana', avgPeakSunHours: 4.8, netMetering: true },
  { name: 'Maine', avgPeakSunHours: 4.0, netMetering: true },
  { name: 'Maryland', avgPeakSunHours: 4.4, netMetering: true },
  { name: 'Massachusetts', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Michigan', avgPeakSunHours: 4.0, netMetering: true },
  { name: 'Minnesota', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Mississippi', avgPeakSunHours: 4.7, netMetering: true },
  { name: 'Missouri', avgPeakSunHours: 4.5, netMetering: true },
  { name: 'Montana', avgPeakSunHours: 4.6, netMetering: true },
  { name: 'Nebraska', avgPeakSunHours: 4.8, netMetering: true },
  { name: 'Nevada', avgPeakSunHours: 6.3, netMetering: true },
  { name: 'New Hampshire', avgPeakSunHours: 4.1, netMetering: true },
  { name: 'New Jersey', avgPeakSunHours: 4.3, netMetering: true },
  { name: 'New Mexico', avgPeakSunHours: 6.0, netMetering: true },
  { name: 'New York', avgPeakSunHours: 4.1, netMetering: true },
  { name: 'North Carolina', avgPeakSunHours: 4.7, netMetering: true },
  { name: 'North Dakota', avgPeakSunHours: 4.4, netMetering: true },
  { name: 'Ohio', avgPeakSunHours: 4.1, netMetering: true },
  { name: 'Oklahoma', avgPeakSunHours: 5.0, netMetering: true },
  { name: 'Oregon', avgPeakSunHours: 4.4, netMetering: true },
  { name: 'Pennsylvania', avgPeakSunHours: 4.1, netMetering: true },
  { name: 'Rhode Island', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'South Carolina', avgPeakSunHours: 4.7, netMetering: true },
  { name: 'South Dakota', avgPeakSunHours: 4.7, netMetering: true },
  { name: 'Tennessee', avgPeakSunHours: 4.5, netMetering: true },
  { name: 'Texas', avgPeakSunHours: 5.6, netMetering: true },
  { name: 'Utah', avgPeakSunHours: 5.3, netMetering: true },
  { name: 'Vermont', avgPeakSunHours: 4.0, netMetering: true },
  { name: 'Virginia', avgPeakSunHours: 4.4, netMetering: true },
  { name: 'Washington', avgPeakSunHours: 3.8, netMetering: true },
  { name: 'West Virginia', avgPeakSunHours: 4.2, netMetering: true },
  { name: 'Wisconsin', avgPeakSunHours: 4.1, netMetering: true },
  { name: 'Wyoming', avgPeakSunHours: 5.1, netMetering: true }
];

export function calculateDailyProduction(systemSize, peakSunHours) {
  const systemEfficiency = 0.85; // Account for various system losses
  return systemSize * peakSunHours * systemEfficiency;
}