import React from 'react';

export default function FeatureCard({ feature, specs }) {
  const Icon = feature.icon;
  
  return (
    <div className="relative flex gap-x-4">
      <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-green-600">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-white mb-1">{feature.name}</h3>
        <p className="text-gray-300 text-sm mb-2">{feature.description}</p>
        <div className="inline-flex items-baseline rounded-full bg-green-600/10 px-2.5 py-1">
          <span className="text-sm text-green-200">{feature.stat.label}:</span>
          <span className="ml-2 text-sm font-semibold text-white">{feature.stat.value}</span>
        </div>
      </div>
    </div>
  );
}