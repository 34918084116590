import React, { useEffect } from 'react';

export default function CookieConsent() {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true; // Add defer to improve loading
    script.src = 'https://app.termly.io/resource-blocker/e776382f-4140-4f39-8ae7-2f9b84545d88?autoBlock=on';
    
    // Error handling
    script.onerror = (error) => {
      console.warn('Cookie consent script failed to load:', error);
    };

    // Only add if not already present
    if (!document.querySelector('script[src*="resource-blocker"]')) {
      document.body.appendChild(script);
    }

    return () => {
      // Cleanup on unmount
      const existingScript = document.querySelector('script[src*="resource-blocker"]');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null;
}