import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ 
  title, 
  description,
  canonical,
  type = 'website',
  image = 'https://lionenergy.sirv.com/Images/Website/Sanctuary/Sanctuary-wall-mount-lossy.webp',
  schema,
  children 
}) {
  const siteTitle = 'Agilio - Your Home Improvement Concierge';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const baseUrl = 'https://agilio.pro';

  // Default schema for organization
  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Agilio",
    "url": baseUrl,
    "logo": `${baseUrl}/logo.png`,
    "description": "Your Home Improvement Concierge. From energy storage to HVAC, we simplify home improvements with expert guidance and guaranteed satisfaction.",
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61567797987805",
      "https://www.linkedin.com/company/agiliopro"
    ]
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`${baseUrl}${canonical}`} />

      {/* Open Graph */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={`${baseUrl}${canonical}`} />
      <meta property="og:site_name" content="Agilio Energy" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Agilio" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#16A34A" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(schema || defaultSchema)}
      </script>

      {children}
    </Helmet>
  );
}