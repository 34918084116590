import {
  BoltIcon,
  SunIcon,
  CurrencyDollarIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';

export const learningCenterData = {
  featured: {
    id: 'home-battery-guide',
    title: 'The Complete Guide to Home Battery Systems',
    description: 'Everything you need to know about choosing and installing a home battery system, from basic concepts to advanced features.',
    image: 'https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80',
    href: '/learning-center/articles/home-battery-guide',
    topic: 'Battery Storage',
    readTime: 15
  },

  topics: [
    { id: 'battery-storage', name: 'Battery Storage', icon: BoltIcon },
    { id: 'solar', name: 'Solar Integration', icon: SunIcon },
    { id: 'savings', name: 'Energy Savings', icon: CurrencyDollarIcon },
    { id: 'home-improvement', name: 'Home Improvement', icon: HomeIcon },
    { id: 'maintenance', name: 'Maintenance & Care', icon: WrenchScrewdriverIcon },
    { id: 'efficiency', name: 'Energy Efficiency', icon: ChartBarIcon },
  ],

  articles: [
    {
      id: 'net-metering',
      title: 'Understanding Net Metering: A Complete Guide',
      description: 'Learn how net metering works, state policies, and utility programs to maximize your solar investment.',
      image: 'https://images.unsplash.com/photo-1473341304170-971dccb5ac1e?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
      href: '/learning-center/articles/net-metering',
      topic: 'Solar Integration',
      readTime: 12
    },
    {
      id: 'battery-sizing',
      title: 'How to Size Your Battery System',
      description: 'Learn how to calculate your home\'s power needs and choose the right battery size.',
      image: 'https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80',
      href: '/learning-center/articles/battery-sizing',
      topic: 'Battery Storage',
      readTime: 8
    },
    {
      id: 'solar-integration',
      title: 'Integrating Solar with Battery Storage',
      description: 'Maximize your solar investment with smart battery integration strategies.',
      image: 'https://images.unsplash.com/photo-1509391366360-2e959784a276?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
      href: '/learning-center/articles/solar-integration',
      topic: 'Solar Integration',
      readTime: 10
    },
    {
      id: 'energy-savings',
      title: 'Maximizing Energy Cost Savings',
      description: 'Tips and strategies for reducing your utility bills with smart energy management.',
      image: 'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
      href: '/learning-center/articles/energy-savings',
      topic: 'Energy Savings',
      readTime: 12
    },
    {
      id: 'maintenance-tips',
      title: 'Battery System Maintenance Guide',
      description: 'Essential maintenance tips to keep your battery system running optimally.',
      image: 'https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80',
      href: '/learning-center/articles/maintenance-tips',
      topic: 'Maintenance & Care',
      readTime: 6
    }
  ]
};