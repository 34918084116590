import React from 'react';
import { BoltIcon, ShieldCheckIcon, ClockIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const features = [
  {
    name: 'Constant Storage',
    description: 'Grid, solar panels, and other sources charge our safe lithium iron phosphate battery system, ensuring you always have backup power ready.',
    icon: BoltIcon,
    image: 'https://lionenergy.sirv.com/Images/Website/Sanctuary/new-sanctuary-3-1-700x845.webp',
  },
  {
    name: 'Zero Maintenance',
    description: 'Unlike generators that need regular maintenance and fuel, our system is completely maintenance-free and always ready.',
    icon: ShieldCheckIcon,
    image: 'https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80',
  },
  {
    name: '24/7 Availability',
    description: 'Instant, automatic power switchover ensures your home stays running without interruption when the grid fails.',
    icon: ClockIcon,
    image: 'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80',
  },
];

export default function OutageProtection() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Reliable Protection</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Always-On Power Protection
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience worry-free living with a backup power system that activates instantly and runs silently in the background.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-12">
          {features.map((feature, index) => (
            <div 
              key={feature.name} 
              className={`relative grid grid-cols-1 lg:grid-cols-2 gap-8 items-center ${
                index % 2 === 1 ? 'lg:flex-row-reverse' : ''
              }`}
            >
              <div className={index % 2 === 1 ? 'lg:order-2' : ''}>
                <div className="flex items-center gap-x-4 mb-6">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">{feature.name}</h3>
                </div>
                <p className="text-lg leading-8 text-gray-600">{feature.description}</p>
              </div>
              <div className={index % 2 === 1 ? 'lg:order-1' : ''}>
                <img
                  src={feature.image}
                  alt={feature.name}
                  className="rounded-2xl shadow-xl aspect-[4/3] object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}