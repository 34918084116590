import React from 'react';
import SEO from '../../components/SEO';
import Container from '../../components/layout/Container';
import { 
  UserGroupIcon, 
  ShieldCheckIcon, 
  CurrencyDollarIcon, 
  AcademicCapIcon,
  ChartBarIcon,
  BuildingOfficeIcon,
  ComputerDesktopIcon,
  GlobeAltIcon,
  MegaphoneIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';
import HubSpotForm from '../../components/forms/HubSpotForm';

const ecosystemBenefits = [
  {
    name: 'Free Business Software',
    description: 'Complete business management platform at no cost to you.',
    icon: ComputerDesktopIcon,
    features: [
      'Job management system',
      'Customer relationship tools',
      'Scheduling and dispatch',
      'Mobile app for field teams'
    ]
  },
  {
    name: 'Professional Website',
    description: 'Custom-built website included with your partnership.',
    icon: GlobeAltIcon,
    features: [
      'Professional design',
      'Mobile optimization',
      'SEO optimization',
      'Regular updates'
    ]
  },
  {
    name: 'Marketing Support',
    description: 'Comprehensive marketing tools and resources.',
    icon: MegaphoneIcon,
    features: [
      'Marketing materials',
      'Social media content',
      'Email templates',
      'Local advertising support'
    ]
  },
  {
    name: 'Workflow Integration',
    description: 'Seamless job management and customer communication.',
    icon: ClipboardDocumentCheckIcon,
    features: [
      'Automated job assignments',
      'Real-time updates',
      'Customer communication',
      'Payment processing'
    ]
  }
];

// Previous benefits and requirements arrays remain the same...

export default function LocalPros() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Local Professional Network"
        description="Join our network of certified professionals and grow your business with quality leads, support, and free business tools."
        canonical="/partnerships/local-pros"
      />
      
      {/* Hero Section */}
      <div className="relative isolate overflow-hidden bg-green-600 py-24">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <div className="mb-8 inline-flex items-center rounded-full bg-green-500 px-3 py-1 text-sm text-white ring-1 ring-inset ring-green-200">
              No fees or costs to join
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Grow Your Business with Zero Overhead
            </h1>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Join our network to get quality leads, free business software, marketing support, and more - all with no fees or costs to you.
            </p>
          </div>
        </Container>
      </div>

      {/* Ecosystem Benefits Section */}
      <Container className="py-24">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Complete Business Solution</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything You Need to Succeed
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Stop paying for expensive software and marketing tools. We provide everything you need to run and grow your business.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {ecosystemBenefits.map((benefit) => (
            <div key={benefit.name} className="relative p-8 bg-green-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{benefit.name}</h3>
              </div>
              <p className="mt-4 text-gray-600">{benefit.description}</p>
              <ul className="mt-8 space-y-3">
                {benefit.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Cost Comparison Card */}
        <div className="mt-16 bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200">
          <h3 className="text-xl font-semibold text-gray-900 mb-6">
            Stop Paying for Business Tools
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h4 className="text-base font-medium text-gray-900 mb-4">Traditional Costs</h4>
              <ul className="space-y-3">
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">CRM Software</span>
                  <span className="text-red-600">$99/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Job Management</span>
                  <span className="text-red-600">$79/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Website & Hosting</span>
                  <span className="text-red-600">$49/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Marketing Tools</span>
                  <span className="text-red-600">$129/mo</span>
                </li>
                <li className="flex items-center justify-between font-semibold border-t border-gray-200 pt-3 mt-3">
                  <span>Total Monthly Cost</span>
                  <span className="text-red-600">$356/mo</span>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-base font-medium text-gray-900 mb-4">With Agilio</h4>
              <ul className="space-y-3">
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">All-in-One Platform</span>
                  <span className="text-green-600">$0/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Professional Website</span>
                  <span className="text-green-600">$0/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Marketing Support</span>
                  <span className="text-green-600">$0/mo</span>
                </li>
                <li className="flex items-center justify-between">
                  <span className="text-gray-600">Quality Leads</span>
                  <span className="text-green-600">$0/mo</span>
                </li>
                <li className="flex items-center justify-between font-semibold border-t border-gray-200 pt-3 mt-3">
                  <span>Total Monthly Cost</span>
                  <span className="text-green-600">$0/mo</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* Previous sections remain the same... */}
      
      {/* Application Form Section */}
      <Container className="py-24">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
              Start Growing Your Business Today
            </h2>
            <p className="text-lg text-gray-600">
              Join our network and get instant access to our complete business platform - with zero fees or costs.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-sm ring-1 ring-gray-200 p-8">
            <HubSpotForm 
              formId="9f365658-dcd3-48ba-990a-cb4b3ff5ca7c"
              portalId="42269009"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}