import React from 'react';
import Container from '../layout/Container';

const testimonials = [
  {
    content: "Working with Agilio made our home improvement project stress-free. Their expert guidance and attention to detail exceeded our expectations.",
    author: "Sarah M.",
    role: "Homeowner",
    image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&auto=format&fit=crop&w=400&q=80"
  },
  {
    content: "The level of professionalism and care they showed throughout our energy storage installation was remarkable. Highly recommended!",
    author: "Michael R.",
    role: "Solar Energy Customer",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&auto=format&fit=crop&w=400&q=80"
  },
  {
    content: "From initial consultation to final installation, Agilio's team was knowledgeable, efficient, and a pleasure to work with.",
    author: "Jennifer L.",
    role: "Recent Customer",
    image: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&auto=format&fit=crop&w=400&q=80"
  },
];

export default function Testimonials() {
  return (
    <div id="testimonials" className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Success Stories</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            What Our Customers Say
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Don't just take our word for it. Hear from homeowners who've experienced 
            the Agilio difference.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author}
              className="relative rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <blockquote>
                <p className="text-gray-600 mb-8">"{testimonial.content}"</p>
                <div className="flex items-center gap-x-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                  <div>
                    <div className="font-semibold text-gray-900">{testimonial.author}</div>
                    <div className="text-sm text-gray-500">{testimonial.role}</div>
                  </div>
                </div>
              </blockquote>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}