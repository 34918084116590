import React from 'react';
import Container from '../../../components/layout/Container';
import ShowcaseHeader from './HomeEnergyShowcase/ShowcaseHeader';
import FeatureGrid from './HomeEnergyShowcase/FeatureGrid';
import ShowcaseImage from './HomeEnergyShowcase/ShowcaseImage';
import ActionButtons from './HomeEnergyShowcase/ActionButtons';

export default function HomeEnergyShowcase() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-green-600/90 to-gray-900/90" />
        <img
          src="https://lionenergy.sirv.com/Images/Website/Sanctuary/garage%20with%20new%20Sactuary.webp"
          alt="Lion Energy Sanctuary installation in garage"
          className="h-full w-full object-cover opacity-30"
        />
      </div>

      <Container>
        <ShowcaseHeader />
        
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <ShowcaseImage />
          <FeatureGrid />
        </div>

        <ActionButtons />
      </Container>
    </div>
  );
}