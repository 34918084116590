import { 
  BookOpenIcon, 
  AcademicCapIcon, 
  DocumentTextIcon 
} from '@heroicons/react/24/outline';

export const resources = [
  {
    name: 'Energy Definitions',
    description: 'Learn about key energy terms and concepts in simple language',
    href: '/energy-definitions',
    icon: BookOpenIcon,
    features: [
      'Simple explanations',
      'Real-world examples',
      'Visual analogies',
    ]
  },
  {
    name: 'Learning Center',
    description: 'Educational resources and guides for home energy solutions',
    href: '/learning-center',
    icon: AcademicCapIcon,
    features: [
      'How-to guides',
      'Energy saving tips',
      'Technology explanations',
    ]
  },
  {
    name: 'Documentation',
    description: 'Product manuals, specifications, and technical resources',
    href: '/documentation',
    icon: DocumentTextIcon,
    features: [
      'Product manuals',
      'Installation guides',
      'Technical specs',
    ]
  }
];