import React from 'react';
import { 
  BoltIcon, 
  ShieldCheckIcon, 
  SparklesIcon, 
  WrenchScrewdriverIcon,
  BeakerIcon,
  CloudIcon,
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const modernFeatures = [
  {
    name: 'Smart Energy Storage',
    description: 'Lithium iron phosphate battery storage with cutting-edge inverters and monitoring software',
    icon: SparklesIcon,
  },
  {
    name: 'Zero Maintenance',
    description: 'Maintenance-free operation with no regular service requirements',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Clean & Safe',
    description: 'No fumes, no fire hazards, and zero environmental impact',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Always Available',
    description: 'Ready to use anytime with smart app monitoring and control',
    icon: CloudIcon,
  },
];

const oldTechIssues = [
  {
    name: 'Fossil Fuel Dependent',
    description: 'Relies on gas or natural propane, requiring constant fuel supply',
    icon: BeakerIcon,
  },
  {
    name: 'Safety Concerns',
    description: 'Potential health hazards from fumes and fire risks',
    icon: ShieldCheckIcon,
  },
  {
    name: 'High Maintenance',
    description: 'Regular maintenance required to ensure system readiness',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Limited Availability',
    description: 'Dependent on fuel availability during emergencies',
    icon: BoltIcon,
  },
];

export default function TechnologyComparison() {
  return (
    <div className="py-24 sm:py-32 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">
            Next-Generation Technology
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The Future of Home Energy Storage
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience the difference between modern energy storage and traditional backup solutions.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Modern Technology */}
          <div>
            <div className="rounded-3xl bg-green-50 p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-8">
                Modern Technology Advantages
              </h3>
              <dl className="space-y-6">
                {modernFeatures.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <feature.icon className="h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600 pl-8">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Old Technology */}
          <div>
            <div className="rounded-3xl bg-gray-50 p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-8">
                Traditional Backup Limitations
              </h3>
              <dl className="space-y-6">
                {oldTechIssues.map((issue) => (
                  <div key={issue.name} className="relative">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <issue.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {issue.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600 pl-8">
                      {issue.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}