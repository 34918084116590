import React, { useState, useEffect } from 'react';
import Container from '../components/layout/Container';
import SEO from '../components/SEO';
import { powerTooltips } from '../utils/tooltips/powerTooltips';
import PowerSourceComparison from '../components/sections/PowerSourceComparison';
import BatteryWithoutSolar from '../components/sections/BatteryWithoutSolar';
import BatteryInstallationLocations from '../components/sections/BatteryInstallationLocations';
import BatteryChemistry from '../components/sections/BatteryChemistry';
import SystemSizing from '../components/sections/SystemSizing';


const terms = [
  {
    id: 'amps',
    title: 'Amperage (Amps)',
    content: powerTooltips.amps
  },
  {
    id: 'watts',
    title: 'Wattage (Watts)', 
    content: powerTooltips.watts
  },
  {
    id: 'kwh',
    title: 'Kilowatt-Hours (kWh)',
    content: powerTooltips.kwh
  },
  {
    id: 'inverter',
    title: 'Inverter',
    content: powerTooltips.inverter
  },
  {
    id: 'battery-chemistry',
    title: 'Battery Chemistry',
    content: null,
    component: BatteryChemistry
  },
  {
    id: 'comparison',
    title: 'Battery vs Generator',
    content: null
  },
  {
    id: 'battery-without-solar',
    title: 'Do I Need Solar?',
    content: null
  },
  {
    id: 'installation-locations',
    title: 'Where to Install',
    content: null
  },
  {
  id: 'system-sizing',
  title: 'System Sizing Guide',
  content: null,
  component: SystemSizing
  }
];

export default function EnergyDefinitions() {
  const [activeSection, setActiveSection] = useState(terms[0].id);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setActiveSection(id);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = terms.map(term => ({
        id: term.id,
        offset: document.getElementById(term.id)?.offsetTop || 0
      }));

      const scrollPosition = window.scrollY + 100;

      for (let i = sections.length - 1; i >= 0; i--) {
        if (scrollPosition >= sections[i].offset) {
          setActiveSection(sections[i].id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Energy Definitions"
        description="Learn about key energy terms and concepts in simple, easy-to-understand language. Understand watts, amps, kilowatt-hours, and more."
        canonical="/energy-definitions"
      />
      
      <Container className="py-24">
        <div className="grid grid-cols-4 gap-8">
          {/* Left Navigation */}
          <div className="col-span-1">
            <div className="sticky top-24 space-y-1">
              <h2 className="text-sm font-semibold text-gray-900 mb-4">On this page</h2>
              {terms.map((term) => (
                <button
                  key={term.id}
                  onClick={() => scrollToSection(term.id)}
                  className={`
                    block w-full text-left px-3 py-2 text-sm rounded-lg
                    ${activeSection === term.id 
                      ? 'bg-green-50 text-green-600 font-medium'
                      : 'text-gray-600 hover:bg-gray-50'
                    }
                  `}
                >
                  {term.title}
                </button>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="col-span-3">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-8">
              Understanding Energy Terms
            </h1>
            <p className="text-xl text-gray-600 mb-16">
              Energy terms can be confusing. We've broken down the most important concepts into simple, 
              easy-to-understand explanations with real-world examples.
            </p>

            <div className="space-y-16">
              {terms.map((term) => {
                if (term.content) {
                  return (
                    <div 
                      key={term.id}
                      id={term.id}
                      className="bg-gray-50 rounded-2xl p-8 scroll-mt-24"
                    >
                      <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                        {term.title}
                      </h2>
                      <div className="prose prose-lg">
                        {term.content}
                      </div>
                    </div>
                  );
                } else if (term.component) {
                  const Component = term.component;
                  return (
                    <div 
                      key={term.id}
                      id={term.id}
                      className="scroll-mt-24"
                    >
                      <Component />
                    </div>
                  );
                }
                return null;
              })}
            </div>

            {/* Battery vs Generator Comparison */}
            <div id="comparison" className="mt-24 scroll-mt-24">
              <PowerSourceComparison />
            </div>

            {/* Battery Without Solar Section */}
            <div id="battery-without-solar" className="mt-24 scroll-mt-24">
              <BatteryWithoutSolar />
            </div>

            {/* Installation Locations Section */}
            <div id="installation-locations" className="mt-24 scroll-mt-24">
              <BatteryInstallationLocations />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}