export const florida = {
  name: 'Florida',
  hasNetMetering: true,
  creditRate: 'Retail Rate',
  aggregation: false,
  features: [
    'Monthly rollover credits',
    'Annual credit payout',
    'Solar rebate programs',
    'Grid interconnection'
  ],
  notes: 'Florida offers net metering at retail rates with monthly credit rollover and annual payout of excess credits.',
  utilities: [
    {
      name: 'Florida Power & Light',
      description: 'Serves most of Florida',
      programUrl: 'https://www.fpl.com/clean-energy/net-metering.html',
      features: [
        'Net metering program',
        'Solar rebates',
        'Time-of-use rates',
        'Energy monitoring'
      ]
    },
    {
      name: 'Duke Energy Florida',
      description: 'Serves Central and Northern Florida',
      programUrl: 'https://www.duke-energy.com/home/products/renewable-energy/generate-your-own/net-metering',
      features: [
        'Net metering program',
        'Solar incentives',
        'Smart meter integration',
        'Online monitoring'
      ]
    }
  ]
};