import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import TableOfContents from './TableOfContents';

export default function ArticleLayout({ article, children }) {
  const { title, description, content } = article;

  return (
    <div className="bg-white pt-24">
      {/* Back Button and Header */}
      <div className="bg-gray-50 border-b border-gray-200">
        <Container>
          <div className="py-4">
            <Link
              to="/learning-center"
              className="group inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-900"
            >
              <ArrowLeftIcon className="h-4 w-4 transition-transform group-hover:-translate-x-1" />
              Back to Learning Center
            </Link>
          </div>
        </Container>
      </div>

      {/* Article Header */}
      <div className="bg-white border-b border-gray-200 py-12">
        <Container>
          <div className="mx-auto max-w-3xl">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {title}
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              {description}
            </p>
          </div>
        </Container>
      </div>

      {/* Article Content */}
      <Container className="py-16">
        <div className="grid grid-cols-12 gap-8">
          {/* Table of Contents Sidebar */}
          <div className="col-span-3">
            <TableOfContents sections={content.sections} />
          </div>

          {/* Main Content */}
          <div className="col-span-9">
            <article className="prose prose-lg max-w-none">
              <p className="lead text-xl text-gray-600">{content.introduction}</p>
              {content.sections.map((section) => (
                <section 
                  key={section.title} 
                  id={section.title.toLowerCase().replace(/\s+/g, '-')} 
                  className="scroll-mt-24 mt-12"
                >
                  <h2 className="text-3xl font-bold text-gray-900 mb-6 pb-2 border-b border-gray-200">
                    {section.title}
                  </h2>
                  <div className="whitespace-pre-wrap text-gray-600 leading-relaxed">
                    {section.content}
                  </div>
                </section>
              ))}
              {children}
            </article>
          </div>
        </div>
      </Container>
    </div>
  );
}