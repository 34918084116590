import React, { useState } from 'react';
import { HomeIcon, DevicePhoneMobileIcon, HeartIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import BatteryCalculator from '../calculator/BatteryCalculator';

const impacts = [
  {
    name: 'Home Life',
    description: 'Keep your lights on, food fresh, and maintain comfort with working HVAC systems.',
    icon: HomeIcon,
  },
  {
    name: 'Work & School',
    description: 'Stay connected for remote work and online learning without interruption.',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'Health & Safety',
    description: 'Ensure medical devices and security systems remain operational.',
    icon: HeartIcon,
  },
  {
    name: 'Business Continuity',
    description: 'Protect home-based businesses from costly downtime.',
    icon: BuildingOfficeIcon,
  },
];

export default function OutageImpact() {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Impact Protection</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Protect What Matters Most
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Power outages affect every aspect of modern life. Our solution ensures you stay powered and protected.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {impacts.map((impact) => (
            <div key={impact.name} className="relative p-8 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <impact.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold leading-8 text-gray-900">{impact.name}</h3>
              </div>
              <p className="mt-4 text-base leading-7 text-gray-600">{impact.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
          >
            Protect Your Home
          </a>
        </div>

        <BatteryCalculator 
          isOpen={isCalculatorOpen}
          onClose={() => setIsCalculatorOpen(false)}
        />
      </Container>
    </div>
  );
}