import React, { useState } from 'react';

export default function Tooltip({ children, content, className = '' }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="inline-flex items-center cursor-help"
      >
        {children}
        <svg
          className="h-4 w-4 ml-1 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      
      {isVisible && (
        <div className={`
          absolute z-50 w-96 p-4 mt-2 text-sm bg-white rounded-lg shadow-xl
          border border-gray-200 text-gray-600 leading-relaxed
          ${className}
        `}>
          {content}
        </div>
      )}
    </div>
  );
}