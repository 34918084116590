import React from 'react';
import { CurrencyDollarIcon, ExclamationTriangleIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const costs = [
  {
    category: 'Food Loss',
    amount: '$500+',
    description: 'Spoiled food from non-functioning refrigerators and freezers during extended outages.',
    icon: ExclamationTriangleIcon,
  },
  {
    category: 'Alternative Accommodation',
    amount: '$200/night',
    description: 'Hotel stays when homes become uninhabitable due to lack of heating/cooling.',
    icon: CurrencyDollarIcon,
  },
  {
    category: 'Lost Productivity',
    amount: '$250/day',
    description: 'Work disruption for remote workers and home-based businesses.',
    icon: ChartBarIcon,
  },
];

const preventionPoints = [
  'Eliminate food spoilage costs with continuous refrigeration',
  'Avoid hotel expenses by maintaining home comfort',
  'Keep working remotely without interruption',
  'Prevent damage from frozen pipes in winter',
  'Maintain security systems and monitoring',
];

export default function OutageCosts() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Financial Protection</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The True Cost of Power Outages
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Power outages can be expensive. Protect your home and finances with reliable backup power.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-16">
          {costs.map((cost) => (
            <div 
              key={cost.category}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <div className="flex items-center gap-x-4 mb-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <cost.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{cost.category}</h3>
              </div>
              <p className="text-3xl font-bold text-green-600 mb-4">{cost.amount}</p>
              <p className="text-gray-600">{cost.description}</p>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200">
          <h3 className="text-xl font-semibold text-gray-900 mb-6">
            Prevent Costly Disruptions
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <ul className="space-y-4">
                {preventionPoints.map((point, index) => (
                  <li key={index} className="flex items-start gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm font-medium">
                      {index + 1}
                    </div>
                    <span className="text-gray-600">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-green-50 rounded-xl p-6">
              <h4 className="text-lg font-semibold text-gray-900 mb-4">
                Return on Investment
              </h4>
              <p className="text-gray-600 mb-4">
                A single extended power outage can cost thousands in lost food, alternative accommodations, and lost productivity. The Sanctuary system pays for itself by preventing these losses while providing daily energy cost savings through smart power management.
              </p>
              <div className="text-sm text-gray-500">
                * Costs may vary based on location and specific circumstances
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}