import React from 'react';

export default function EmptyState({ onBack }) {
  return (
    <div className="text-center py-8">
      <p className="text-gray-600">Please add appliances to calculate your power needs.</p>
      <button
        onClick={onBack}
        className="mt-4 rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        Back
      </button>
    </div>
  );
}