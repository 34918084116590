import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo({ className = '' }) {
  return (
    <Link to="/" className={`block ${className}`}>
      <img 
        src="https://cdn.prod.website-files.com/67091f695cd8e90971357ea4/671b89182e8f151846ae4e4e_Agilio%20Logo-1.webp"
        alt="Agilio"
        className="h-8 w-auto"
      />
      <span className="sr-only">Agilio</span>
    </Link>
  );
}