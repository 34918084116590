import React, { useState } from 'react';
import { stateNetMeteringData } from '../../utils/netMetering';
import StateCard from './StateCard';
import UtilityList from './UtilityList';

export default function StateLookup() {
  const [selectedState, setSelectedState] = useState(null);

  return (
    <div className="mt-12 bg-white rounded-xl shadow-sm ring-1 ring-gray-200 overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900">
          Find Net Metering Programs in Your Area
        </h3>
        <p className="mt-2 text-sm text-gray-600">
          Select your state to see available net metering programs and utility policies.
        </p>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {/* State Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Your State
            </label>
            <select
              value={selectedState?.name || ''}
              onChange={(e) => setSelectedState(stateNetMeteringData.find(s => s.name === e.target.value))}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
            >
              <option value="">Choose a state...</option>
              {stateNetMeteringData.map((state) => (
                <option key={state.name} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* State Details */}
        {selectedState && (
          <div className="mt-8">
            <StateCard state={selectedState} />
            <UtilityList utilities={selectedState.utilities} />
          </div>
        )}
      </div>
    </div>
  );
}