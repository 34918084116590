import React, { useState } from 'react';
import { SunIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { stateData } from '../../../utils/solarData';

export default function SolarStep({ data, onUpdate, onNext, onBack }) {
  const [solarData, setSolarData] = useState(data.solar || {
    hasSolar: false,
    state: '',
    systemSize: 0
  });

  const handleChange = (field, value) => {
    setSolarData(prev => ({ ...prev, [field]: value }));
  };

  const handleContinue = () => {
    onUpdate({ solar: solarData });
    onNext();
  };

  return (
    <div className="space-y-8">
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-4">
          Solar System Information
        </h4>
        <p className="text-sm text-gray-600 mb-8">
          Tell us about your solar system to help optimize your battery solution.
        </p>

        {/* Solar Panel Question */}
        <div className="bg-white rounded-lg border border-gray-200 p-6 mb-8">
          <label className="text-base font-medium text-gray-900 block mb-4">
            Do you have solar panels installed?
          </label>
          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              className={`
                flex items-center justify-center gap-x-2 px-6 py-4 rounded-lg text-base font-medium
                transition-colors duration-200 border-2
                ${solarData.hasSolar
                  ? 'bg-green-50 border-green-600 text-green-700'
                  : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                }
              `}
              onClick={() => handleChange('hasSolar', true)}
            >
              <SunIcon className={`h-6 w-6 ${solarData.hasSolar ? 'text-green-600' : 'text-gray-400'}`} />
              Yes
            </button>
            <button
              type="button"
              className={`
                flex items-center justify-center gap-x-2 px-6 py-4 rounded-lg text-base font-medium
                transition-colors duration-200 border-2
                ${!solarData.hasSolar && solarData.hasSolar !== undefined
                  ? 'bg-green-50 border-green-600 text-green-700'
                  : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                }
              `}
              onClick={() => handleChange('hasSolar', false)}
            >
              <SunIcon className={`h-6 w-6 ${!solarData.hasSolar && solarData.hasSolar !== undefined ? 'text-green-600' : 'text-gray-400'}`} />
              No
            </button>
          </div>
        </div>

        {/* Location Selection */}
        <div className="bg-white rounded-lg border border-gray-200 p-6 mb-8">
          <label className="text-base font-medium text-gray-900 block mb-4">
            Select your location
          </label>
          <div className="relative">
            <MapPinIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <select
              value={solarData.state}
              onChange={(e) => handleChange('state', e.target.value)}
              className="block w-full rounded-lg border-gray-200 pl-10 py-3.5 text-base focus:border-green-500 focus:ring-green-500"
            >
              <option value="">Select your state...</option>
              {stateData.map((state) => (
                <option key={state.name} value={state.name}>
                  {state.name} - {state.avgPeakSunHours} peak sun hours/day
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* System Size - Only shown if hasSolar is true */}
        {solarData.hasSolar && (
          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <label className="text-base font-medium text-gray-900 block mb-4">
              What is your solar system size?
            </label>
            <div className="relative mt-2">
              <input
                type="number"
                min="0"
                step="0.1"
                value={solarData.systemSize || ''}
                onChange={(e) => handleChange('systemSize', parseFloat(e.target.value))}
                className="block w-full rounded-lg border-gray-200 py-3.5 pr-12 text-base focus:border-green-500 focus:ring-green-500"
                placeholder="Enter system size"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <span className="text-gray-500">kW</span>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Enter the total capacity of your solar system in kilowatts (kW)
            </p>
          </div>
        )}
      </div>

      {/* Navigation */}
      <div className="flex justify-between pt-6">
        <button
          type="button"
          className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onBack}
        >
          Back
        </button>
        <button
          type="button"
          className="rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}