export const batterySizingArticle = {
  id: 'battery-sizing',
  title: 'How to Size Your Battery System',
  description: 'A comprehensive guide to calculating your home\'s power needs and choosing the right battery size.',
  content: {
    introduction: `
      Choosing the right size for your home battery system is crucial for ensuring reliable backup power and maximizing your investment. This comprehensive guide will walk you through the process of determining your power needs and selecting the optimal system configuration for your home.
    `,
    sections: [
      {
        title: 'Understanding Power Requirements',
        content: `
          Before sizing your battery system, it's essential to understand the key factors that determine your power needs:

          Daily Energy Usage (kWh)
          • Total energy consumed over 24 hours
          • Varies by season and lifestyle
          • Found on your utility bill
          • Typically ranges from 20-40 kWh per day

          Peak Power Demands (kW)
          • Maximum power needed at any moment
          • Determined by simultaneous appliance use
          • Important for inverter sizing
          • Usually 4-8 kW for most homes

          Critical Loads vs. Whole-Home Backup
          • Critical: Essential appliances only
          • Whole-home: Complete power coverage
          • Affects system size and cost
          • Consider future needs

          Backup Duration Goals
          • How long you need backup power
          • Typical outage duration in your area
          • Budget considerations
          • Solar integration plans
        `
      },
      {
        title: 'Essential vs. Optional Loads',
        content: `
          Categorizing your power needs helps prioritize what to backup:

          Essential Loads
          • Refrigeration (150-700W)
          • Lighting (100-500W)
          • Medical equipment
          • Security systems
          • Internet/communications
          • Well pump (if applicable)

          Common Optional Loads
          • HVAC (2000-3500W)
          • Water heater (3000-4500W)
          • Clothes washer/dryer
          • Dishwasher
          • Electric vehicle charging

          Consider these factors:
          • Essential loads require smaller, more affordable systems
          • Whole-home backup provides maximum convenience
          • Mixed approach possible with critical load panel
          • Future expansion capability important
        `
      },
      {
        title: 'Calculating Your Needs',
        content: `
          Follow these steps to determine your ideal system size:

          1. List Your Appliances
          • Write down all devices needing power
          • Note both running and startup watts
          • Consider usage patterns
          • Include future additions

          2. Calculate Daily Energy
          • Multiply watts by hours used
          • Add up all appliances
          • Consider seasonal variations
          • Include efficiency losses

          3. Determine Peak Load
          • List simultaneous appliance use
          • Account for startup surges
          • Consider time-of-day patterns
          • Add 20% safety margin

          4. Factor Backup Duration
          • Minimum desired runtime
          • Typical outage length
          • Solar charging capability
          • Budget constraints
        `
      },
      {
        title: 'System Recommendations',
        content: `
          Based on home size and usage patterns, here are typical system configurations:

          Small Home (1-2 bedrooms)
          • 13.5 kWh battery capacity
          • 8 kW continuous power
          • Ideal for essential loads
          • Typical cost: $12,000-15,000

          Medium Home (3-4 bedrooms)
          • 27 kWh battery capacity
          • 16 kW continuous power
          • Whole-home capable
          • Typical cost: $20,000-25,000

          Large Home (5+ bedrooms)
          • 40.5 kWh battery capacity
          • 24 kW continuous power
          • Complete coverage
          • Typical cost: $30,000-40,000

          Additional Considerations:
          • Future expansion needs
          • Solar integration plans
          • Local incentives available
          • Installation requirements
        `
      }
    ]
  }
};