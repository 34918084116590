import React from 'react';
import { 
  BoltIcon, 
  HomeIcon, 
  ChartBarIcon, 
  TrophyIcon 
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import Button from '../ui/Button';
import CalculatorButton from '../ui/CalculatorButton';
import { sanctuarySpecs } from '../../utils/productSpecs';

const features = [
  {
    name: 'Constant Storage',
    description: 'Store energy from multiple sources - grid, solar panels, wind turbines - in our safe lithium iron phosphate battery system. Available 24/7 for perfect home backup.',
    icon: BoltIcon,
  },
  {
    name: 'Flexible Options',
    description: 'Choose your ideal backup capacity - from essential loads to whole-home backup. Our proprietary software optimizes power usage for maximum efficiency.',
    icon: HomeIcon,
  },
  {
    name: 'Smart Management',
    description: 'Reduce costs with time-of-use optimization, charging during off-peak hours and using stored energy during peak periods.',
    icon: ChartBarIcon,
  },
  {
    name: 'Premium Technology',
    description: `Industry-leading ${sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency} round-trip efficiency with integrated inverter and smart monitoring system.`,
    icon: TrophyIcon,
  },
];

export default function SanctuarySolution() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">The Solution</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {sanctuarySpecs.name}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience true power security with our premium home battery system. Advanced technology
            and unmatched reliability keep your home running, no matter what.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <div className="grid grid-cols-2 gap-4 mb-8">
              {sanctuarySpecs.basicSpecs.map((spec) => (
                <div key={spec.label} className="bg-green-50 p-4 rounded-lg">
                  <dt className="text-sm font-medium text-gray-600">{spec.label}</dt>
                  <dd className="mt-1 text-lg font-semibold text-green-600">{spec.value}</dd>
                </div>
              ))}
            </div>

            <div className="space-y-6">
              {features.map((feature) => (
                <div key={feature.name} className="flex gap-x-4">
                  <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{feature.name}</h3>
                    <p className="mt-2 text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-10 flex gap-4">
              <CalculatorButton />
              <Button href="/products/sanctuary">Learn More</Button>
            </div>
          </div>

          <div className="relative">
            <img
              src="https://lionenergy.sirv.com/Images/Website/Sanctuary/Sanctuary-wall-mount-lossy.webp"
              alt="Lion Energy Sanctuary home battery installation"
              className="rounded-2xl shadow-xl"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}