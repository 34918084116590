import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { solutions, products, resources, partnerships } from '../../utils/navigation';
import CalculatorButton from '../ui/CalculatorButton';

function MobileMenuSection({ title, items, onClose }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-2">
      <button
        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <ChevronDownIcon
          className={`h-5 w-5 flex-none text-gray-400 transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
        />
      </button>
      {isOpen && (
        <div className="mt-2 space-y-2">
          {items.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              onClick={onClose}
            >
              <div className="flex items-center gap-x-3">
                <item.icon className="h-5 w-5 text-gray-400" />
                <span>{item.name}</span>
              </div>
              {item.description && (
                <p className="mt-1 text-sm text-gray-500 pl-8">{item.description}</p>
              )}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default function MobileNavigation({ onClose }) {
  return (
    <div className="space-y-2">
      <Link
        to="/"
        className="block rounded-lg py-2 pl-3 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        onClick={onClose}
      >
        Home
      </Link>
      <MobileMenuSection
        title="Solutions"
        items={solutions}
        onClose={onClose}
      />
      <MobileMenuSection
        title="Products"
        items={products}
        onClose={onClose}
      />
      <MobileMenuSection
        title="Partnerships"
        items={partnerships}
        onClose={onClose}
      />
      <MobileMenuSection
        title="Resources"
        items={resources}
        onClose={onClose}
      />
      <Link
        to="/how-it-works"
        className="block rounded-lg py-2 pl-3 pr-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        onClick={onClose}
      >
        How It Works
      </Link>
      <div className="mt-4 px-3">
        <CalculatorButton className="w-full justify-center" />
      </div>
    </div>
  );
}