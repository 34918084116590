import { useCallback } from 'react';
import { event } from '../utils/analytics/gtag';

export function useGetStarted() {
  const handleGetStarted = useCallback((source) => {
    // Track the event
    event({
      action: 'get_started_click',
      category: 'Conversion',
      label: source || window.location.pathname,
      value: 1
    });

    // Open in new tab
    window.open('https://agilio.go-kf.com/contact-us', '_blank', 'noopener noreferrer');
  }, []);

  return handleGetStarted;
}