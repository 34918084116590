import React from 'react';
import { BeakerIcon, ShieldCheckIcon, FireIcon, HomeIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import { batteryChemistries, safetyComparison } from '../../utils/batteryChemistryData';

export default function BatteryChemistry() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">
        Understanding Battery Chemistry
      </h2>

      {/* LiFePO4 Section */}
      <div className="bg-green-50 rounded-2xl p-8">
        <div className="flex items-center gap-x-3 mb-6">
          <BeakerIcon className="h-6 w-6 text-green-600" />
          <h3 className="text-xl font-semibold text-gray-900">
            {batteryChemistries.lifepo4.name}
          </h3>
        </div>
        
        <p className="text-gray-600 mb-6">
          The Lion Energy Sanctuary uses Lithium Iron Phosphate (LiFePO4) chemistry, 
          setting it apart from traditional battery systems. This advanced chemistry 
          offers superior safety and longevity, making it ideal for home energy storage.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-4">Key Advantages</h4>
            <ul className="space-y-3">
              {batteryChemistries.lifepo4.advantages.map((advantage) => (
                <li key={advantage} className="flex items-center gap-x-3">
                  <ShieldCheckIcon className="h-5 w-5 flex-none text-green-600" />
                  <span className="text-gray-600">{advantage}</span>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-4">Ideal Applications</h4>
            <ul className="space-y-3">
              {batteryChemistries.lifepo4.applications.map((application) => (
                <li key={application} className="flex items-center gap-x-3">
                  <HomeIcon className="h-5 w-5 flex-none text-green-600" />
                  <span className="text-gray-600">{application}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* NMC Section */}
      <div className="bg-gray-50 rounded-2xl p-8">
        <div className="flex items-center gap-x-3 mb-6">
          <FireIcon className="h-6 w-6 text-gray-600" />
          <h3 className="text-xl font-semibold text-gray-900">
            {batteryChemistries.nmc.name}
          </h3>
        </div>

        <p className="text-gray-600 mb-6">
          Traditional home battery systems often use NMC chemistry, which has some 
          limitations and safety concerns that affect installation and usage.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-4">Advantages</h4>
            <ul className="space-y-3">
              {batteryChemistries.nmc.advantages.map((advantage) => (
                <li key={advantage} className="flex items-center gap-x-3">
                  <ArrowTrendingUpIcon className="h-5 w-5 flex-none text-gray-600" />
                  <span className="text-gray-600">{advantage}</span>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold text-gray-900 mb-4">Limitations</h4>
            <ul className="space-y-3">
              {batteryChemistries.nmc.disadvantages.map((disadvantage) => (
                <li key={disadvantage} className="flex items-center gap-x-3">
                  <FireIcon className="h-5 w-5 flex-none text-red-600" />
                  <span className="text-gray-600">{disadvantage}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Safety Comparison Table */}
      <div className="bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Safety Comparison
        </h3>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                  Feature
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-green-600">
                  LiFePO4 (Lion Energy)
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">
                  NMC
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {safetyComparison.map((item) => (
                <tr key={item.aspect}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                    {item.aspect}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    {item.lifepo4}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    {item.nmc}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}