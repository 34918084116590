const servicesMap = {
  'battery': 'Battery Storage',
  'hvac-new': 'New Heating/Cooling',
  'hvac-service': 'Heating/Cooling Service',
  'ev-charger': 'EV Charger Install'
};

export function formatFormData(data) {
  console.log('Formatting data:', data);

  // Format basic fields
  const formatted = {
    firstName: data.firstName?.trim() || '',
    lastName: data.lastName?.trim() || '',
    email: data.email?.trim().toLowerCase() || '',
    phone: formatPhoneNumber(data.phone),
    services: formatServices(data.services)
  };

  console.log('Formatted result:', formatted);
  return formatted;
}

function formatPhoneNumber(phone) {
  if (!phone) return '';
  
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');
  
  // Format as (XXX) XXX-XXXX if 10 digits
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  }
  
  return phone;
}

function formatServices(services) {
  console.log('Formatting services:', services);

  if (!Array.isArray(services) || services.length === 0) {
    return [];
  }

  // Map service IDs to readable names
  return services.map(service => servicesMap[service] || service);
}