import React from 'react';
import { 
  ShieldCheckIcon, 
  SparklesIcon, 
  UserGroupIcon, 
  HeartIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const benefits = [
  {
    title: 'Expert Guidance',
    description: 'Our experienced team helps you navigate complex decisions with confidence.',
    icon: SparklesIcon,
    traditional: 'Hours of research and uncertainty',
    agilio: 'Personalized expert recommendations'
  },
  {
    title: 'Quality Assured',
    description: 'We work only with vetted professionals and premium products.',
    icon: ShieldCheckIcon,
    traditional: 'Unknown contractor quality',
    agilio: 'Pre-vetted, certified professionals'
  },
  {
    title: 'Stress-Free Process',
    description: 'One point of contact manages everything from start to finish.',
    icon: ClockIcon,
    traditional: 'Managing multiple contractors',
    agilio: 'Single point of contact for everything'
  },
  {
    title: 'Cost Transparency',
    description: 'Clear, upfront pricing with no hidden fees or surprises.',
    icon: CurrencyDollarIcon,
    traditional: 'Unexpected costs and overruns',
    agilio: 'Transparent, guaranteed pricing'
  }
];

const comparison = [
  {
    traditional: 'Time-consuming research and multiple quotes',
    agilio: 'One call connects you with everything you need'
  },
  {
    traditional: 'Uncertainty about contractor reliability',
    agilio: 'Thoroughly vetted professionals with proven track records'
  },
  {
    traditional: 'Coordinating between different service providers',
    agilio: 'Single point of contact manages entire project'
  },
  {
    traditional: 'No guarantee on work quality',
    agilio: 'Comprehensive satisfaction guarantee'
  },
  {
    traditional: 'Limited support after installation',
    agilio: 'Ongoing support and maintenance'
  }
];

export default function Benefits() {
  return (
    <div id="benefits" className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Why Choose Agilio</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The Agilio Difference
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience home improvement the way it should be - simple, professional, and stress-free.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {benefits.map((benefit) => (
            <div 
              key={benefit.title}
              className="relative p-8 bg-green-50 rounded-2xl"
            >
              <div className="flex items-center gap-x-4 mb-6">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{benefit.title}</h3>
              </div>
              <p className="text-gray-600 mb-6">{benefit.description}</p>
              <div className="space-y-4">
                <div className="flex items-start gap-x-3">
                  <ExclamationTriangleIcon className="h-6 w-6 flex-none mt-0.5 text-gray-400" />
                  <span className="text-sm text-gray-600">Traditional: {benefit.traditional}</span>
                </div>
                <div className="flex items-start gap-x-3">
                  <CheckCircleIcon className="h-6 w-6 flex-none mt-0.5 text-green-600" />
                  <span className="text-sm text-gray-900">Agilio: {benefit.agilio}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200">
          <h3 className="text-xl font-semibold text-gray-900 mb-8 text-center">
            Agilio vs Traditional Approach
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div>
              <div className="flex items-center gap-x-3 mb-6">
                <ExclamationTriangleIcon className="h-6 w-6 text-gray-400" />
                <h4 className="text-lg font-semibold text-gray-900">Traditional Process</h4>
              </div>
              <ul className="space-y-4">
                {comparison.map((item, index) => (
                  <li key={index} className="flex items-start gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-gray-600 text-sm">
                      {index + 1}
                    </div>
                    <span className="text-gray-600">{item.traditional}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="flex items-center gap-x-3 mb-6">
                <CheckCircleIcon className="h-6 w-6 text-green-600" />
                <h4 className="text-lg font-semibold text-gray-900">The Agilio Way</h4>
              </div>
              <ul className="space-y-4">
                {comparison.map((item, index) => (
                  <li key={index} className="flex items-start gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm">
                      {index + 1}
                    </div>
                    <span className="text-gray-900">{item.agilio}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}