import React, { useEffect } from 'react';

export default function TermlyWidget({ policyId }) {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    script.defer = true; // Add defer to improve loading
    script.id = 'termly-jssdk';
    
    // Error handling
    script.onerror = (error) => {
      console.warn('Termly policy widget script failed to load:', error);
    };
    
    // Only add if not already present
    if (!document.getElementById('termly-jssdk')) {
      document.body.appendChild(script);
    }

    return () => {
      // Cleanup on unmount
      const existingScript = document.getElementById('termly-jssdk');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <div 
      name="termly-embed" 
      data-id={policyId}
      className="min-h-[800px] w-full"
    />
  );
}