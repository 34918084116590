import React, { useState } from 'react';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import OutageStats from '../../components/sections/OutageStats';
import OutageImpact from '../../components/sections/OutageImpact';
import OutageProtection from '../../components/sections/OutageProtection';
import OutageReadiness from '../../components/sections/OutageReadiness';
import OutageCosts from '../../components/sections/OutageCosts';
import WhyLionEnergy from '../../components/sections/WhyLionEnergy';
import TechnologyComparison from '../../components/sections/TechnologyComparison';
import TaxCredit from '../../components/sections/TaxCredit';
import CTASection from '../../components/CTASection';
import BatteryCalculator from '../../components/calculator/BatteryCalculator';

export default function SanctuaryProduct() {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  return (
    <div className="bg-white">
      <SEO 
        title="Lion Energy Sanctuary"
        description="Experience true energy independence with Lion Energy Sanctuary. Our premium home battery system combines cutting-edge technology with unmatched reliability."
        canonical="/products/sanctuary"
        image="https://lionenergy.sirv.com/Images/Website/Sanctuary/Sanctuary-wall-mount-lossy.webp"
      />
      <PageHeader
        title="Lion Energy Sanctuary"
        description="Experience true energy independence with our premium home battery system. Advanced technology and unmatched reliability keep your home running, no matter what."
        showCalculator={true}
      />
      
      <OutageStats />
      <OutageImpact />
      <WhyLionEnergy />
      <OutageProtection />
      <OutageReadiness />
      <OutageCosts />
      <TechnologyComparison />
      <TaxCredit />
      <CTASection />

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </div>
  );
}