import { POWER_CONSTANTS } from '../powerCalculations';
import { BATTERY_SPECS } from './specs';

export function calculateSystemSize(powerNeeds) {
  const { totalAmps, peakPowerKw, dailyKwh, hasHighStartingLoads } = powerNeeds;

  // Calculate required inverters based on power needs
  const invertersForContinuous = Math.ceil(totalAmps / BATTERY_SPECS.inverterAmps);
  const invertersForPeak = Math.ceil(peakPowerKw / (BATTERY_SPECS.power * BATTERY_SPECS.sustainedPeakFactor));
  
  // Use the higher inverter count requirement
  const requiredInverters = Math.max(
    Math.min(Math.max(invertersForContinuous, invertersForPeak), BATTERY_SPECS.maxInverters),
    1
  );

  // Store daily kWh for backup time calculations
  return {
    inverterCount: requiredInverters,
    dailyKwh: dailyKwh
  };
}