import React, { useEffect } from 'react';

export default function HubSpotForm({ formId, region = 'na1', portalId = '42269009' }) {
  useEffect(() => {
    // Load HubSpot Forms v2 script
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.async = true;
    
    script.addEventListener('load', () => {
      // Create form after script loads
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: `#hubspot-form-${formId}`,
          region: region
        });
      }
    });

    document.head.appendChild(script);

    return () => {
      // Cleanup script on unmount
      document.head.removeChild(script);
      // Remove form container
      const formContainer = document.querySelector(`#hubspot-form-${formId}`);
      if (formContainer) {
        formContainer.innerHTML = '';
      }
    };
  }, [formId, portalId, region]);

  return (
    <div id={`hubspot-form-${formId}`} className="hubspot-form-container p-8" />
  );
}