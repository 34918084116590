import React from 'react';
import { 
  UserGroupIcon, 
  ShieldCheckIcon, 
  AcademicCapIcon,
  BuildingOfficeIcon,
  StarIcon,
  HandThumbUpIcon
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const requirements = [
  {
    name: 'Experience & Expertise',
    description: 'Minimum 5 years of industry experience with proven track record',
    icon: AcademicCapIcon,
    details: [
      'Demonstrated expertise in specific trade',
      'History of successful projects',
      'Up-to-date certifications',
      'Continuous learning commitment'
    ]
  },
  {
    name: 'Quality Standards',
    description: 'Rigorous quality control processes and attention to detail',
    icon: ShieldCheckIcon,
    details: [
      'Adherence to industry best practices',
      'Quality assurance protocols',
      'Regular performance reviews',
      'Customer satisfaction tracking'
    ]
  },
  {
    name: 'Customer Service',
    description: 'Excellence in communication and customer satisfaction',
    icon: HandThumbUpIcon,
    details: [
      'Professional communication skills',
      'Timely response standards',
      'Problem-solving ability',
      'Customer-first mindset'
    ]
  },
  {
    name: 'Business Standards',
    description: 'Strong business practices and professional operations',
    icon: BuildingOfficeIcon,
    details: [
      'Licensed and insured',
      'Clean background checks',
      'Financial stability',
      'Professional equipment'
    ]
  }
];

const benefits = [
  {
    name: 'Exclusive Territory',
    description: 'Protected service areas with guaranteed lead flow',
    icon: StarIcon,
  },
  {
    name: 'Training & Support',
    description: 'Ongoing training, certification, and technical support',
    icon: AcademicCapIcon,
  },
  {
    name: 'Growth Opportunities',
    description: 'Expand your business with our proven systems and support',
    icon: BuildingOfficeIcon,
  },
  {
    name: 'Quality Leads',
    description: 'Pre-qualified customers and streamlined sales process',
    icon: UserGroupIcon,
  },
];

export default function LocalProsNetwork() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Join Our Network</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Local Professional Network
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We're building the nation's premier network of home improvement professionals. 
            Our rigorous selection process and ongoing support ensure exceptional service 
            quality for our customers.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {requirements.map((requirement) => (
            <div key={requirement.name} className="relative p-8 bg-gray-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                  <requirement.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{requirement.name}</h3>
              </div>
              <p className="mt-4 text-gray-600">{requirement.description}</p>
              <ul className="mt-8 space-y-3">
                {requirement.details.map((detail) => (
                  <li key={detail} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{detail}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-3xl bg-green-50 p-8">
          <div className="mx-auto max-w-2xl lg:text-center mb-12">
            <h3 className="text-2xl font-semibold text-gray-900">
              Partner Benefits
            </h3>
            <p className="mt-4 text-lg text-gray-600">
              Join our network and unlock exclusive benefits designed to help your business thrive.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
            {benefits.map((benefit) => (
              <div key={benefit.name} className="relative bg-white p-6 rounded-xl">
                <div className="flex items-center gap-x-4 mb-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-100">
                    <benefit.icon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                </div>
                <h4 className="text-lg font-semibold text-gray-900 mb-2">{benefit.name}</h4>
                <p className="text-sm text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}