import React from 'react';
import { 
  ChatBubbleBottomCenterTextIcon, 
  ClipboardDocumentCheckIcon, 
  WrenchScrewdriverIcon,
  UserGroupIcon,
  ShieldCheckIcon,
  HeartIcon 
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const steps = [
  {
    name: 'Initial Consultation',
    description: "Share your needs with our experts in a personalized consultation. We'll understand your goals, concerns, and specific requirements.",
    icon: ChatBubbleBottomCenterTextIcon,
    details: [
      'Discuss your home improvement goals',
      'Review current challenges and pain points',
      'Understand your budget and timeline',
      'Answer all your questions and concerns'
    ],
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  },
  {
    name: 'Expert Assessment',
    description: 'Our team evaluates your needs and designs a customized solution that perfectly matches your requirements.',
    icon: ClipboardDocumentCheckIcon,
    details: [
      'Professional site evaluation',
      'Custom solution design',
      'Product recommendations',
      'Detailed cost analysis'
    ],
    image: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  },
  {
    name: 'Local Pro Validation',
    description: 'We connect you with thoroughly vetted local professionals who validate the solution and ensure perfect implementation.',
    icon: UserGroupIcon,
    details: [
      'Vetted installation partners',
      'Local expertise',
      'Quality assurance',
      'Installation planning'
    ],
    image: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  },
  {
    name: 'Professional Installation',
    description: 'Sit back while our certified professionals handle the entire installation process with meticulous attention to detail.',
    icon: WrenchScrewdriverIcon,
    details: [
      'Coordinated installation schedule',
      'Expert installation team',
      'Minimal disruption',
      'Quality workmanship'
    ],
    image: 'https://images.unsplash.com/photo-1621905251189-08b45d6a269e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  },
  {
    name: 'Quality Assurance',
    description: 'We conduct thorough testing and verification to ensure everything meets our high standards.',
    icon: ShieldCheckIcon,
    details: [
      'Comprehensive testing',
      'Performance verification',
      'Safety checks',
      'System optimization'
    ],
    image: 'https://images.unsplash.com/photo-1581092921461-eab62e97a780?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  },
  {
    name: 'Ongoing Support',
    description: "Our commitment doesn't end at installation. We provide continuous support to ensure your long-term satisfaction.",
    icon: HeartIcon,
    details: [
      '24/7 customer support',
      'Regular check-ins',
      'Maintenance guidance',
      'Future upgrade assistance'
    ],
    image: 'https://images.unsplash.com/photo-1573497620053-ea5300f94f21?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80'
  }
];

export default function ProcessSteps() {
  return (
    <div id="process" className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Simple Process</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Path to Success
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We've simplified the home improvement process into clear steps, each designed to ensure 
            your project's success and your complete satisfaction.
          </p>
        </div>

        <div className="space-y-24">
          {steps.map((step, index) => (
            <div 
              key={step.name}
              className={`grid grid-cols-1 lg:grid-cols-2 gap-16 items-center max-w-7xl mx-auto px-6 lg:px-8 ${
                index % 2 === 1 ? 'lg:flex-row-reverse' : ''
              }`}
            >
              <div className={`space-y-8 ${index % 2 === 1 ? 'lg:order-2' : ''}`}>
                <div className="flex items-center gap-x-4">
                  <div className="flex h-16 w-16 items-center justify-center rounded-xl bg-green-600">
                    <step.icon className="h-8 w-8 text-white" />
                  </div>
                  <h3 className="text-2xl font-semibold text-gray-900">{step.name}</h3>
                </div>
                <p className="text-lg text-gray-600 leading-8">{step.description}</p>
                <ul className="space-y-5">
                  {step.details.map((detail) => (
                    <li key={detail} className="flex items-center gap-x-4">
                      <div className="h-2.5 w-2.5 rounded-full bg-green-600" />
                      <span className="text-base text-gray-600">{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={index % 2 === 1 ? 'lg:order-1' : ''}>
                <img
                  src={step.image}
                  alt={step.name}
                  className="rounded-2xl shadow-xl aspect-[4/3] object-cover w-full"
                />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}