import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import HubSpotForm from '../components/forms/HubSpotForm';

export default function DataRemoval() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Data Removal Request"
        description="Request removal of your personal data from Agilio's systems. Learn about our data removal process and your privacy rights."
        canonical="/data-removal"
      />
      
      <Container className="py-24">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-8">
            Data Removal Request
          </h1>
          
          <div className="prose prose-lg max-w-none mb-12">
            <p>
              At Agilio, we respect your privacy and your right to control your personal data. 
              You can request the removal of your personal information from our systems using the form below.
            </p>

            <h2>What You Should Know</h2>
            <ul>
              <li>We will process your request within 30 days</li>
              <li>You will receive confirmation once your data has been removed</li>
              <li>Some data may be retained if required by law or for legitimate business purposes</li>
              <li>Removal requests are handled in accordance with applicable privacy laws</li>
            </ul>

            <h2>What Data Can Be Removed</h2>
            <ul>
              <li>Contact information (name, email, phone number)</li>
              <li>Address and location data</li>
              <li>Account preferences and settings</li>
              <li>Communication history</li>
              <li>Service request history</li>
            </ul>

            <h2>Data We May Retain</h2>
            <ul>
              <li>Information required for tax or legal compliance</li>
              <li>Anonymized or aggregated data used for analytics</li>
              <li>Records of completed transactions</li>
              <li>Safety and warranty-related information</li>
            </ul>

            <div className="bg-yellow-50 rounded-lg p-6 my-8">
              <h3 className="text-yellow-800 font-semibold mb-2">Important Note</h3>
              <p className="text-yellow-700 text-base">
                If you have active services or warranties with us, removing certain data may affect our 
                ability to provide support or honor warranties. Please contact our support team to discuss 
                your specific situation before proceeding with complete data removal.
              </p>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm ring-1 ring-gray-200 p-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Submit Data Removal Request
            </h2>
            <HubSpotForm 
              formId="99005f86-749a-48c3-985b-47ba54c26652"
              portalId="42269009"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}