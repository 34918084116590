import React from 'react';
import { CurrencyDollarIcon, DocumentCheckIcon, HomeIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const requirements = [
  {
    icon: HomeIcon,
    title: 'Residence Requirement',
    description: 'System must be installed at your primary or secondary residence in the United States',
  },
  {
    icon: CurrencyDollarIcon,
    title: 'System Size',
    description: 'Battery system must be at least 3 kilowatt-hours (kWh) in size',
  },
  {
    icon: DocumentCheckIcon,
    title: 'Ownership',
    description: 'You must be the owner of the battery system, not leasing or in a PPA',
  },
];

export default function TaxCredit() {
  return (
    <div className="bg-green-50 py-24 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <div className="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800 mb-6">
            Limited Time Offer
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Save Up to 30% with Federal Tax Credit
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Thanks to the Inflation Reduction Act, residential battery installations in 2023 can qualify for a 30% federal tax credit—even without solar panels.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {requirements.map((req) => (
            <div
              key={req.title}
              className="relative rounded-2xl border border-green-100 bg-white p-8"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                <req.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {req.title}
              </h3>
              <p className="mt-2 text-base leading-7 text-gray-600">
                {req.description}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-900/5">
          <p className="text-sm leading-6 text-gray-600">
            <span className="font-semibold">Note:</span> To qualify, the taxpayer must have taxable income and can only claim the credit on the original installation of the system. Other conditions may apply to your personal situation. Please refer to a tax expert in your area.
          </p>
          <p className="mt-4 text-sm leading-6 text-gray-500">
            Source: <a href="https://www.energy.gov/eere/solar/homeowners-guide-federal-tax-credit-solar-photovoltaics" className="text-green-600 hover:text-green-500" target="_blank" rel="noopener noreferrer">U.S. Department of Energy</a>
          </p>
        </div>
      </Container>
    </div>
  );
}