import { formatPowerValue } from '../../calculators/powerCalculations';

export function generateRequirementsPage(data, powerNeeds) {
  return `
    <div style="margin-bottom: 40px; page-break-after: always;">
      <h2 style="color: #16A34A; font-size: 24px; margin-bottom: 20px;">
        System Requirements
      </h2>
      
      <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px; margin-bottom: 30px;">
        ${generateMetricsSection(powerNeeds)}
      </div>

      ${data.solar?.hasSolar ? generateSolarSection(data.solar) : ''}
      ${generateApplianceTable(data.appliances)}
    </div>
  `;
}

function generateMetricsSection(powerNeeds) {
  return `
    <div style="background: #F0FDF4; padding: 20px; border-radius: 8px;">
      <div style="color: #6B7280; font-size: 14px;">Total Power</div>
      <div style="color: #16A34A; font-size: 24px; font-weight: bold;">
        ${formatPowerValue.amps(powerNeeds.totalAmps)}
      </div>
      ${powerNeeds.hasHighStartingLoads ? `
        <div style="color: #D97706; font-size: 12px; margin-top: 8px;">
          ⚠️ ${powerNeeds.highStartingCount} appliance${powerNeeds.highStartingCount !== 1 ? 's' : ''} 
          with high starting current
        </div>
      ` : ''}
    </div>
    
    <div style="background: #F0FDF4; padding: 20px; border-radius: 8px;">
      <div style="color: #6B7280; font-size: 14px;">Peak Power</div>
      <div style="color: #16A34A; font-size: 24px; font-weight: bold;">
        ${formatPowerValue.kw(powerNeeds.peakPowerKw)}
      </div>
    </div>
    
    <div style="background: #F0FDF4; padding: 20px; border-radius: 8px;">
      <div style="color: #6B7280; font-size: 14px;">Daily Usage</div>
      <div style="color: #16A34A; font-size: 24px; font-weight: bold;">
        ${formatPowerValue.kwh(powerNeeds.dailyKwh)}
      </div>
    </div>
  `;
}

function generateSolarSection(solar) {
  return `
    <div style="background: #F0FDF4; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
      <h3 style="color: #16A34A; font-size: 18px; margin-bottom: 16px;">Solar Integration</h3>
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;">
        <div>
          <div style="color: #6B7280; font-size: 14px;">System Size</div>
          <div style="color: #374151; font-size: 16px; font-weight: bold;">
            ${solar.systemSize} kW
          </div>
        </div>
        <div>
          <div style="color: #6B7280; font-size: 14px;">Location</div>
          <div style="color: #374151; font-size: 16px; font-weight: bold;">
            ${solar.state}
          </div>
        </div>
      </div>
    </div>
  `;
}

function generateApplianceTable(appliances) {
  return `
    <table style="width: 100%; border-collapse: collapse; margin-bottom: 40px;">
      <thead>
        <tr style="background: #F3F4F6;">
          <th style="text-align: left; padding: 12px; border: 1px solid #E5E7EB;">Appliance</th>
          <th style="text-align: left; padding: 12px; border: 1px solid #E5E7EB;">Quantity</th>
          <th style="text-align: left; padding: 12px; border: 1px solid #E5E7EB;">Running Power</th>
          <th style="text-align: left; padding: 12px; border: 1px solid #E5E7EB;">Starting Power</th>
          <th style="text-align: left; padding: 12px; border: 1px solid #E5E7EB;">Daily Usage</th>
        </tr>
      </thead>
      <tbody>
        ${appliances.map(app => `
          <tr>
            <td style="padding: 12px; border: 1px solid #E5E7EB;">${app.name}</td>
            <td style="padding: 12px; border: 1px solid #E5E7EB;">${app.quantity}x</td>
            <td style="padding: 12px; border: 1px solid #E5E7EB;">${formatPowerValue.watts(app.avgWatts)}</td>
            <td style="padding: 12px; border: 1px solid #E5E7EB;">${formatPowerValue.watts(app.startWatts)}</td>
            <td style="padding: 12px; border: 1px solid #E5E7EB;">${app.hoursPerDay} hours/day</td>
          </tr>
        `).join('')}
      </tbody>
    </table>
  `;
}