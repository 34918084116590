import React from 'react';
import { ClockIcon, ChartBarIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const features = [
  {
    name: 'Peak Rate Avoidance',
    description: 'Automatically use stored energy during expensive peak hours, avoiding high utility rates.',
    icon: ClockIcon,
  },
  {
    name: 'Smart Charging',
    description: 'Charge your battery system when electricity rates are lowest for maximum savings.',
    icon: ChartBarIcon,
  },
  {
    name: 'Cost Optimization',
    description: 'AI-powered algorithms continuously optimize your energy usage patterns for best savings.',
    icon: CurrencyDollarIcon,
  },
];

export default function TimeOfUseOptimization() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Smart Savings</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Time-of-Use Rate Optimization
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our smart energy management system automatically optimizes your energy usage based on utility rates, 
            helping you save money every day.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="relative p-8 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</h3>
              </div>
              <p className="mt-4 text-base leading-7 text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 relative overflow-hidden rounded-3xl bg-green-600">
          <div className="absolute inset-0 bg-gradient-to-br from-green-600 to-green-700" />
          <div className="relative px-8 py-12">
            <div className="mx-auto max-w-2xl text-center">
              <h3 className="text-2xl font-semibold text-white">
                See Your Potential Savings
              </h3>
              <p className="mt-4 text-lg text-green-100">
                Let our experts analyze your utility bills and show you how much you could save with smart energy management.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}