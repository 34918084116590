export const california = {
  name: 'California',
  hasNetMetering: true,
  creditRate: 'NEM 3.0',
  aggregation: true,
  features: [
    'Time-of-use rates required',
    'Battery storage incentives',
    'Virtual net metering',
    'Community solar options'
  ],
  notes: 'California transitioned to NEM 3.0 in April 2023, which uses an "Avoided Cost Calculator" to determine export rates.',
  utilities: [
    {
      name: 'Pacific Gas & Electric',
      description: 'Serves Northern California',
      programUrl: 'https://www.pge.com/en_US/residential/solar-and-vehicles/green-energy-incentives/solar-and-renewable-metering-and-billing/net-energy-metering-program-tracking/net-energy-metering-nem-2.page',
      features: [
        'NEM 3.0 program',
        'Battery incentives',
        'Virtual power plant',
        'Time-of-use rates'
      ]
    },
    {
      name: 'Southern California Edison',
      description: 'Serves Southern California',
      programUrl: 'https://www.sce.com/residential/generating-your-own-power/net-energy-metering',
      features: [
        'NEM 3.0 program',
        'Storage solutions',
        'Virtual net metering',
        'Grid reliability programs'
      ]
    }
  ]
};