import React from 'react';
import { 
  BuildingOfficeIcon, 
  BoltIcon, 
  TruckIcon, 
  ShoppingBagIcon, 
  UserGroupIcon, 
  ShieldCheckIcon,
  SparklesIcon,
  HeartIcon 
} from '@heroicons/react/24/outline';
import Container from '../components/layout/Container';
import SEO from '../components/SEO';
import LocalProsNetwork from '../components/sections/LocalProsNetwork';

const partnerships = [
  {
    type: 'Manufacturers',
    icon: BuildingOfficeIcon,
    description: 'We partner with leading manufacturers to bring premium home improvement products to our customers.',
    benefits: [
      'Direct access to premium products',
      'Competitive pricing through volume',
      'Latest product innovations',
      'Comprehensive warranty support'
    ]
  },
  {
    type: 'Utilities',
    icon: BoltIcon,
    description: 'Strong relationships with utility companies ensure smooth integration and maximum benefits for homeowners.',
    benefits: [
      'Streamlined grid connections',
      'Rebate program assistance',
      'Net metering coordination',
      'Rate optimization guidance'
    ]
  },
  {
    type: 'Distributors',
    icon: TruckIcon,
    description: 'Our distribution network ensures reliable product availability and efficient delivery.',
    benefits: [
      'Nationwide product availability',
      'Quick delivery timelines',
      'Quality-controlled supply chain',
      'Local inventory management'
    ]
  },
  {
    type: 'Retailers',
    icon: ShoppingBagIcon,
    description: 'Strategic retail partnerships expand our reach while maintaining service quality.',
    benefits: [
      'Expanded market presence',
      'Consistent brand experience',
      'Local market expertise',
      'Simplified purchasing process'
    ]
  }
];

const advantages = [
  {
    name: 'Vetted Professional Network',
    description: 'Our carefully selected network of local professionals meets rigorous quality and service standards.',
    icon: UserGroupIcon,
  },
  {
    name: 'Quality Assurance',
    description: 'Comprehensive quality control processes ensure consistent, high-quality installations.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Concierge Experience',
    description: 'Single point of contact manages all aspects of your project for a seamless experience.',
    icon: SparklesIcon,
  },
  {
    name: 'Customer Success',
    description: 'Dedicated support team ensures your complete satisfaction throughout the entire journey.',
    icon: HeartIcon,
  }
];

export default function Partnerships() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Partnerships"
        description="Join our network of industry leaders and local professionals delivering exceptional home improvement experiences through our innovative concierge service model."
        canonical="/partnerships"
      />
      
      {/* Top Section */}
      <div className="relative isolate overflow-hidden bg-green-600 py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Join Our Partner Network
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Whether you're a manufacturer, distributor, or local professional, discover how partnering with Agilio can help grow your business.
            </p>
          </div>
        </div>
      </div>

      {/* Partnership Network */}
      <div className="py-24 bg-gray-50">
        <Container>
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-green-600">Our Network</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Powerful Industry Partnerships
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We've built strong relationships across the industry to deliver premium products 
              and exceptional service to our customers.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            {partnerships.map((partner) => (
              <div key={partner.type} className="relative p-8 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200">
                <div className="flex items-center gap-x-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                    <partner.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900">{partner.type}</h3>
                </div>
                <p className="mt-4 text-gray-600">{partner.description}</p>
                <ul className="mt-8 space-y-3">
                  {partner.benefits.map((benefit) => (
                    <li key={benefit} className="flex items-center gap-x-3">
                      <div className="h-2 w-2 rounded-full bg-green-600" />
                      <span className="text-sm text-gray-600">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </div>

      {/* Local Pros Network */}
      <LocalProsNetwork />

      {/* Advantages Section */}
      <div className="py-24 bg-white">
        <Container>
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-green-600">The Agilio Advantage</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Why Our Model Works
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our unique approach combines the strength of national partnerships with the personal 
              touch of local service.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            {advantages.map((advantage) => (
              <div key={advantage.name} className="relative p-8 bg-gray-50 rounded-2xl">
                <div className="flex items-center gap-x-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <advantage.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{advantage.name}</h3>
                </div>
                <p className="mt-4 text-gray-600">{advantage.description}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>

      {/* Bottom Section */}
      <div className="py-24 bg-green-600">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to Join Our Network?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Whether you're a manufacturer, distributor, or local professional, we'd love to explore 
              how we can work together to transform the home improvement industry.
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
}