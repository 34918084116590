import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import { sanctuarySpecs } from '../../utils/productSpecs';

const checklist = [
  {
    title: 'Before Installation',
    items: [
      'Identify critical loads and backup power needs',
      'Professional site assessment',
      'Custom system design based on your requirements',
      'Permit acquisition and utility coordination',
    ],
  },
  {
    title: 'During Installation',
    items: [
      'Professional installation by certified technicians',
      'Integration with existing electrical system',
      'Smart monitoring system setup',
      'Full system testing and verification',
    ],
  },
  {
    title: 'After Installation',
    items: [
      '24/7 monitoring and alerts',
      'Automatic updates and optimization',
      `${sanctuarySpecs.detailedSpecs.warranty.standard} warranty coverage`,
      'Ongoing support and maintenance',
    ],
  },
];

export default function OutageReadiness() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Path to Power Security
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We handle every aspect of getting your home ready for power outages, from assessment to installation and beyond.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {checklist.map((section) => (
            <div key={section.title} className="bg-gray-50 rounded-2xl p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-6">{section.title}</h3>
              <ul className="space-y-4">
                {section.items.map((item) => (
                  <li key={item} className="flex items-start gap-x-3">
                    <CheckCircleIcon className="h-6 w-6 flex-none mt-0.5 text-green-600" />
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}