import React from 'react';

export default function ShowcaseImage() {
  return (
    <div className="relative">
      <img
        src="https://lionenergy.sirv.com/Images/Website/Sanctuary/garage%20with%20new%20Sactuary.webp"
        alt="Lion Energy Sanctuary home battery installation"
        className="rounded-2xl shadow-xl"
      />
    </div>
  );
}