import React from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import { solarIntegrationArticle } from '../../utils/articles/solarIntegration';

export default function SolarIntegration() {
  return (
    <>
      <SEO 
        title={solarIntegrationArticle.title}
        description={solarIntegrationArticle.description}
        canonical="/learning-center/articles/solar-integration"
      />
      <ArticleLayout article={solarIntegrationArticle}>
        {/* Call to Action */}
        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Ready to Integrate Solar with Battery Storage?</h3>
          <p className="text-gray-600 mb-6">
            Talk to our experts about maximizing your solar investment with smart battery integration. We'll help you:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>Design an optimized system</li>
            <li>Calculate potential savings</li>
            <li>Explore available incentives</li>
            <li>Plan for future expansion</li>
          </ul>
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
          >
            Get Expert Help
          </a>
        </div>
      </ArticleLayout>
    </>
  );
}