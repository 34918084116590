import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CalculatorStepper from './CalculatorStepper';
import ApplianceStep from './steps/ApplianceStep';
import SolarStep from './steps/SolarStep';
import RecommendationStep from './steps/RecommendationStep/index'; // Updated import path

export default function BatteryCalculator({ isOpen, onClose }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [calculatorData, setCalculatorData] = useState({
    appliances: [],
    solar: {
      hasSolar: false,
      state: '',
      systemSize: 0
    },
    selectedSolution: null
  });

  const steps = [
    { name: 'Appliances', component: ApplianceStep },
    { name: 'Solar System', component: SolarStep },
    { name: 'Recommendation', component: RecommendationStep }
  ];

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleUpdateData = (data) => {
    setCalculatorData((prev) => ({ ...prev, ...data }));
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <Dialog as="div" className="relative z-50" open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="w-full">
                <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900 mb-8">
                  Battery System Calculator
                </Dialog.Title>

                <CalculatorStepper steps={steps} currentStep={currentStep} />

                <div className="mt-8">
                  <CurrentStepComponent
                    data={calculatorData}
                    onUpdate={handleUpdateData}
                    onNext={handleNext}
                    onBack={handleBack}
                    onComplete={onClose}
                    isFirstStep={currentStep === 0}
                    isLastStep={currentStep === steps.length - 1}
                  />
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}