import React from 'react';

export default function ShowcaseHeader() {
  return (
    <div className="mx-auto max-w-2xl lg:text-center mb-16">
      <h2 className="text-base font-semibold leading-7 text-green-200">Featured Solution</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
        Transform Your Home Energy
      </p>
      <p className="mt-6 text-lg leading-8 text-gray-300">
        Experience true energy independence with our premium home battery system. 
        Our cutting-edge technology combines unmatched reliability with smart energy management.
      </p>
    </div>
  );
}