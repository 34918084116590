/**
 * Format a duration in hours to a human-readable string
 */
export function formatDuration(hours) {
  if (!Number.isFinite(hours)) return 'Infinite';
  
  // Round to 2 decimal places
  const roundedHours = Number(Number(hours).toFixed(2));
  
  if (roundedHours < 24) {
    return `${roundedHours} hours`;
  }
  
  const days = Math.floor(roundedHours / 24);
  const remainingHours = Number((roundedHours % 24).toFixed(2));
  
  if (remainingHours === 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  
  return `${days} day${days > 1 ? 's' : ''} ${remainingHours} hours`;
}