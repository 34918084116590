import React from 'react';
import { BoltIcon, ChartBarIcon, ExclamationTriangleIcon, ScaleIcon } from '@heroicons/react/24/outline';

export default function SystemSizing() {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-semibold text-gray-900 mb-6">
        Right-Sizing Your Battery System
      </h2>

      {/* Key Considerations */}
      <div className="bg-green-50 rounded-2xl p-8">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Key Sizing Considerations
        </h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="flex gap-x-3">
              <BoltIcon className="h-6 w-6 flex-none text-green-600 mt-1" />
              <div>
                <h4 className="font-semibold text-gray-900">Continuous Power Needs</h4>
                <p className="text-gray-600">Each battery provides 8kW of continuous power. Your total system size should match your peak continuous power usage.</p>
              </div>
            </div>

            <div className="flex gap-x-3">
              <ExclamationTriangleIcon className="h-6 w-6 flex-none text-green-600 mt-1" />
              <div>
                <h4 className="font-semibold text-gray-900">Inrush Current</h4>
                <p className="text-gray-600">Many appliances require 2-3 times their running power when starting. Your system must handle these brief power spikes.</p>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex gap-x-3">
              <ChartBarIcon className="h-6 w-6 flex-none text-green-600 mt-1" />
              <div>
                <h4 className="font-semibold text-gray-900">Load Analysis</h4>
                <p className="text-gray-600">Identify all appliances that need backup power. Consider both their continuous and starting power requirements.</p>
              </div>
            </div>

            <div className="flex gap-x-3">
              <ScaleIcon className="h-6 w-6 flex-none text-green-600 mt-1" />
              <div>
                <h4 className="font-semibold text-gray-900">System Scaling</h4>
                <p className="text-gray-600">Multiple batteries can be combined to increase both power and capacity. Each inverter supports up to 3 batteries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inrush Current Examples */}
      <div className="bg-white rounded-2xl p-8 shadow-sm ring-1 ring-gray-200">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Common Inrush Current Examples
        </h3>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Appliance</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Running Power</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Starting Power</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Inrush Factor</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">AC Unit</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">3,500W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">4,900W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">1.4x</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">Well Pump</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">1,000W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">3,000W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">3x</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">Refrigerator</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">150W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">1,200W</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">8x</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
