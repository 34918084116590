import React from 'react';
import { Battery100Icon, ClockIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { formatPowerValue } from '../../../../utils/calculators/powerCalculations';
import { sanctuarySpecs } from '../../../../utils/productSpecs';
import InverterDisplay from '../../components/InverterDisplay';

export default function SolutionGrid({ solutions }) {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
      {Object.entries(solutions).map(([level, solution]) => (
        <div
          key={level}
          className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-6 shadow-sm h-full"
        >
          <div className="text-center flex-1">
            <h3 className="text-lg font-semibold text-gray-900 capitalize mb-4">{level}</h3>
            
            <div className="bg-green-50 rounded-lg p-4 mb-6">
              <div className="flex items-center justify-center gap-x-2 mb-2">
                <ClockIcon className="h-5 w-5 text-green-600" />
                <span className="text-sm font-medium text-green-600">Backup Duration</span>
              </div>
              <div className="text-2xl font-bold text-gray-900">
                {solution.backupTime} hours
              </div>
            </div>

            <div className="space-y-6">
              <InverterDisplay count={solution.inverters} />
              <BatteryDisplay count={solution.batteries} />
            </div>

            <SystemSpecs solution={solution} />
            
            <div className="mt-6 text-left space-y-2">
              <WarrantyInfo />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function BatteryDisplay({ count }) {
  return (
    <div>
      <div className="text-sm font-medium text-gray-500">
        {count} {sanctuarySpecs.name} Batter{count > 1 ? 'ies' : 'y'}
      </div>
      <div className="flex flex-wrap justify-center gap-2 px-2">
        {[...Array(count)].map((_, i) => (
          <div key={i} className="relative w-10">
            <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-800">
              <Battery100Icon className="h-5 w-5 text-white" />
            </div>
            <div className="mt-1 text-xs text-gray-500">13.5 kWh</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SystemSpecs({ solution }) {
  return (
    <div className="mt-8 bg-gray-50 rounded-lg p-4">
      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600">Total Power:</span>
          <span className="text-sm font-semibold text-gray-900">
            {formatPowerValue.kw(solution.totalPower)}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600">Storage Capacity:</span>
          <span className="text-sm font-semibold text-gray-900">
            {formatPowerValue.kwh(solution.totalCapacity)}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600">System Efficiency:</span>
          <span className="text-sm font-semibold text-gray-900">
            {sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency}
          </span>
        </div>
      </div>
    </div>
  );
}

function WarrantyInfo() {
  return (
    <div className="flex items-center gap-x-2">
      <ShieldCheckIcon className="h-4 w-4 text-green-600" />
      <span className="text-sm text-gray-600">
        {sanctuarySpecs.detailedSpecs.warranty.standard} warranty
      </span>
    </div>
  );
}