import React from 'react';
import { useRecommendationStep } from './useRecommendationStep';
import PowerSummary from './PowerSummary';
import SolutionGrid from './SolutionGrid';
import ActionButtons from './ActionButtons';
import EmptyState from './EmptyState';
import ErrorState from './ErrorState';

export default function RecommendationStep({ data, onUpdate, onBack, onComplete }) {
  const { 
    powerNeeds, 
    solutions,
    error
  } = useRecommendationStep(data);

  if (!data?.appliances?.length) {
    return <EmptyState onBack={onBack} />;
  }

  if (error || !powerNeeds || !solutions) {
    return <ErrorState onBack={onBack} error={error} />;
  }

  return (
    <div className="space-y-6">
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-2">
          System Recommendations
        </h4>
        <p className="text-sm text-gray-600 mb-6">
          Based on your power needs, here are our recommended configurations. 
          Each solution provides different backup durations and capacity options.
        </p>

        <PowerSummary powerNeeds={powerNeeds} />
        <SolutionGrid solutions={solutions} />
      </div>

      <ActionButtons 
        onBack={onBack}
        onComplete={onComplete}
        data={data}
        powerNeeds={powerNeeds}
        solutions={solutions}
      />
    </div>
  );
}