import React, { useState } from 'react';
import BatteryCalculator from '../../../../components/calculator/BatteryCalculator';
import { event } from '../../../../utils/analytics/gtag';

export default function ActionButtons() {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  const handleCalculatorOpen = () => {
    // Track the event
    event({
      action: 'open_calculator',
      category: 'Engagement',
      label: 'home_showcase',
      value: 1
    });
    
    setIsCalculatorOpen(true);
  };

  return (
    <>
      <div className="mt-8 flex gap-4">
        <a
          href="https://agilio.go-kf.com/contact-us"
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
        >
          Get Started
        </a>
        <button
          onClick={handleCalculatorOpen}
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50"
        >
          Size Your System
        </button>
      </div>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </>
  );
}