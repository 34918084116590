import React from 'react';
import { ShieldCheckIcon, SparklesIcon, UserGroupIcon, HeartIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const features = [
  {
    name: 'Expert Guidance',
    description: 'Our experienced team helps you navigate complex decisions with confidence, ensuring you choose the perfect solutions for your home.',
    icon: SparklesIcon,
  },
  {
    name: 'Vetted Professionals',
    description: 'We partner only with thoroughly vetted local professionals who meet our high standards for quality and reliability.',
    icon: UserGroupIcon,
  },
  {
    name: 'Quality Assurance',
    description: 'Every project is backed by our comprehensive quality assurance process and satisfaction guarantee.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Ongoing Support',
    description: 'Our commitment extends beyond installation with dedicated support throughout your journey.',
    icon: HeartIcon,
  },
];

export default function WhyAgilio() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Why Choose Agilio</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Trusted Partner
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We take the complexity out of home improvements by managing every aspect of your project, 
            ensuring a seamless experience from start to finish.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {features.map((feature) => (
            <div key={feature.name} className="relative p-8 bg-gray-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</h3>
              </div>
              <p className="mt-4 text-base leading-7 text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}