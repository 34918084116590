import React from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import { homeBatteryGuideArticle } from '../../utils/articles/homeBatteryGuide';

export default function HomeBatteryGuide() {
  return (
    <>
      <SEO 
        title={homeBatteryGuideArticle.title}
        description={homeBatteryGuideArticle.description}
        canonical="/learning-center/articles/home-battery-guide"
      />
      <ArticleLayout article={homeBatteryGuideArticle}>
        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3>Ready to Get Started?</h3>
          <p>
            Talk to our experts about finding the right battery solution for your home.
          </p>
          <div className="mt-4">
            <a
              href="https://agilio.go-kf.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-500"
            >
              Explore Battery Solutions
            </a>
          </div>
        </div>
      </ArticleLayout>
    </>
  );
}