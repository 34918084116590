import { texas } from './states/texas';
import { newYork } from './states/newYork';
import { california } from './states/california';
import { florida } from './states/florida';

// Combine all state data
export const stateNetMeteringData = [
  texas,
  newYork,
  california,
  florida
];

// Export utility functions
export {
  getStateNetMeteringData,
  getStateUtilities,
  hasNetMetering,
  getUtilityByName
} from './utils';