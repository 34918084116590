import React from 'react';
import { Link } from 'react-router-dom';

export default function TopicNav({ topics }) {
  return (
    <div className="sticky top-24 space-y-1">
      <h2 className="text-sm font-semibold text-gray-900 mb-4">Topics</h2>
      <nav className="space-y-1">
        {topics.map((topic) => (
          <Link
            key={topic.id}
            to={`/learning-center/topics/${topic.id}`}
            className="flex items-center gap-x-3 rounded-lg px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          >
            <topic.icon className="h-5 w-5 flex-none" />
            <span>{topic.name}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
}