import React from 'react';

export default function TableOfContents({ sections }) {
  return (
    <div className="sticky top-24">
      <h3 className="text-sm font-semibold text-gray-900 mb-4">
        Table of Contents
      </h3>
      <nav className="space-y-1">
        {sections.map((section) => {
          const sectionId = section.title.toLowerCase().replace(/\s+/g, '-');
          return (
            <a
              key={section.title}
              href={`#${sectionId}`}
              className="block px-3 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-900 rounded-lg"
            >
              {section.title}
            </a>
          );
        })}
      </nav>
    </div>
  );
}