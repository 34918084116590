import React from 'react';
import Container from '../components/layout/Container';
import ProductCard from '../components/products/ProductCard';
import CategoryHeader from '../components/products/CategoryHeader';
import PageHeader from '../components/PageHeader';
import SanctuarySolution from '../components/sections/SanctuarySolution';
import SEO from '../components/SEO';
import { sanctuarySpecs } from '../utils/productSpecs';

// ... rest of the imports

export default function Products() {
  return (
    <div className="bg-white">
      <SEO 
        title="Products"
        description="Discover our premium home improvement solutions, from energy storage systems to smart HVAC. Every product backed by expert installation and our satisfaction guarantee."
        canonical="/products"
      />
      <PageHeader
        title="Our Solutions"
        description="Discover our curated selection of premium home improvement products, backed by expert installation and our satisfaction guarantee."
        showCalculator={true}
      />
      
      <Container className="py-24">
        {/* Rest of the component remains the same */}
      </Container>
      
      <div className="mt-24">
        <SanctuarySolution />
      </div>
    </div>
  );
}