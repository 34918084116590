// Battery system specifications
export const BATTERY_SPECS = {
  capacity: 13.5, // kWh per battery
  power: 8, // kW per battery
  maxBatteriesPerInverter: 3,
  roundTripEfficiency: 0.94,
  inverterAmps: 33.3, // Amps per inverter at 240V
  maxInverters: 3,
  minSystemSize: 1, // Minimum number of batteries
  peakPowerDuration: 10, // seconds
  sustainedPeakFactor: 1.5 // 150% of continuous power
};