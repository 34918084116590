import React from 'react';
import { Link } from 'react-router-dom';
import { ClockIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

export default function ArticleGrid({ articles }) {
  return (
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
      {articles.map((article) => (
        <Link
          key={article.id}
          to={`/learning-center/articles/${article.id}`}
          className="group relative flex flex-col overflow-hidden rounded-2xl bg-white shadow-sm ring-1 ring-gray-200 hover:ring-green-200 transition-all duration-200"
        >
          <div className="aspect-[16/9] overflow-hidden">
            <img
              src={article.image}
              alt={article.title}
              className="h-full w-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
            />
          </div>
          
          <div className="flex flex-1 flex-col justify-between p-6">
            <div className="flex-1">
              <div className="flex items-center gap-x-4 text-xs">
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700">
                  {article.topic}
                </span>
                <span className="inline-flex items-center text-gray-500">
                  <ClockIcon className="mr-1.5 h-4 w-4" />
                  {article.readTime} min read
                </span>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-semibold leading-6 text-gray-900 group-hover:text-green-600">
                  {article.title}
                </h3>
                <p className="mt-3 text-base text-gray-600">
                  {article.description}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-2 text-sm font-medium text-green-600 group-hover:text-green-500">
                Read article
                <ArrowRightIcon className="h-4 w-4 transition-transform group-hover:translate-x-1" />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}