import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Core Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import CookieConsent from './components/CookieConsent';
import LeadForm from './components/LeadForm';
import GoogleAnalytics from './components/analytics/GoogleAnalytics';

// Main Pages
import Home from './pages/Home';
import Products from './pages/Products';
import Solutions from './pages/Solutions';
import SmartEnergySolutions from './pages/SmartEnergySolutions';
import HowItWorks from './pages/HowItWorks';

// Product Pages
import SanctuaryProduct from './pages/products/SanctuaryProduct';

// Solution Pages
import PowerOutages from './pages/solutions/PowerOutages';
import EnergySavings from './pages/solutions/EnergySavings';
import HVAC from './pages/solutions/HVAC';

// Partnership Pages
import Partnerships from './pages/Partnerships';
import LocalPros from './pages/partnerships/LocalPros';
import Utilities from './pages/partnerships/Utilities';
import Manufacturers from './pages/partnerships/Manufacturers';
import Distributors from './pages/partnerships/Distributors';

// Resource Pages
import EnergyDefinitions from './pages/EnergyDefinitions';
import Documentation from './pages/Documentation';
import LearningCenter from './pages/LearningCenter';

// Article Pages
import NetMetering from './pages/articles/NetMetering';
import BatterySizing from './pages/articles/BatterySizing';
import SolarIntegration from './pages/articles/SolarIntegration';
import EnergySavingsArticle from './pages/articles/EnergySavings';
import MaintenanceGuide from './pages/articles/MaintenanceGuide';
import HomeBatteryGuide from './pages/articles/HomeBatteryGuide';

// Legal Pages
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DataRemoval from './pages/DataRemoval';

// Create context for modal state management
export const ModalContext = React.createContext({
  isLeadFormOpen: false,
  setIsLeadFormOpen: () => {},
});

export default function App() {
  const [isLeadFormOpen, setIsLeadFormOpen] = React.useState(false);

  return (
    <ModalContext.Provider value={{ isLeadFormOpen, setIsLeadFormOpen }}>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <GoogleAnalytics />
          <CookieConsent />
          <div className="bg-white">
            <Navbar />
            <Routes>
              {/* Main Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/smart-energy-solutions" element={<SmartEnergySolutions />} />
              <Route path="/products/sanctuary" element={<SanctuaryProduct />} />
              <Route path="/outage" element={<PowerOutages />} />
              <Route path="/saveonenergy" element={<EnergySavings />} />
              <Route path="/hvac" element={<HVAC />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              
              {/* Partnership Routes */}
              <Route path="/partnerships" element={<Partnerships />} />
              <Route path="/partnerships/local-pros" element={<LocalPros />} />
              <Route path="/partnerships/utilities" element={<Utilities />} />
              <Route path="/partnerships/manufacturers" element={<Manufacturers />} />
              <Route path="/partnerships/distributors" element={<Distributors />} />
              
              {/* Legal Routes */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/data-removal" element={<DataRemoval />} />
              
              {/* Resource Routes */}
              <Route path="/energy-definitions" element={<EnergyDefinitions />} />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/learning-center" element={<LearningCenter />} />
              
              {/* Article Routes */}
              <Route path="/learning-center/articles/net-metering" element={<NetMetering />} />
              <Route path="/learning-center/articles/battery-sizing" element={<BatterySizing />} />
              <Route path="/learning-center/articles/solar-integration" element={<SolarIntegration />} />
              <Route path="/learning-center/articles/energy-savings" element={<EnergySavingsArticle />} />
              <Route path="/learning-center/articles/maintenance-tips" element={<MaintenanceGuide />} />
              <Route path="/learning-center/articles/home-battery-guide" element={<HomeBatteryGuide />} />
              
              {/* Redirect old routes to new ones */}
              <Route path="/solutions/power-outages" element={<Navigate to="/outage" replace />} />
              <Route path="/solutions/energy-savings" element={<Navigate to="/saveonenergy" replace />} />
              
              {/* Catch all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />

            {/* Lead Form Modal */}
            <LeadForm 
              isOpen={isLeadFormOpen}
              onClose={() => setIsLeadFormOpen(false)}
            />
          </div>
        </Router>
      </HelmetProvider>
    </ModalContext.Provider>
  );
}