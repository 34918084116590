import React from 'react';
import SEO from '../../components/SEO';
import OutageStats from '../../components/sections/OutageStats';
import OutageImpact from '../../components/sections/OutageImpact';
import OutageProtection from '../../components/sections/OutageProtection';
import OutageReadiness from '../../components/sections/OutageReadiness';
import OutageCosts from '../../components/sections/OutageCosts';
import SanctuarySolution from '../../components/sections/SanctuarySolution';
import TechnologyComparison from '../../components/sections/TechnologyComparison';
import TaxCredit from '../../components/sections/TaxCredit';
import CTASection from '../../components/CTASection';
import HubSpotForm from '../../components/forms/HubSpotForm';
import Container from '../../components/layout/Container';
import HeroCalculatorButton from '../../components/ui/HeroCalculatorButton';

export default function PowerOutages() {
  return (
    <div className="bg-white">
      <SEO 
        title="Power Outage Protection"
        description="Keep your home running during blackouts with reliable backup power. Our solutions provide instant, automatic power switchover and 24/7 monitoring."
        canonical="/outage"
        image="https://lionenergy.sirv.com/Images/Website/Sanctuary/Sanctuary-wall-mount-lossy.webp"
      />
      
      <div className="relative isolate overflow-hidden pt-24">
        <div className="absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-gray-900/60" />
          <img
            src="https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"
            alt="Dark house exterior during power outage"
            className="h-full w-full object-cover"
          />
        </div>

        <Container className="relative z-10 py-24 sm:py-32">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-8 inline-flex items-center rounded-full bg-green-600/10 px-3 py-1 text-sm leading-6 text-green-200 ring-1 ring-inset ring-green-600/20">
                Never lose power again
              </div>
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Protect Your Home From Power Outages
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Keep your home running with continuous backup power. Our system activates instantly when the grid fails, ensuring uninterrupted power to your essential needs.
              </p>
              <div className="mt-10 flex gap-x-4">
                <a
                  href="https://agilio.go-kf.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                >
                  Get Protected
                </a>
                <HeroCalculatorButton />
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-8">
                <HubSpotForm formId="33a85a6a-b60f-4fa0-96b7-99fb642da5bf" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      
      <OutageStats />
      <SanctuarySolution />
      <OutageImpact />
      <OutageProtection />
      <OutageReadiness />
      <OutageCosts />
      <TechnologyComparison />
      <TaxCredit />
      <CTASection />
    </div>
  );
}