import React from 'react';
import { DocumentTextIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export default function DocumentationSection({ id, title, description, documents }) {
  return (
    <div id={id} className="scroll-mt-24">
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">{title}</h2>
      <p className="text-gray-600 mb-8">{description}</p>
      
      <div className="grid grid-cols-1 gap-4">
        {documents.map((doc) => (
          <div 
            key={doc.title}
            className="flex items-center justify-between p-6 bg-white rounded-xl border border-gray-200 hover:border-green-200 hover:bg-green-50 transition-colors duration-200"
          >
            <div className="flex items-center gap-x-4">
              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                <DocumentTextIcon className="h-6 w-6 text-white" />
              </div>
              <div>
                <h3 className="text-base font-semibold text-gray-900">{doc.title}</h3>
                <p className="text-sm text-gray-500">{doc.description}</p>
              </div>
            </div>
            <a
              href={doc.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-x-2 rounded-lg bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-200 hover:bg-green-50"
            >
              <ArrowDownTrayIcon className="h-5 w-5" />
              Download
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}