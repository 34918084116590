// Constants for power calculations
export const POWER_CONSTANTS = {
  VOLTAGE: 240, // Standard home voltage
  HIGH_INRUSH_THRESHOLD: 1000, // Watts threshold for high starting current
  INRUSH_DURATION: 0.5, // Duration in seconds for starting current
  SAFETY_FACTOR: 1.2 // 20% safety margin for calculations
};

// Format power values for display
export const formatPowerValue = {
  amps: (value) => `${Number(value).toFixed(1)}A`,
  watts: (value) => `${Number(value).toLocaleString()}W`,
  kw: (value) => `${Number(value).toFixed(1)} kW`,
  kwh: (value) => `${Number(value).toFixed(1)} kWh`
};

/**
 * Calculate total power requirements including inrush current considerations
 */
export function calculatePowerNeeds(appliances) {
  const baseCalculations = calculateBasePower(appliances);
  const inrushImpact = calculateInrushImpact(appliances);
  const totalRequirements = combinePowerRequirements(baseCalculations, inrushImpact);

  return {
    totalAmps: Number(totalRequirements.effectiveAmps.toFixed(1)),
    continuousPowerKw: Number((totalRequirements.continuousWatts / 1000).toFixed(1)),
    peakPowerKw: Number((totalRequirements.peakWatts / 1000).toFixed(1)),
    dailyKwh: Number((totalRequirements.dailyWattHours / 1000).toFixed(1)),
    hasHighStartingLoads: inrushImpact.hasHighInrushLoads,
    highStartingCount: inrushImpact.highInrushCount,
    inrushGroups: inrushImpact.groups
  };
}

/**
 * Check if an appliance has high starting current
 */
export function hasHighStartingCurrent(appliance) {
  return (appliance.startWatts - appliance.avgWatts) >= POWER_CONSTANTS.HIGH_INRUSH_THRESHOLD;
}

// Private helper functions
function calculateBasePower(appliances) {
  return appliances.reduce((acc, appliance) => {
    const quantity = appliance.quantity || 0;
    const continuousWatts = appliance.avgWatts * quantity;
    const dailyWattHours = continuousWatts * appliance.hoursPerDay;

    return {
      continuousWatts: acc.continuousWatts + continuousWatts,
      dailyWattHours: acc.dailyWattHours + dailyWattHours
    };
  }, { continuousWatts: 0, dailyWattHours: 0 });
}

function calculateInrushImpact(appliances) {
  const groups = groupAppliancesByInrush(appliances);
  const maxInrushWatts = calculateMaxInrushWatts(groups);
  const maxInrushAmps = maxInrushWatts / POWER_CONSTANTS.VOLTAGE;
  const highInrushCount = countHighInrushAppliances(appliances);

  return {
    peakWatts: maxInrushWatts,
    peakAmps: maxInrushAmps,
    hasHighInrushLoads: highInrushCount > 0,
    highInrushCount,
    groups
  };
}

function groupAppliancesByInrush(appliances) {
  const highInrush = [];
  const normal = [];

  appliances.forEach(app => {
    if (hasHighStartingCurrent(app)) {
      highInrush.push(app);
    } else {
      normal.push(app);
    }
  });

  return [highInrush, normal].filter(group => group.length > 0);
}

function calculateMaxInrushWatts(groups) {
  return groups.reduce((max, group) => {
    const groupInrush = group.reduce((sum, app) => {
      return sum + (app.startWatts * (app.quantity || 1));
    }, 0);
    return Math.max(max, groupInrush);
  }, 0);
}

function countHighInrushAppliances(appliances) {
  return appliances.filter(app => hasHighStartingCurrent(app)).length;
}

function combinePowerRequirements(base, inrush) {
  const effectiveWatts = Math.max(
    base.continuousWatts * POWER_CONSTANTS.SAFETY_FACTOR,
    inrush.peakWatts
  );

  return {
    continuousWatts: base.continuousWatts,
    peakWatts: inrush.peakWatts,
    effectiveAmps: effectiveWatts / POWER_CONSTANTS.VOLTAGE,
    dailyWattHours: base.dailyWattHours
  };
}