export const texas = {
  name: 'Texas',
  hasNetMetering: true,
  creditRate: 'Varies by Provider',
  aggregation: false,
  features: [
    'Retail electric provider programs',
    'Battery storage incentives',
    'Time-of-use options',
    'Solar buyback programs'
  ],
  notes: 'Texas has a deregulated electricity market where net metering policies vary by retail electric provider. Programs and rates can vary significantly between providers and regions.',
  utilities: [
    {
      name: 'Oncor',
      description: 'Serves Dallas-Fort Worth and surrounding areas',
      programUrl: 'https://www.oncor.com/content/oncorwww/us/en/home/about-us/solar.html',
      features: [
        'Solar interconnection',
        'Smart meter integration',
        'Battery storage support',
        'Grid modernization'
      ]
    },
    {
      name: 'CenterPoint Energy',
      description: 'Serves Greater Houston area',
      programUrl: 'https://www.centerpointenergy.com/en-us/residential/save-energy-money/electric-vehicles-solar',
      features: [
        'Solar interconnection',
        'Smart meter data',
        'Grid reliability programs',
        'Renewable integration'
      ]
    },
    {
      name: 'Austin Energy',
      description: 'Serves Austin metropolitan area',
      programUrl: 'https://austinenergy.com/ae/green-power/solar-solutions/for-your-home/value-of-solar-rate',
      features: [
        'Value of Solar tariff',
        'Battery rebates',
        'Community solar',
        'Green energy programs'
      ]
    },
    {
      name: 'CPS Energy',
      description: 'Serves San Antonio area',
      programUrl: 'https://www.cpsenergy.com/en/my-home/savenow/rebates-rebate/solar-photovoltaic-rebate.html',
      features: [
        'Solar rebate program',
        'Battery pilot program',
        'Time-of-use rates',
        'Grid reliability services'
      ]
    }
  ]
};