import React from 'react';
import SEO from '../../components/SEO';
import Container from '../../components/layout/Container';
import { 
  BoltIcon, 
  ShieldCheckIcon, 
  ChartBarIcon, 
  UserGroupIcon,
  CurrencyDollarIcon,
  BuildingOfficeIcon,
  ClockIcon,
  CloudArrowUpIcon,
  CircleStackIcon,
  CubeTransparentIcon,
  ServerIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import HubSpotForm from '../../components/forms/HubSpotForm';

const integrationFeatures = [
  {
    name: 'Last Mile Network',
    description: 'Complete integration with your service agreement workflow and field operations.',
    icon: ServerIcon,
    features: [
      'Service agreement automation',
      'Work order management',
      'Field team coordination',
      'Real-time status updates'
    ]
  },
  {
    name: 'System of Action',
    description: 'Real-time operational platform that connects all stakeholders.',
    icon: CircleStackIcon,
    features: [
      'Real-time data streams',
      'API-first architecture',
      'Custom integrations',
      'Automated workflows'
    ]
  },
  {
    name: 'Data Platform',
    description: 'Comprehensive data management and analytics platform.',
    icon: CubeTransparentIcon,
    features: [
      'Real-time monitoring',
      'Predictive analytics',
      'Custom reporting',
      'Data visualization'
    ]
  },
  {
    name: 'Field Operations',
    description: 'Streamlined field service and maintenance management.',
    icon: WrenchScrewdriverIcon,
    features: [
      'Service scheduling',
      'Mobile workforce tools',
      'Quality assurance',
      'Performance tracking'
    ]
  }
];

// Previous benefits and programs arrays remain the same...

export default function Utilities() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Utility Partnerships"
        description="Partner with Agilio to enhance your energy programs and customer engagement through innovative solutions."
        canonical="/partnerships/utilities"
      />
      
      {/* Previous hero section remains the same... */}

      {/* Integration Platform Section */}
      <div className="bg-gray-50 py-24">
        <Container>
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-green-600">Integration Platform</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Complete Last Mile Integration
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our platform provides comprehensive integration from grid to customer, including service agreements, 
              field operations, and real-time data management.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            {integrationFeatures.map((feature) => (
              <div key={feature.name} className="relative p-8 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200">
                <div className="flex items-center gap-x-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.name}</h3>
                </div>
                <p className="mt-4 text-gray-600">{feature.description}</p>
                <ul className="mt-8 space-y-3">
                  {feature.features.map((item) => (
                    <li key={item} className="flex items-center gap-x-3">
                      <div className="h-2 w-2 rounded-full bg-green-600" />
                      <span className="text-sm text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* API Integration Card */}
          <div className="mt-16 bg-green-50 rounded-2xl p-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                  Real-Time Data Integration
                </h3>
                <p className="text-gray-600 mb-6">
                  Our API-first platform enables seamless integration with your existing systems:
                </p>
                <ul className="space-y-4">
                  <li className="flex items-center gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm font-medium">
                      1
                    </div>
                    <span className="text-gray-600">
                      Real-time device telemetry and status updates
                    </span>
                  </li>
                  <li className="flex items-center gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm font-medium">
                      2
                    </div>
                    <span className="text-gray-600">
                      Automated service agreement workflow integration
                    </span>
                  </li>
                  <li className="flex items-center gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm font-medium">
                      3
                    </div>
                    <span className="text-gray-600">
                      Custom data streams and reporting capabilities
                    </span>
                  </li>
                  <li className="flex items-center gap-x-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 text-green-600 text-sm font-medium">
                      4
                    </div>
                    <span className="text-gray-600">
                      Secure, scalable infrastructure with 99.9% uptime
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <pre className="text-sm text-gray-600 overflow-x-auto">
                  <code>{`
// Example API Response
{
  "device_id": "sanctuary_123",
  "timestamp": "2024-01-20T15:30:00Z",
  "metrics": {
    "power_output": 7.2,
    "state_of_charge": 85,
    "grid_status": "connected",
    "response_time": 0.05
  },
  "service_status": {
    "agreement_id": "sa_456",
    "last_maintenance": "2024-01-15",
    "next_service": "2024-04-15"
  }
}
                  `}</code>
                </pre>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Previous sections remain the same... */}

      {/* Contact Form Section */}
      <Container className="py-24">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
              Start Your Partnership Journey
            </h2>
            <p className="text-lg text-gray-600">
              Connect with our utility partnerships team to explore how we can work together.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-sm ring-1 ring-gray-200 p-8">
            <HubSpotForm 
              formId="8418c7e7-337e-4dba-8d8c-88d2c6b7f6f4"
              portalId="42269009"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}