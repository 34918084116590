import { 
  BoltIcon, 
  WrenchScrewdriverIcon,
  SunIcon,
  BoltSlashIcon
} from '@heroicons/react/24/outline';

export const services = [
  { 
    id: 'battery', 
    label: 'Battery Storage',
    icon: BoltIcon
  },
  { 
    id: 'hvac-new', 
    label: 'New Heating/Cooling',
    icon: SunIcon
  },
  { 
    id: 'hvac-service', 
    label: 'Heating/Cooling Service',
    icon: WrenchScrewdriverIcon
  },
  { 
    id: 'ev-charger', 
    label: 'EV Charger Install',
    icon: BoltSlashIcon
  }
];

export const verticals = [
  {
    name: 'Energy Storage',
    description: 'Premium home battery systems for backup power and energy cost savings',
    icon: BoltIcon,
    features: [
      'Backup power protection',
      'Smart energy management',
      'Solar integration',
      'Time-of-use optimization'
    ]
  },
  {
    name: 'EV Charging',
    description: 'Level 2 home charging stations for all electric vehicle brands',
    icon: BoltSlashIcon,
    features: [
      'Fast home charging',
      'Smart scheduling',
      'Energy monitoring',
      'Utility rebate assistance'
    ]
  },
  {
    name: 'HVAC Service',
    description: 'Professional maintenance and repair for all HVAC systems',
    icon: WrenchScrewdriverIcon,
    features: [
      'Preventive maintenance',
      'Emergency repairs',
      'System optimization',
      'Indoor air quality'
    ]
  },
  {
    name: 'HVAC Installation',
    description: 'Expert installation of energy-efficient heating and cooling systems',
    icon: SunIcon,
    features: [
      'System design',
      'Energy efficiency analysis',
      'Professional installation',
      'Rebate coordination'
    ]
  }
];