import React from 'react';

export default function MegaMenuCard({ item }) {
  return (
    <div className="group relative flex flex-col rounded-2xl p-6 hover:bg-gray-50 transition-colors duration-200">
      <div className="flex items-center gap-x-4">
        <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-50 group-hover:bg-white ring-1 ring-green-100">
          <item.icon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">{item.name}</h3>
      </div>
      
      <p className="mt-3 text-sm text-gray-600">{item.description}</p>
      
      {item.features && (
        <ul className="mt-4 space-y-2">
          {item.features.map((feature) => (
            <li key={feature} className="flex items-center gap-x-2">
              <div className="h-1.5 w-1.5 rounded-full bg-green-600" />
              <span className="text-xs text-gray-500">{feature}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}