export const sanctuarySpecs = {
  name: 'Lion Energy Sanctuary',
  basicSpecs: [
    { label: 'Energy Capacity', value: '14.3 kWh' },
    { label: 'Continuous Power', value: '8 kW' },
    { label: 'Peak Power', value: '14 kW (10s)' },
    { label: 'Warranty', value: '10-25 Years' },
  ],
  detailedSpecs: {
    size: {
      dimensions: '74.25" x 18.5" x 10"',
      weight: '375 lbs',
    },
    performance: {
      inverterEfficiency: '96%',
      roundTripEfficiency: '94%',
      maxSolarInput: '12 kW',
    },
    installation: {
      features: 'Integrated inverter and system controller',
      temperature: '32°F to 86°F',
      protection: 'Water and dust resistant',
    },
    certification: [
      'UL9540',
      'UL9540-A',
      'UL1741-SA',
      'UL1741-SB',
      'UL1973 (LiFePO4)',
    ],
    warranty: {
      standard: '10 years',
      extended: '25 years (optional)',
    },
  },
};