import React from 'react';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import MarketChallenges from './Home/sections/MarketChallenges';
import AgilioSolution from './Home/sections/AgilioSolution';
import HomeEnergyShowcase from './Home/sections/HomeEnergyShowcase';
import ProcessSteps from './Home/sections/ProcessSteps';
import Benefits from './Home/sections/Benefits';
import Testimonials from './Home/sections/Testimonials';
import CTASection from './Home/sections/CTASection';

export default function Home() {
  // Homepage schema
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Agilio - Your Home Improvement Concierge",
    "description": "Transform your home with Agilio's concierge service. From energy storage to HVAC, we simplify home improvements with expert guidance and guaranteed satisfaction.",
    "provider": {
      "@type": "Organization",
      "name": "Agilio",
      "url": "https://agilio.pro"
    },
    "mainEntity": {
      "@type": "Service",
      "name": "Home Improvement Concierge Service",
      "description": "Expert guidance and project management for home improvements including energy storage, HVAC, and more."
    }
  };

  return (
    <main>
      <SEO 
        title="Home"
        description="Transform your home with Agilio's concierge service. From energy storage to HVAC, we simplify home improvements with expert guidance and guaranteed satisfaction."
        canonical="/"
        schema={homeSchema}
      />
      <Hero />
      <MarketChallenges />
      <AgilioSolution />
      <HomeEnergyShowcase />
      <ProcessSteps />
      <Benefits />
      <Testimonials />
      <CTASection />
    </main>
  );
}