import React from 'react';
import { WrenchScrewdriverIcon, ShieldCheckIcon, ClockIcon, SparklesIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Container from '../../components/layout/Container';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';

const features = [
  {
    name: 'Expert Installation',
    description: 'Professional installation by certified technicians who ensure your system is perfectly sized and optimized for your home.',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Quality Assurance',
    description: 'Every installation is backed by our comprehensive quality assurance process and satisfaction guarantee.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Fast Service',
    description: 'Quick response times and efficient installation process to minimize disruption to your daily life.',
    icon: ClockIcon,
  },
  {
    name: 'Smart Solutions',
    description: 'Modern comfort solutions with smart controls and energy-efficient operation for optimal performance.',
    icon: SparklesIcon,
  },
];

const benefits = [
  'Improved energy efficiency and lower utility bills',
  'Enhanced indoor air quality for better health',
  'Consistent temperature throughout your home',
  'Quiet operation and improved comfort',
  'Smart controls for convenient operation',
  'Extended equipment life through proper installation'
];

export default function HVAC() {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Home Comfort Solutions",
    "provider": {
      "@type": "Organization",
      "name": "Agilio"
    },
    "description": "Expert HVAC installation and service for optimal home comfort",
    "areaServed": "United States",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "HVAC Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "HVAC Installation"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "HVAC Maintenance"
          }
        }
      ]
    }
  };

  return (
    <div className="bg-white">
      <SEO 
        title="Home Comfort Solutions"
        description="Experience perfect home comfort with our expert HVAC solutions. From installation to maintenance, we deliver reliable heating and cooling that fits your lifestyle and budget."
        canonical="/hvac"
        schema={serviceSchema}
      />
      
      <PageHeader
        title="Your Perfect Home Climate"
        description="Experience the comfort you deserve with solutions designed for your home. We deliver efficient heating and cooling systems that fit your lifestyle and budget."
        backgroundImage="https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"
      />

      {/* Quick Action CTA */}
      <div className="bg-green-50 py-8">
        <Container>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <span className="text-lg font-semibold text-gray-900">
              Ready to improve your home comfort?
            </span>
            <a
              href="https://agilio.go-kf.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
            >
              Get Started Today
            </a>
          </div>
        </Container>
      </div>

      {/* Main Features Section */}
      <Container className="py-24">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Expert Solutions</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comfort Made Simple
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We take the complexity out of home comfort. Our expert team handles everything from system selection to installation and maintenance.
          </p>
          <div className="mt-10">
            <a
              href="https://agilio.go-kf.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
            >
              Get Started Today
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {features.map((feature) => (
            <div key={feature.name} className="relative p-8 bg-gray-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{feature.name}</h3>
              </div>
              <p className="mt-4 text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </Container>

      {/* Benefits Section */}
      <div className="bg-gray-50 py-24">
        <Container>
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-green-600">The Benefits</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Transform Your Home Comfort
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Experience the difference a properly designed and installed HVAC system can make in your daily life.
            </p>
          </div>

          <div className="mx-auto max-w-2xl">
            <div className="grid grid-cols-1 gap-4">
              {benefits.map((benefit) => (
                <div key={benefit} className="flex items-center gap-x-3 bg-white p-4 rounded-lg">
                  <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" />
                  <span className="text-base text-gray-900">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>

      {/* CTA Section */}
      <div className="bg-green-600 py-24">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready for Better Home Comfort?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Get started with our expert HVAC solutions today.
            </p>
            <div className="mt-10">
              <a
                href="https://agilio.go-kf.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50"
              >
                Get Started Today
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}