import React from 'react';
import { Link } from 'react-router-dom';

const variants = {
  primary: 'bg-green-600 text-white hover:bg-green-500',
  secondary: 'bg-white text-green-600 hover:bg-green-50',
  text: 'text-gray-900 hover:text-green-600',
};

export default function Button({ 
  href, 
  variant = 'primary', 
  className = '', 
  children,
  icon: Icon,
}) {
  const baseClasses = 'rounded-md px-3.5 py-2.5 text-sm font-semibold transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 inline-flex items-center gap-2';
  const variantClasses = variants[variant];

  // Check if the href is an external URL
  const isExternal = href.startsWith('http');
  
  if (isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${baseClasses} ${variantClasses} ${className}`}
      >
        {children}
        {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
      </a>
    );
  }
  
  return (
    <Link
      to={href}
      className={`${baseClasses} ${variantClasses} ${className}`}
    >
      {children}
      {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
    </Link>
  );
}