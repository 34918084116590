import React from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import { energySavingsArticle } from '../../utils/articles/energySavings';

export default function EnergySavings() {
  return (
    <>
      <SEO 
        title={energySavingsArticle.title}
        description={energySavingsArticle.description}
        canonical="/learning-center/articles/energy-savings"
      />
      <ArticleLayout article={energySavingsArticle}>
        {/* Call to Action */}
        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Ready to Start Saving?</h3>
          <p className="text-gray-600 mb-6">
            Talk to our experts about implementing smart energy management solutions. We'll help you:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>Analyze your current energy usage</li>
            <li>Identify savings opportunities</li>
            <li>Design a custom solution</li>
            <li>Maximize available incentives</li>
          </ul>
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
          >
            Get Expert Help
          </a>
        </div>
      </ArticleLayout>
    </>
  );
}