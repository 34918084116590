import React from 'react';
import { BoltIcon, CurrencyDollarIcon, ClockIcon, SunIcon } from '@heroicons/react/24/outline';

const benefits = [
  {
    title: 'Backup Power',
    description: 'Keep your home running during outages with instant, automatic power switchover',
    icon: BoltIcon
  },
  {
    title: 'Rate Optimization',
    description: 'Charge when electricity rates are low and use stored power during expensive peak hours',
    icon: CurrencyDollarIcon
  },
  {
    title: 'Future Ready',
    description: 'Add solar panels anytime - your battery system is already prepared for integration',
    icon: SunIcon
  },
  {
    title: 'Time-of-Use Savings',
    description: 'Smart scheduling automatically optimizes charging times to minimize your energy costs',
    icon: ClockIcon
  }
];

export default function BatteryWithoutSolar() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Do I Need Solar to Have a Battery?
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            No, you don't need solar panels to benefit from a home battery system. While solar integration 
            can maximize your savings, batteries provide significant value on their own through backup power 
            and smart energy management.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
          {benefits.map((benefit) => (
            <div key={benefit.title} className="relative">
              <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-green-50 opacity-50 sm:rounded-2xl lg:-inset-x-6 lg:rounded-2xl" />
              <div className="relative z-10 p-6">
                <div className="flex items-center gap-x-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <benefit.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {benefit.title}
                  </h3>
                </div>
                <p className="mt-4 text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-2xl bg-green-50 p-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            How It Works Without Solar
          </h3>
          <div className="space-y-6">
            <p className="text-gray-600">
              Your battery system charges from the grid during off-peak hours when electricity rates 
              are lowest. During peak rate periods or power outages, you can use this stored energy 
              to power your home, potentially saving money on your utility bills while ensuring 
              reliable backup power.
            </p>
            <p className="text-gray-600">
              If you decide to add solar panels later, your battery system is already prepared for 
              integration, making it a future-proof investment in your home's energy independence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}