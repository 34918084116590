import { formatPowerValue } from '../../calculators/powerCalculations';
import { formatDuration } from '../../calculators/timeCalculations';
import { sanctuarySpecs } from '../../productSpecs';

export function generateSolutionsPage(solutions) {
  return `
    <div style="margin-bottom: 40px;">
      <h2 style="color: #16A34A; font-size: 24px; margin-bottom: 20px;">
        Recommended Solutions
      </h2>
      
      ${Object.entries(solutions).map(([level, solution]) => `
        <div style="background: #F0FDF4; padding: 20px; border-radius: 8px; margin-bottom: 20px;">
          <h3 style="color: #16A34A; font-size: 18px; margin-bottom: 16px;">
            ${level.toUpperCase()}: ${formatDuration(solution.backupTime)} Backup
          </h3>
          
          <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;">
            <div>
              <div style="color: #6B7280; font-size: 14px; margin-bottom: 8px;">System Configuration</div>
              <ul style="list-style: none; padding: 0; margin: 0;">
                <li style="margin-bottom: 8px;">• ${solution.inverters} Inverter${solution.inverters > 1 ? 's' : ''}</li>
                <li style="margin-bottom: 8px;">• ${solution.batteries} Battery Module${solution.batteries > 1 ? 's' : ''}</li>
              </ul>
            </div>
            
            <div>
              <div style="color: #6B7280; font-size: 14px; margin-bottom: 8px;">Performance Metrics</div>
              <ul style="list-style: none; padding: 0; margin: 0;">
                <li style="margin-bottom: 8px;">• Total Power: ${formatPowerValue.kw(solution.totalPower)}</li>
                <li style="margin-bottom: 8px;">• Storage: ${formatPowerValue.kwh(solution.totalCapacity)}</li>
                <li style="margin-bottom: 8px;">• Efficiency: ${sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency}</li>
              </ul>
            </div>
          </div>
        </div>
      `).join('')}
    </div>
  `;
}