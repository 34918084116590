import React from 'react';
import { CurrencyDollarIcon, ChartBarIcon, ArrowTrendingDownIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import { sanctuarySpecs } from '../../utils/productSpecs';

const savingsCategories = [
  {
    name: 'Peak Rate Savings',
    description: 'Store energy during off-peak hours and use it during expensive peak periods',
    potential: '20-30%',
    icon: ChartBarIcon,
    details: [
      'Avoid high time-of-use rates',
      'Automated peak/off-peak switching',
      'Smart rate monitoring',
    ],
  },
  {
    name: 'Solar Optimization',
    description: 'Maximize your solar investment by storing excess energy for later use',
    potential: '40-60%',
    icon: ArrowTrendingDownIcon,
    details: [
      'Store excess solar production',
      'Use stored energy at night',
      'Reduce grid dependency',
    ],
  },
  {
    name: 'Demand Charge Reduction',
    description: 'Reduce or eliminate costly demand charges from your utility bill',
    potential: '15-25%',
    icon: CurrencyDollarIcon,
    details: [
      'Peak demand management',
      'Load balancing',
      'Smart consumption patterns',
    ],
  },
];

export default function CostBreakdown() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Cost Analysis</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Understanding Your Savings
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            The {sanctuarySpecs.name} can significantly reduce your energy costs through multiple saving mechanisms.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {savingsCategories.map((category) => (
            <div
              key={category.name}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <category.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{category.name}</h3>
              </div>
              
              <div className="mt-4 flex items-baseline gap-x-2">
                <span className="text-4xl font-bold text-green-600">{category.potential}</span>
                <span className="text-sm text-gray-500">potential savings</span>
              </div>
              
              <p className="mt-4 text-gray-600">{category.description}</p>
              
              <ul className="mt-6 space-y-3">
                {category.details.map((detail, index) => (
                  <li key={index} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 flex-none rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{detail}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-3xl bg-green-50 p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                Calculate Your Savings
              </h3>
              <p className="text-gray-600">
                Our energy experts can analyze your current utility bills and usage patterns to provide 
                a detailed savings projection. With {sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency} round-trip 
                efficiency and smart optimization, the savings can be substantial.
              </p>
            </div>
            <div className="bg-white rounded-2xl p-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-sm font-medium text-gray-500">System Efficiency</div>
                  <div className="text-2xl font-semibold text-green-600">
                    {sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Max Solar Input</div>
                  <div className="text-2xl font-semibold text-green-600">
                    {sanctuarySpecs.detailedSpecs.performance.maxSolarInput}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}