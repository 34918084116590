import { 
  UserGroupIcon, 
  BoltIcon, 
  BuildingOfficeIcon, 
  TruckIcon 
} from '@heroicons/react/24/outline';

export const partnerships = [
  {
    name: 'Local Professionals',
    description: 'Join our network of certified installers and service providers',
    href: '/partnerships/local-pros',
    icon: UserGroupIcon,
    features: [
      'Quality leads',
      'Comprehensive support',
      'Training resources',
      'Growth opportunities'
    ]
  },
  {
    name: 'Utilities',
    description: 'Partner with us to enhance your energy programs',
    href: '/partnerships/utilities',
    icon: BoltIcon,
    features: [
      'Grid integration',
      'Program support',
      'Customer engagement',
      'Data analytics'
    ]
  },
  {
    name: 'Manufacturers',
    description: 'Expand your market reach through our platform',
    href: '/partnerships/manufacturers',
    icon: BuildingOfficeIcon,
    features: [
      'Market expansion',
      'Distribution network',
      'Professional installation',
      'Performance tracking'
    ]
  },
  {
    name: 'Distributors',
    description: 'Optimize your distribution network and coverage',
    href: '/partnerships/distributors',
    icon: TruckIcon,
    features: [
      'Territory expansion',
      'Inventory management',
      'Logistics support',
      'Partner network'
    ]
  }
];