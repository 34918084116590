import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../ui/Tooltip';
import { formatPowerValue } from '../../../../utils/calculators/powerCalculations';
import { powerTooltips } from '../../../../utils/tooltips/powerTooltips';

export default function PowerSummary({ powerNeeds }) {
  return (
    <div className="bg-gray-50 rounded-lg p-4 mb-6">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <Tooltip content={powerTooltips.amps}>
            <div className="text-sm font-medium text-gray-500">Total Power</div>
            <div className="mt-1 text-lg font-semibold text-gray-900">
              {formatPowerValue.amps(powerNeeds.totalAmps)}
            </div>
          </Tooltip>
          {powerNeeds.hasHighStartingLoads && (
            <div className="text-xs text-yellow-600 flex items-center gap-x-1 mt-1">
              <ExclamationTriangleIcon className="h-3 w-3" />
              {powerNeeds.highStartingCount} appliance{powerNeeds.highStartingCount !== 1 ? 's' : ''} with high starting current
            </div>
          )}
        </div>
        <div>
          <Tooltip content={powerTooltips.watts}>
            <div className="text-sm font-medium text-gray-500">Peak Power</div>
            <div className="mt-1 text-lg font-semibold text-gray-900">
              {formatPowerValue.kw(powerNeeds.peakPowerKw)}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={powerTooltips.kwh}>
            <div className="text-sm font-medium text-gray-500">Daily Usage</div>
            <div className="mt-1 text-lg font-semibold text-gray-900">
              {formatPowerValue.kwh(powerNeeds.dailyKwh)}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}