import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, ClockIcon } from '@heroicons/react/24/outline';

export default function FeaturedArticle({ article }) {
  if (!article) return null;

  return (
    <div className="relative isolate overflow-hidden rounded-3xl bg-gray-900">
      <div className="absolute inset-0">
        <img
          src={article.image}
          alt={article.title}
          className="h-full w-full object-cover opacity-40"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40" />
      </div>
      
      <div className="relative px-6 py-24 sm:px-12 sm:py-32 lg:px-16">
        <div className="mx-auto max-w-2xl text-center">
          <div className="flex items-center justify-center gap-x-4 text-xs">
            <span className="inline-flex items-center rounded-full bg-white/10 px-3 py-1 text-xs font-medium text-white">
              {article.topic}
            </span>
            <span className="inline-flex items-center text-gray-300">
              <ClockIcon className="mr-1.5 h-4 w-4" />
              {article.readTime} min read
            </span>
          </div>
          
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {article.title}
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-300">
            {article.description}
          </p>
          
          <div className="mt-8">
            <Link
              to={`/learning-center/articles/${article.id}`}
              className="group inline-flex items-center gap-x-2 rounded-full bg-white/10 px-6 py-3 text-sm font-semibold text-white hover:bg-white/20"
            >
              Read full article
              <ArrowRightIcon className="h-4 w-4 transition-transform group-hover:translate-x-1" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}