import React from 'react';
import { BoltIcon, ExclamationTriangleIcon, ClockIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';

const stats = [
  {
    label: 'Average Outages',
    value: '6-12',
    unit: 'per year',
    icon: BoltIcon,
    description: 'Most homes experience multiple power outages annually'
  },
  {
    label: 'Average Duration',
    value: '2-8',
    unit: 'hours',
    icon: ClockIcon,
    description: 'Extended outages can last days during severe weather'
  },
  {
    label: 'Cost Impact',
    value: '$250-1,000',
    unit: 'per outage',
    icon: ExclamationTriangleIcon,
    description: 'Including food spoilage, lost productivity, and hotel stays'
  },
];

export default function OutageStats() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">The Impact</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Power Outages By The Numbers
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Power outages are becoming more frequent and costly. Protect your home and family with reliable backup power.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.label} className="relative overflow-hidden rounded-2xl bg-white shadow-sm ring-1 ring-gray-200 p-8">
              <div className="flex items-center gap-x-4 mb-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <stat.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold leading-8 text-gray-900">{stat.label}</h3>
              </div>
              <div className="flex items-baseline gap-x-2">
                <span className="text-4xl font-bold tracking-tight text-green-600">{stat.value}</span>
                <span className="text-sm text-gray-600">{stat.unit}</span>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{stat.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}