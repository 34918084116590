import React from 'react';
import { ArrowsRightLeftIcon, SunIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import { sanctuarySpecs } from '../../utils/productSpecs';

const benefits = [
  {
    name: 'Sell Back to Grid',
    description: 'Earn credits by selling excess stored energy back to your utility company during peak rate periods.',
    icon: BanknotesIcon,
    points: [
      'Maximize returns on solar investment',
      'Earn higher rates during peak hours',
      'Reduce or eliminate energy bills',
    ],
  },
  {
    name: 'Smart Grid Integration',
    description: 'Intelligent system automatically optimizes between self-consumption, storage, and grid export.',
    icon: ArrowsRightLeftIcon,
    points: [
      'Automated energy management',
      'Real-time rate optimization',
      'Seamless grid integration',
    ],
  },
  {
    name: 'Solar Optimization',
    description: `Store excess solar production with ${sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency} round-trip efficiency for maximum value.`,
    icon: SunIcon,
    points: [
      'Capture all solar production',
      'Use stored energy at night',
      'Maximize self-consumption',
    ],
  },
];

export default function NetMeteringBenefits() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Grid Independence</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Net Metering & Grid Integration
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Transform your home into a smart energy hub. Store excess solar production, participate in net metering, 
            and maximize your energy independence.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {benefits.map((benefit) => (
            <div
              key={benefit.name}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <div className="flex items-center gap-x-4 mb-6">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{benefit.name}</h3>
              </div>
              
              <p className="text-gray-600 mb-6">{benefit.description}</p>
              
              <ul className="space-y-3">
                {benefit.points.map((point, index) => (
                  <li key={index} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 flex-none rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-3xl bg-green-50 p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                Maximize Your Energy Value
              </h3>
              <p className="text-gray-600">
                With {sanctuarySpecs.detailedSpecs.performance.maxSolarInput} maximum solar input and 
                intelligent grid integration, the Sanctuary system helps you get the most value from your 
                energy production and storage.
              </p>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1613665813446-82a78c468a1d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80"
                alt="Smart home energy monitoring dashboard"
                className="rounded-2xl shadow-lg"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}