import { formatDate } from '../utils/formatters';

export function generateCoverPage() {
  return `
    <div style="text-align: center; margin-bottom: 60px; page-break-after: always;">
      <img 
        src="https://cdn.prod.website-files.com/67091f695cd8e90971357ea4/671b89182e8f151846ae4e4e_Agilio%20Logo-1.webp"
        alt="Agilio"
        style="max-width: 200px; margin-bottom: 40px;"
      />
      <h1 style="color: #16A34A; font-size: 32px; margin-bottom: 16px;">
        Energy System Recommendation
      </h1>
      <p style="color: #6B7280; font-size: 18px;">
        Generated on ${formatDate(new Date())}
      </p>
    </div>
  `;
}