import React from 'react';
import Container from '../layout/Container';
import { verticals } from '../../utils/serviceData';

export default function ServiceVerticals() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Our Services</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comprehensive Home Solutions
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            From energy storage to climate control, we provide expert solutions for every aspect of your home.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {verticals.map((vertical) => (
            <div
              key={vertical.name}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200 hover:shadow-lg transition-shadow duration-300"
            >
              <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600 mb-6">
                <vertical.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              
              <h3 className="text-lg font-semibold text-gray-900 mb-3">{vertical.name}</h3>
              <p className="text-sm text-gray-600 mb-6">{vertical.description}</p>
              
              <ul className="space-y-3">
                {vertical.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-x-3">
                    <div className="h-1.5 w-1.5 flex-none rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}