export const documentationData = [
  {
    id: 'product-manuals',
    title: 'Product Manuals',
    description: 'Comprehensive user guides and operation manuals for all Agilio products.',
    documents: [
      {
        title: 'Lion Energy Sanctuary User Manual',
        description: 'Complete guide to installation, operation, and maintenance',
        url: '/docs/sanctuary-user-manual.pdf',
        type: 'pdf'
      },
      {
        title: 'Sanctuary Quick Start Guide',
        description: 'Essential setup and configuration steps',
        url: '/docs/sanctuary-quick-start.pdf',
        type: 'pdf'
      },
      {
        title: 'Mobile App User Guide',
        description: 'Instructions for using the Sanctuary mobile app',
        url: '/docs/sanctuary-app-guide.pdf',
        type: 'pdf'
      }
    ]
  },
  {
    id: 'installation',
    title: 'Installation Guides',
    description: 'Detailed installation instructions and requirements for professionals.',
    documents: [
      {
        title: 'Professional Installation Manual',
        description: 'Complete installation procedures and specifications',
        url: '/docs/sanctuary-installation.pdf',
        type: 'pdf'
      },
      {
        title: 'Site Preparation Guide',
        description: 'Requirements and checklist for installation site',
        url: '/docs/site-prep-guide.pdf',
        type: 'pdf'
      },
      {
        title: 'Electrical Integration Guide',
        description: 'Wiring diagrams and electrical specifications',
        url: '/docs/electrical-integration.pdf',
        type: 'pdf'
      }
    ]
  },
  {
    id: 'technical-specs',
    title: 'Technical Specifications',
    description: 'Detailed technical specifications and performance data.',
    documents: [
      {
        title: 'Sanctuary Technical Datasheet',
        description: 'Complete technical specifications and performance data',
        url: '/docs/sanctuary-datasheet.pdf',
        type: 'pdf'
      },
      {
        title: 'Battery Chemistry White Paper',
        description: 'Detailed analysis of LiFePO4 technology',
        url: '/docs/battery-chemistry.pdf',
        type: 'pdf'
      },
      {
        title: 'Performance Test Results',
        description: 'Third-party verification of system performance',
        url: '/docs/performance-results.pdf',
        type: 'pdf'
      }
    ]
  },
  {
    id: 'compliance',
    title: 'Compliance & Certifications',
    description: 'Safety certifications and compliance documentation.',
    documents: [
      {
        title: 'UL Certification Documents',
        description: 'UL 9540 and UL 1741 certification details',
        url: '/docs/ul-certifications.pdf',
        type: 'pdf'
      },
      {
        title: 'Safety Data Sheet',
        description: 'Material safety data and handling instructions',
        url: '/docs/safety-data-sheet.pdf',
        type: 'pdf'
      },
      {
        title: 'Warranty Documentation',
        description: 'Detailed warranty terms and conditions',
        url: '/docs/warranty-terms.pdf',
        type: 'pdf'
      }
    ]
  }
];