import React from 'react';
import { ArrowsRightLeftIcon, SunIcon, BanknotesIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import { sanctuarySpecs } from '../../utils/productSpecs';

const gridFeatures = [
  {
    name: 'Net Metering Benefits',
    description: 'Maximize your return on investment by selling excess energy back to the grid during peak rate periods.',
    icon: BanknotesIcon,
    details: [
      'Earn credits from your utility company',
      'Offset your energy costs',
      'Potential for negative electricity bills',
      'Higher returns during peak periods',
    ],
  },
  {
    name: 'Solar Integration',
    description: `Store excess solar production with ${sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency} efficiency and use it when you need it most.`,
    icon: SunIcon,
    details: [
      'Maximize solar self-consumption',
      'Reduce reliance on grid power',
      'Optimize solar ROI',
      'Smart solar charging',
    ],
  },
  {
    name: 'Grid Independence',
    description: 'Achieve greater energy independence while maintaining the security of grid connection.',
    icon: ArrowsRightLeftIcon,
    details: [
      'Reduce grid dependency',
      'Backup power security',
      'Smart grid integration',
      'Flexible power management',
    ],
  },
  {
    name: 'Rate Optimization',
    description: 'Intelligent software automatically optimizes between self-consumption, storage, and grid export.',
    icon: ChartBarIcon,
    details: [
      'Time-of-use optimization',
      'Peak/off-peak arbitrage',
      'Demand charge reduction',
      'Real-time rate monitoring',
    ],
  },
];

export default function GridIntegration() {
  return (
    <div className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Grid Integration</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Smart Grid Integration & Net Metering
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Transform your home into an intelligent energy hub that optimizes between self-consumption, 
            storage, and grid interaction for maximum savings.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {gridFeatures.map((feature) => (
            <div
              key={feature.name}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <div className="flex items-center gap-x-4 mb-6">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{feature.name}</h3>
              </div>
              
              <p className="text-gray-600 mb-6">{feature.description}</p>
              
              <ul className="space-y-3">
                {feature.details.map((detail, index) => (
                  <li key={index} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 flex-none rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{detail}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-3xl bg-green-50 p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                How Net Metering Works
              </h3>
              <p className="text-gray-600 mb-6">
                With net metering, your home becomes a mini power plant. During peak solar production or 
                off-peak hours, store excess energy in your Sanctuary system. Then, either use this stored 
                energy during peak rate periods or sell it back to the grid when prices are highest.
              </p>
              <div className="space-y-4">
                <div className="flex items-center gap-x-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-100 text-green-600">
                    1
                  </div>
                  <span className="text-sm text-gray-600">
                    Store energy during low-rate periods or from solar production
                  </span>
                </div>
                <div className="flex items-center gap-x-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-100 text-green-600">
                    2
                  </div>
                  <span className="text-sm text-gray-600">
                    Use stored energy during high-rate periods
                  </span>
                </div>
                <div className="flex items-center gap-x-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-100 text-green-600">
                    3
                  </div>
                  <span className="text-sm text-gray-600">
                    Sell excess energy back to grid at premium rates
                  </span>
                </div>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1592833159155-c62df1b65634?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80"
                alt="Smart energy management dashboard"
                className="rounded-2xl shadow-lg"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}