import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from './layout/Container';
import CalculatorButton from './ui/CalculatorButton';

export default function PageHeader({ title, description, showCalculator = false, backgroundImage }) {
  const location = useLocation();
  
  const getBackgroundImage = () => {
    if (backgroundImage) return backgroundImage;
    
    switch (location.pathname) {
      case '/outage':
        return "https://images.unsplash.com/photo-1530508777238-14544088c3ed?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"; // Night time home image
      case '/saveonenergy':
        return "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"; // Daytime home image
      case '/hvac':
        return "https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"; // Hot summer day
      default:
        return "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80";
    }
  };

  return (
    <div className="relative isolate overflow-hidden pt-24">
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-gray-900/60" />
        <img
          src={getBackgroundImage()}
          alt="Modern home exterior"
          className="h-full w-full object-cover"
        />
      </div>

      <Container className="py-24 sm:py-32">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            {title}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {description}
          </p>
          {showCalculator && (
            <div className="mt-10 flex items-center justify-center">
              <CalculatorButton />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}