import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GA_TRACKING_ID } from '../../utils/analytics/config';
import { initializeGtag, pageview } from '../../utils/analytics/gtag';

export default function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    
    script.onload = () => {
      initializeGtag();
    };

    // Error handling
    script.onerror = () => {
      console.warn('Failed to load Google Analytics');
    };

    // Add script to document
    document.head.appendChild(script);

    // Cleanup
    return () => {
      const existingScript = document.querySelector(`script[src*="googletagmanager.com/gtag"]`);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  useEffect(() => {
    // Track page views
    pageview(location.pathname + location.search);
  }, [location]);

  return null;
}