import { SunIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { sanctuarySpecs } from '../productSpecs';

export const products = [
  {
    name: sanctuarySpecs.name,
    description: 'Premium home battery system with advanced energy management',
    href: '/products/sanctuary',
    icon: SunIcon,
    features: [
      `${sanctuarySpecs.basicSpecs[0].value} energy capacity`,
      `${sanctuarySpecs.basicSpecs[1].value} continuous power`,
      `${sanctuarySpecs.detailedSpecs.warranty.standard} warranty`,
    ]
  },
  {
    name: 'Smart Energy Solutions',
    description: 'Comprehensive home energy management systems',
    href: '/smart-energy-solutions',
    icon: ChartBarIcon,
    features: [
      'Intelligent monitoring',
      'Grid integration',
      'Mobile app control',
    ]
  }
];