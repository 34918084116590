import React from 'react';
import SEO from '../../components/SEO';
import TimeOfUseOptimization from '../../components/sections/TimeOfUseOptimization';
import GridIntegration from '../../components/sections/GridIntegration';
import NetMeteringBenefits from '../../components/sections/NetMeteringBenefits';
import CostBreakdown from '../../components/sections/CostBreakdown';
import SanctuarySolution from '../../components/sections/SanctuarySolution';
import TaxCredit from '../../components/sections/TaxCredit';
import CTASection from '../../components/CTASection';
import Container from '../../components/layout/Container';
import HubSpotForm from '../../components/forms/HubSpotForm';
import HeroCalculatorButton from '../../components/ui/HeroCalculatorButton';

export default function EnergySavings() {
  return (
    <div className="bg-white">
      <SEO 
        title="Energy Cost Savings"
        description="Reduce your utility bills with smart energy management and solar integration. Store power when it's cheap, use it when rates are high."
        canonical="/saveonenergy"
      />
      
      <div className="relative isolate overflow-hidden pt-24">
        <div className="absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-gray-900/60" />
          <img
            src="https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"
            alt="Modern home with solar panels"
            className="h-full w-full object-cover"
          />
        </div>

        <Container className="relative z-10 py-24 sm:py-32">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="mb-8 inline-flex items-center rounded-full bg-green-600/10 px-3 py-1 text-sm leading-6 text-green-200 ring-1 ring-inset ring-green-600/20">
                Maximize your energy savings
              </div>
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Transform Your Home Into a Smart Energy Hub
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Pair our battery systems with solar to maximize savings. Store energy when it's abundant, use it when rates are high, and even sell excess back to the grid.
              </p>
              <div className="mt-10 flex gap-x-4">
                <a
                  href="https://agilio.go-kf.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                >
                  Start Saving
                </a>
                <HeroCalculatorButton />
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-8">
                <HubSpotForm formId="9140a911-7fe8-4b69-9673-a6a2feea10fe" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      
      <TimeOfUseOptimization />
      <GridIntegration />
      <NetMeteringBenefits />
      <CostBreakdown />
      <SanctuarySolution />
      <TaxCredit />
      <CTASection />
    </div>
  );
}