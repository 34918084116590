import React, { useState } from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import BatteryCalculator from '../../components/calculator/BatteryCalculator';
import { batterySizingArticle } from '../../utils/articles/batterySizing';

export default function BatterySizing() {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  return (
    <>
      <SEO 
        title={batterySizingArticle.title}
        description={batterySizingArticle.description}
        canonical="/learning-center/articles/battery-sizing"
      />
      <ArticleLayout article={batterySizingArticle}>
        {/* Calculator CTA */}
        <div className="my-16 bg-green-50 rounded-xl p-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Size Your Battery System
          </h3>
          <p className="text-gray-600 mb-6">
            Use our interactive calculator to determine the right battery size for your home. Simply enter your appliances and power needs to get a personalized recommendation.
          </p>
          <button
            onClick={() => setIsCalculatorOpen(true)}
            className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
          >
            Open Battery Calculator
          </button>
        </div>

        {/* Next Steps */}
        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Ready to Get Started?</h3>
          <p className="text-gray-600 mb-6">
            Talk to our experts about finding the right battery solution for your home. We'll help you:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>Analyze your power needs</li>
            <li>Design a custom solution</li>
            <li>Maximize available incentives</li>
            <li>Plan for future expansion</li>
          </ul>
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
          >
            Get Expert Help
          </a>
        </div>

        {/* Calculator Modal */}
        <BatteryCalculator 
          isOpen={isCalculatorOpen}
          onClose={() => setIsCalculatorOpen(false)}
        />
      </ArticleLayout>
    </>
  );
}