import React from 'react';
import { 
  ShieldCheckIcon, 
  SparklesIcon, 
  UserGroupIcon, 
  HeartIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import Container from '../../../components/layout/Container';

const benefits = [
  {
    title: 'Expert Guidance',
    description: 'Our experienced team helps you navigate complex decisions with confidence.',
    icon: SparklesIcon,
    traditional: 'Hours of research and uncertainty',
    agilio: 'Personalized expert recommendations'
  },
  {
    title: 'Quality Assured',
    description: 'We work only with vetted professionals and premium products.',
    icon: ShieldCheckIcon,
    traditional: 'Unknown contractor quality',
    agilio: 'Pre-vetted, certified professionals'
  },
  {
    title: 'Stress-Free Process',
    description: 'One point of contact manages everything from start to finish.',
    icon: ClockIcon,
    traditional: 'Managing multiple contractors',
    agilio: 'Single point of contact for everything'
  },
  {
    title: 'Cost Transparency',
    description: 'Clear, upfront pricing with no hidden fees or surprises.',
    icon: CurrencyDollarIcon,
    traditional: 'Unexpected costs and overruns',
    agilio: 'Transparent, guaranteed pricing'
  }
];

export default function Benefits() {
  return (
    <div id="benefits" className="py-24 bg-white">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Why Choose Agilio</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The Agilio Difference
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience home improvement the way it should be - simple, professional, and stress-free.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {benefits.map((benefit) => (
            <div 
              key={benefit.title}
              className="relative p-8 bg-green-50 rounded-2xl"
            >
              <div className="flex items-center gap-x-4 mb-6">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{benefit.title}</h3>
              </div>
              <p className="text-gray-600 mb-6">{benefit.description}</p>
              <div className="space-y-4">
                <div className="flex items-start gap-x-3">
                  <ExclamationTriangleIcon className="h-6 w-6 flex-none mt-0.5 text-gray-400" />
                  <span className="text-sm text-gray-600">Traditional: {benefit.traditional}</span>
                </div>
                <div className="flex items-start gap-x-3">
                  <CheckCircleIcon className="h-6 w-6 flex-none mt-0.5 text-green-600" />
                  <span className="text-sm text-gray-900">Agilio: {benefit.agilio}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}