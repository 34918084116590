import React from 'react';
import { HomeIcon, ShieldCheckIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const locations = [
  {
    name: 'Garage',
    recommended: true,
    description: 'Most common and ideal location for battery installation',
    benefits: [
      'Temperature controlled environment',
      'Easy access for maintenance',
      'Protected from weather',
      'Close to electrical panel'
    ]
  },
  {
    name: 'Basement',
    recommended: true,
    description: 'Excellent alternative to garage installation',
    benefits: [
      'Stable temperature year-round',
      'Protected indoor location',
      'Often near electrical equipment',
      'Space-efficient solution'
    ]
  },
  {
    name: 'Utility Room',
    recommended: true,
    description: 'Good option when garage or basement isn\'t available',
    benefits: [
      'Indoor protection',
      'Usually near electrical panel',
      'Climate controlled space',
      'Convenient access'
    ]
  },
  {
    name: 'Outdoor',
    recommended: false,
    description: 'Possible with proper protection and considerations',
    requirements: [
      'Weather-protected enclosure',
      'Proper ventilation',
      'Temperature management',
      'Security measures'
    ]
  }
];

const requirements = [
  'Near main electrical panel',
  'Protected from extreme temperatures',
  'Good ventilation',
  'Easy maintenance access',
  'Secure location',
  'Level mounting surface'
];

export default function BatteryInstallationLocations() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Where Can I Install a Battery?
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Home battery systems can be installed in several locations, but some spots are better than 
            others. Here's a guide to help you understand the best places for installation.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          {locations.map((location) => (
            <div key={location.name} className="relative">
              <div className={`absolute -inset-x-4 -inset-y-6 z-0 scale-95 ${
                location.recommended ? 'bg-green-50' : 'bg-gray-50'
              } opacity-50 sm:rounded-2xl lg:-inset-x-6 lg:rounded-2xl`} />
              <div className="relative z-10 p-6">
                <div className="flex items-center gap-x-4">
                  <div className={`flex h-10 w-10 items-center justify-center rounded-lg ${
                    location.recommended ? 'bg-green-600' : 'bg-gray-600'
                  }`}>
                    <HomeIcon className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      {location.name}
                    </h3>
                    {location.recommended ? (
                      <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                        Recommended
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                        Possible with conditions
                      </span>
                    )}
                  </div>
                </div>
                <p className="mt-4 text-gray-600">{location.description}</p>
                <ul className="mt-6 space-y-3">
                  {(location.benefits || location.requirements).map((item) => (
                    <li key={item} className="flex items-start gap-x-3">
                      {location.recommended ? (
                        <CheckCircleIcon className="h-5 w-5 flex-none mt-0.5 text-green-600" />
                      ) : (
                        <ExclamationTriangleIcon className="h-5 w-5 flex-none mt-0.5 text-gray-400" />
                      )}
                      <span className="text-sm text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-2xl bg-green-50 p-8">
          <div className="flex items-center gap-x-3 mb-6">
            <ShieldCheckIcon className="h-6 w-6 text-green-600" />
            <h3 className="text-xl font-semibold text-gray-900">
              General Installation Requirements
            </h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {requirements.map((req) => (
              <div key={req} className="flex items-center gap-x-3 bg-white p-4 rounded-lg">
                <div className="h-2 w-2 flex-none rounded-full bg-green-600" />
                <span className="text-gray-600">{req}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}