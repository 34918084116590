import React from 'react';
import { BoltIcon, BeakerIcon, SunIcon, BanknotesIcon, ClockIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

const comparisonPoints = [
  {
    category: 'Power Source',
    battery: {
      title: 'Clean Electric Power',
      description: 'Stores energy from grid or solar for silent, emission-free operation',
      icon: BoltIcon
    },
    generator: {
      title: 'Fossil Fuel',
      description: 'Runs on gasoline, propane, or natural gas with emissions',
      icon: BeakerIcon
    }
  },
  {
    category: 'Energy Independence',
    battery: {
      title: 'Grid + Solar Integration',
      description: 'Can store solar power and participate in grid services',
      icon: SunIcon
    },
    generator: {
      title: 'Fuel Dependent',
      description: 'Requires constant fuel supply and storage',
      icon: BanknotesIcon
    }
  },
  {
    category: 'Response Time',
    battery: {
      title: 'Instant Switchover',
      description: 'Automatic, seamless power transition in milliseconds',
      icon: ClockIcon
    },
    generator: {
      title: 'Delayed Start',
      description: '10-30 second startup time with manual intervention',
      icon: ClockIcon
    }
  },
  {
    category: 'Maintenance',
    battery: {
      title: 'Minimal Maintenance',
      description: 'No regular maintenance required, software updates only',
      icon: WrenchScrewdriverIcon
    },
    generator: {
      title: 'Regular Maintenance',
      description: 'Requires oil changes, filter replacements, and testing',
      icon: WrenchScrewdriverIcon
    }
  }
];

export default function PowerSourceComparison() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Battery Storage vs. Generators
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Understanding the key differences between modern battery systems and traditional generators
            for backup power.
          </p>
        </div>

        <div className="space-y-8">
          {comparisonPoints.map((point) => (
            <div key={point.category} className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="relative">
                <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-green-50 opacity-50 sm:rounded-2xl lg:-inset-x-6 lg:rounded-2xl" />
                <div className="relative z-10 p-6">
                  <div className="flex items-center gap-x-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                      <point.battery.icon className="h-6 w-6 text-white" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      Battery: {point.battery.title}
                    </h3>
                  </div>
                  <p className="mt-4 text-gray-600">{point.battery.description}</p>
                </div>
              </div>

              <div className="relative">
                <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-gray-50 opacity-50 sm:rounded-2xl lg:-inset-x-6 lg:rounded-2xl" />
                <div className="relative z-10 p-6">
                  <div className="flex items-center gap-x-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-600">
                      <point.generator.icon className="h-6 w-6 text-white" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      Generator: {point.generator.title}
                    </h3>
                  </div>
                  <p className="mt-4 text-gray-600">{point.generator.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-2xl bg-green-50 p-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Why Choose Battery Storage?
          </h3>
          <ul className="space-y-4">
            <li className="flex items-start gap-x-3">
              <div className="h-2 w-2 flex-none rounded-full bg-green-600 mt-2" />
              <span className="text-gray-600">
                Silent operation with no emissions or fuel storage required
              </span>
            </li>
            <li className="flex items-start gap-x-3">
              <div className="h-2 w-2 flex-none rounded-full bg-green-600 mt-2" />
              <span className="text-gray-600">
                Instant, automatic power switchover protects sensitive electronics
              </span>
            </li>
            <li className="flex items-start gap-x-3">
              <div className="h-2 w-2 flex-none rounded-full bg-green-600 mt-2" />
              <span className="text-gray-600">
                Smart features for monitoring and control from your phone
              </span>
            </li>
            <li className="flex items-start gap-x-3">
              <div className="h-2 w-2 flex-none rounded-full bg-green-600 mt-2" />
              <span className="text-gray-600">
                Potential for utility bill savings through time-of-use optimization
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}