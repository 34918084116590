import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export default function UtilityList({ utilities }) {
  return (
    <div>
      <h4 className="text-lg font-semibold text-gray-900 mb-4">
        Participating Utilities
      </h4>
      
      <div className="grid grid-cols-1 gap-4">
        {utilities.map((utility) => (
          <div 
            key={utility.name}
            className="bg-white rounded-lg border border-gray-200 p-6 hover:border-green-200 transition-colors duration-200"
          >
            <div className="flex items-start justify-between">
              <div>
                <h5 className="text-base font-semibold text-gray-900">
                  {utility.name}
                </h5>
                <p className="mt-1 text-sm text-gray-600">
                  {utility.description}
                </p>
                <ul className="mt-4 space-y-2">
                  {utility.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-x-2 text-sm text-gray-600">
                      <div className="h-1.5 w-1.5 rounded-full bg-green-600" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              
              {utility.programUrl && (
                <a
                  href={utility.programUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-x-1 text-sm font-medium text-green-600 hover:text-green-500"
                >
                  Program Details
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}