import { validateFormData } from './validation';
import { formatFormData } from './formatting';
import { getMetadata } from './metadata';

const WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/16982579/2su2do6/';

export async function submitForm(data) {
  try {
    // Log initial data
    console.log('Initial form data:', data);

    // Validate the data
    validateFormData(data);

    // Format the form data
    const formattedData = formatFormData(data);
    console.log('Formatted data:', formattedData);

    // Get metadata
    const metadata = getMetadata();
    console.log('Metadata:', metadata);

    // Prepare the final payload
    const payload = {
      type: 'lead',
      formData: {
        firstName: formattedData.firstName,
        lastName: formattedData.lastName,
        email: formattedData.email,
        phone: formattedData.phone,
        services: formattedData.services
      },
      metadata: {
        source: metadata.source,
        timestamp: metadata.timestamp,
        userAgent: metadata.userAgent,
        referrer: metadata.referrer,
        pageUrl: metadata.pageUrl,
        utmSource: metadata.utmSource,
        utmMedium: metadata.utmMedium,
        utmCampaign: metadata.utmCampaign,
        utmTerm: metadata.utmTerm,
        utmContent: metadata.utmContent
      }
    };

    console.log('Final payload being sent to Zapier:', payload);

    // Send the request
    const response = await fetch(WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    console.log('Zapier response status:', response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response from Zapier:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json().catch(() => ({ success: true }));
    console.log('Successful response from Zapier:', responseData);

    return responseData;
  } catch (error) {
    console.error('Form submission error:', error);
    throw error;
  }
}