import React from 'react';
import SEO from '../../components/SEO';
import Container from '../../components/layout/Container';
import { 
  BuildingOfficeIcon, 
  TruckIcon, 
  UserGroupIcon, 
  ChartBarIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
  PresentationChartLineIcon,
  CubeTransparentIcon
} from '@heroicons/react/24/outline';
import HubSpotForm from '../../components/forms/HubSpotForm';

const benefits = [
  {
    name: 'Market Expansion',
    description: 'Rapidly expand your market reach through our established network.',
    icon: GlobeAltIcon,
    features: [
      'Access to qualified customers',
      'Geographic expansion',
      'Market penetration',
      'Brand visibility'
    ]
  },
  {
    name: 'Distribution Network',
    description: 'Leverage our optimized distribution and logistics network.',
    icon: TruckIcon,
    features: [
      'Nationwide coverage',
      'Inventory management',
      'Logistics optimization',
      'Quality control'
    ]
  },
  {
    name: 'Professional Network',
    description: 'Access to certified installation and service professionals.',
    icon: UserGroupIcon,
    features: [
      'Certified installers',
      'Training programs',
      'Technical support',
      'Quality assurance'
    ]
  },
  {
    name: 'Performance Analytics',
    description: 'Comprehensive insights and performance tracking.',
    icon: ChartBarIcon,
    features: [
      'Sales analytics',
      'Market insights',
      'Customer feedback',
      'Performance metrics'
    ]
  }
];

const features = [
  {
    name: 'Quality Assurance',
    description: 'Maintain product quality through professional installation and service.',
    icon: ShieldCheckIcon,
    details: [
      'Professional installation standards',
      'Certified technician network',
      'Regular quality audits',
      'Performance monitoring'
    ]
  },
  {
    name: 'Market Intelligence',
    description: 'Access detailed market insights and customer behavior data.',
    icon: PresentationChartLineIcon,
    details: [
      'Regional market analysis',
      'Customer preferences',
      'Competitive insights',
      'Trend forecasting'
    ]
  },
  {
    name: 'Product Integration',
    description: 'Seamless integration with our platform and ecosystem.',
    icon: CubeTransparentIcon,
    details: [
      'API integration',
      'Real-time monitoring',
      'Performance tracking',
      'Remote diagnostics'
    ]
  },
  {
    name: 'Brand Growth',
    description: 'Enhance your brand presence and market position.',
    icon: BuildingOfficeIcon,
    details: [
      'Brand visibility',
      'Market positioning',
      'Customer trust',
      'Industry leadership'
    ]
  }
];

export default function Manufacturers() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Manufacturer Partnerships"
        description="Partner with Agilio to expand your market reach and enhance product distribution through our established network."
        canonical="/partnerships/manufacturers"
      />
      
      {/* Hero Section */}
      <div className="relative isolate overflow-hidden bg-green-600 py-24">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Manufacturer Partnerships
            </h1>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Join forces with Agilio to expand your market reach and enhance product distribution through our established network of professionals and homeowners.
            </p>
          </div>
        </Container>
      </div>

      {/* Benefits Section */}
      <Container className="py-24">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Partnership Benefits</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Grow Your Business with Agilio
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Access new markets, enhance distribution, and leverage our professional network to expand your business.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {benefits.map((benefit) => (
            <div key={benefit.name} className="relative p-8 bg-gray-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{benefit.name}</h3>
              </div>
              <p className="mt-4 text-gray-600">{benefit.description}</p>
              <ul className="mt-8 space-y-3">
                {benefit.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>

      {/* Features Section */}
      <div className="bg-gray-50 py-24">
        <Container>
          <div className="mx-auto max-w-2xl lg:text-center mb-16">
            <h2 className="text-base font-semibold leading-7 text-green-600">Platform Features</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything You Need to Succeed
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our comprehensive platform provides the tools and insights you need to grow your business.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="relative p-8 bg-white rounded-2xl shadow-sm ring-1 ring-gray-200">
                <div className="flex items-center gap-x-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.name}</h3>
                </div>
                <p className="mt-4 text-gray-600">{feature.description}</p>
                <ul className="mt-8 space-y-3">
                  {feature.details.map((detail) => (
                    <li key={detail} className="flex items-center gap-x-3">
                      <div className="h-2 w-2 rounded-full bg-green-600" />
                      <span className="text-sm text-gray-600">{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </div>

      {/* Contact Form Section */}
      <Container className="py-24">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
              Start Your Partnership Journey
            </h2>
            <p className="text-lg text-gray-600">
              Connect with our manufacturer partnerships team to explore collaboration opportunities.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-sm ring-1 ring-gray-200 p-8">
            <HubSpotForm 
              formId="5557d877-ee0a-4230-b319-e4646f79ad59"
              portalId="42269009"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}