import React, { useState } from 'react';
import { generateProposal } from '../../../../utils/pdf/generator';

export default function ActionButtons({ onBack, onComplete, data, powerNeeds, solutions }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    if (!data || !powerNeeds || !solutions) {
      setError('Missing required data for PDF generation');
      return;
    }

    try {
      setIsGenerating(true);
      setError(null);
      await generateProposal(data, powerNeeds, solutions);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      setError('Failed to generate PDF. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}
      
      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Back
        </button>
        <div className="flex gap-x-3">
          <button
            type="button"
            onClick={handleDownload}
            disabled={isGenerating}
            className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isGenerating ? 'Generating PDF...' : 'Download Proposal'}
          </button>
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
            onClick={onComplete}
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
}