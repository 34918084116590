import React from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../ui/Tooltip';
import { powerTooltips } from '../../../utils/tooltips/powerTooltips';
import { sanctuarySpecs } from '../../../utils/productSpecs';

export default function InverterDisplay({ count }) {
  return (
    <div>
      <Tooltip content={powerTooltips.inverter}>
        <div className="text-sm font-medium text-gray-500">
          {count} {sanctuarySpecs.name} Inverter{count > 1 ? 's' : ''}
        </div>
        <div className="flex flex-wrap justify-center gap-2 px-2">
          {[...Array(count)].map((_, i) => (
            <div key={i} className="relative w-10">
              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                <BoltIcon className="h-5 w-5 text-white" />
              </div>
              <div className="mt-1 text-xs text-gray-500">
                33.3A
              </div>
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
}