export const batteryChemistries = {
  lifepo4: {
    name: 'Lithium Iron Phosphate (LiFePO4)',
    advantages: [
      'Superior thermal stability and safety',
      'Longer cycle life (6000+ cycles)',
      'Safe for indoor installation',
      'Non-toxic and environmentally friendly',
      'More stable chemical structure',
      'Better performance in high temperatures',
      'No thermal runaway risk'
    ],
    applications: [
      'Home energy storage',
      'Indoor installations',
      'Critical backup systems',
      'Long-term energy storage'
    ]
  },
  nmc: {
    name: 'Lithium Nickel Manganese Cobalt (NMC)',
    advantages: [
      'Higher energy density',
      'Lighter weight',
      'Lower initial cost'
    ],
    disadvantages: [
      'Thermal runaway risk',
      'Must be installed outdoors',
      'Shorter cycle life',
      'Contains toxic materials',
      'More sensitive to temperature',
      'Higher fire risk'
    ]
  }
};

export const safetyComparison = [
  {
    aspect: 'Installation Location',
    lifepo4: 'Safe for indoor installation',
    nmc: 'Outdoor installation required'
  },
  {
    aspect: 'Thermal Stability',
    lifepo4: 'Extremely stable up to 500°C',
    nmc: 'Risk of thermal runaway above 150°C'
  },
  {
    aspect: 'Chemical Stability',
    lifepo4: 'Highly stable phosphate bonds',
    nmc: 'Less stable, can release oxygen when damaged'
  },
  {
    aspect: 'Cycle Life',
    lifepo4: '6000+ cycles',
    nmc: '2000-3000 cycles'
  },
  {
    aspect: 'Environmental Impact',
    lifepo4: 'Non-toxic materials, highly recyclable',
    nmc: 'Contains toxic heavy metals'
  }
];