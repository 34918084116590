import { useState } from 'react';
import { event } from '../utils/analytics/gtag';

export function useCalculatorButton(location = 'unknown') {
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  const handleCalculatorOpen = () => {
    // Track the event
    event({
      action: 'open_calculator',
      category: 'Engagement',
      label: location,
      value: 1
    });
    
    setIsCalculatorOpen(true);
  };

  return {
    isCalculatorOpen,
    setIsCalculatorOpen,
    handleCalculatorOpen
  };
}