import { 
  BoltIcon, 
  HomeIcon, 
  ChartBarIcon, 
  TrophyIcon 
} from '@heroicons/react/24/outline';
import { sanctuarySpecs } from '../../../../utils/productSpecs';

export const showcaseFeatures = [
  {
    name: 'Power Security',
    description: 'Keep your home running during outages with instant, reliable backup power.',
    icon: BoltIcon,
    stat: {
      label: 'Continuous Power',
      value: sanctuarySpecs.basicSpecs[1].value
    }
  },
  {
    name: 'Cost Savings',
    description: 'Reduce utility bills with smart energy management and time-of-use optimization.',
    icon: ChartBarIcon,
    stat: {
      label: 'Peak Rate Savings',
      value: '20-30%'
    }
  },
  {
    name: 'Premium Technology',
    description: 'Industry-leading efficiency with integrated inverter and smart monitoring.',
    icon: TrophyIcon,
    stat: {
      label: 'System Efficiency',
      value: sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency
    }
  },
  {
    name: 'Peace of Mind',
    description: 'Comprehensive warranty coverage and professional maintenance support.',
    icon: HomeIcon,
    stat: {
      label: 'Warranty',
      value: sanctuarySpecs.detailedSpecs.warranty.standard
    }
  }
];