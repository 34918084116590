import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import MegaMenuCard from './MegaMenuCard';
import CalculatorButton from '../ui/CalculatorButton';

export default function MegaMenuSection({ title, items, additionalLinks = [] }) {
  const navigate = useNavigate();

  const handleNavigation = (href, close) => {
    close();
    if (href.startsWith('http')) {
      window.open(href, '_blank', 'noopener noreferrer');
    } else if (href === '#calculator') {
      // Do nothing, the calculator button handles its own state
    } else {
      navigate(href);
      window.scrollTo(0, 0);
      if (href.includes('#')) {
        const id = href.split('#')[1];
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className={`
            inline-flex items-center gap-x-1 text-sm font-semibold leading-6 
            outline-none transition-colors duration-200
            ${open ? 'text-green-600' : 'text-gray-900'}
          `}>
            {title}
            <ChevronDownIcon 
              className={`h-5 w-5 transition-transform duration-200 ${open ? 'rotate-180 text-green-600' : ''}`} 
              aria-hidden="true" 
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-5 w-screen max-w-[90rem] -translate-x-1/2">
              <div className="mx-auto w-full max-w-6xl overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-8">
                  <div className="grid grid-cols-3 gap-x-8">
                    {items.map((item) => (
                      <div 
                        key={item.name} 
                        onClick={() => handleNavigation(item.href, close)}
                        className="cursor-pointer"
                      >
                        <MegaMenuCard item={item} />
                      </div>
                    ))}
                  </div>
                  
                  {additionalLinks && additionalLinks.length > 0 && (
                    <>
                      <div className="relative mt-8 pt-8">
                        <div className="absolute inset-x-8 top-0 h-px bg-gray-200" />
                        <div className="grid grid-cols-2 gap-4">
                          {additionalLinks.map((link) => (
                            link.isCalculator ? (
                              <div key={link.name} className="p-3">
                                <CalculatorButton />
                              </div>
                            ) : (
                              <button
                                key={link.name}
                                onClick={() => handleNavigation(link.href, close)}
                                className="group flex items-center gap-x-3 rounded-lg p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 transition-colors duration-200 w-full text-left"
                              >
                                <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white group-hover:ring-1 group-hover:ring-green-100 transition-all duration-200">
                                  <link.icon className="h-5 w-5 text-gray-400 group-hover:text-green-600 transition-colors duration-200" />
                                </div>
                                <span className="group-hover:text-green-600 transition-colors duration-200">
                                  {link.name}
                                </span>
                              </button>
                            )
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}