import React from 'react';
import Container from '../components/layout/Container';
import Button from '../components/ui/Button';
import ProcessSteps from '../components/sections/ProcessSteps';
import WhyAgilio from '../components/sections/WhyAgilio';
import Testimonials from '../components/sections/Testimonials';
import Benefits from '../components/sections/Benefits';
import ServiceVerticals from '../components/sections/ServiceVerticals';
import SEO from '../components/SEO';

export default function HowItWorks() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="How It Works"
        description="Experience a seamless home improvement journey with Agilio's concierge service. We handle everything from consultation to completion, ensuring your satisfaction."
        canonical="/how-it-works"
      />
      
      {/* Hero Section */}
      <Container className="py-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Your Journey to a Better Home
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Experience a seamless home improvement journey with Agilio's concierge service. 
              We handle everything from consultation to completion, ensuring your complete satisfaction.
            </p>
            <div className="mt-10">
              <a
                href="https://agilio.go-kf.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Start Your Project
              </a>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80"
              alt="Home improvement consultation"
              className="rounded-2xl shadow-xl aspect-[4/3] object-cover"
            />
          </div>
        </div>
      </Container>

      {/* Service Verticals */}
      <ServiceVerticals />

      {/* Benefits Section */}
      <Benefits />

      {/* Process Steps */}
      <ProcessSteps />

      {/* Why Choose Agilio */}
      <WhyAgilio />

      {/* Testimonials */}
      <Testimonials />

      {/* CTA Section */}
      <div className="py-24 bg-green-600">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to Transform Your Home?
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Let's start your journey to a better home today. Our experts are ready to help you 
              every step of the way.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://agilio.go-kf.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get Started
              </a>
              <Button href="/products" variant="text" className="text-white">
                Explore Solutions
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}