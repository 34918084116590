import React, { useState } from 'react';
import { MinusIcon, PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { calculatePowerNeeds, formatPowerValue, hasHighStartingCurrent } from '../../../utils/calculators/powerCalculations';
import { commonAppliances, applianceCategories } from '../../../utils/applianceData';
import { powerTooltips } from '../../../utils/tooltips/powerTooltips';
import Tooltip from '../../ui/Tooltip';

export default function ApplianceStep({ data, onUpdate, onNext }) {
  const [selectedAppliances, setSelectedAppliances] = useState(
    data.appliances?.map(app => ({ ...app, quantity: app.quantity || 1 })) || []
  );
  const [selectedCategory, setSelectedCategory] = useState('Essential');

  // Calculate power needs using the utility function
  const powerNeeds = calculatePowerNeeds(selectedAppliances);

  const handleQuantityChange = (appliance, change) => {
    setSelectedAppliances(prev => {
      const existing = prev.find(a => a.name === appliance.name);
      if (!existing && change > 0) {
        return [...prev, { ...appliance, quantity: 1 }];
      }
      
      return prev.map(a => {
        if (a.name !== appliance.name) return a;
        const newQuantity = Math.max(0, (a.quantity || 1) + change);
        return newQuantity === 0 ? null : { ...a, quantity: newQuantity };
      }).filter(Boolean);
    });
  };

  const handleContinue = () => {
    onUpdate({ appliances: selectedAppliances });
    onNext();
  };

  const getCategoryButtonClasses = (category) => {
    return `px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors duration-200 ${
      selectedCategory === category
        ? 'bg-green-600 text-white shadow-sm'
        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
    }`;
  };

  const getAppliancesForCategory = (category) => {
    if (category === 'Essential') {
      return commonAppliances.filter(app => app.essential);
    }
    return commonAppliances.filter(app => 
      app.category === category || (app.essential && category === 'Essential')
    );
  };

  return (
    <div className="space-y-6">
      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-4">
          Select Your Appliances
        </h4>
        <p className="text-sm text-gray-600 mb-6">
          Choose the appliances you want to power during an outage and specify the quantity.
        </p>

        {/* Power Summary */}
        {selectedAppliances.length > 0 && (
          <div className="bg-gray-50 rounded-lg p-4 mb-6">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <Tooltip content={powerTooltips.amps}>
                  <div className="text-sm font-medium text-gray-500">Total Power</div>
                  <div className="mt-1 text-lg font-semibold text-gray-900">
                    {formatPowerValue.amps(powerNeeds.totalAmps)}
                  </div>
                </Tooltip>
                {powerNeeds.hasHighStartingLoads && (
                  <div className="text-xs text-yellow-600 flex items-center gap-x-1 mt-1">
                    <ExclamationTriangleIcon className="h-3 w-3" />
                    {powerNeeds.highStartingCount} appliance{powerNeeds.highStartingCount !== 1 ? 's' : ''} with high starting current
                  </div>
                )}
              </div>
              <div>
                <Tooltip content={powerTooltips.watts}>
                  <div className="text-sm font-medium text-gray-500">Peak Power</div>
                  <div className="mt-1 text-lg font-semibold text-gray-900">
                    {formatPowerValue.kw(powerNeeds.peakPowerKw)}
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip content={powerTooltips.kwh}>
                  <div className="text-sm font-medium text-gray-500">Daily Usage</div>
                  <div className="mt-1 text-lg font-semibold text-gray-900">
                    {formatPowerValue.kwh(powerNeeds.dailyKwh)}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}

        {/* Category Selection */}
        <div className="flex gap-2 overflow-x-auto pb-2 mb-6">
          {['Essential', ...applianceCategories.filter(cat => cat !== 'Essential')].map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={getCategoryButtonClasses(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Appliance List */}
        <div className="space-y-2">
          {getAppliancesForCategory(selectedCategory).map((appliance) => {
            const selected = selectedAppliances.find(a => a.name === appliance.name);
            const quantity = selected?.quantity || 0;
            const showHighCurrentWarning = hasHighStartingCurrent(appliance);
            
            return (
              <div
                key={appliance.name}
                className={`
                  flex items-center justify-between p-4 rounded-lg border transition-colors duration-200
                  ${quantity > 0 
                    ? 'bg-green-50 border-green-200' 
                    : 'bg-white border-gray-200 hover:bg-gray-50'
                  }
                `}
              >
                <div className="flex-1">
                  <div className="flex items-center gap-x-2">
                    <span className="font-medium text-gray-900">{appliance.name}</span>
                    {appliance.essential && (
                      <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                        Essential
                      </span>
                    )}
                    {showHighCurrentWarning && (
                      <div className="flex items-center gap-x-1 text-yellow-600">
                        <ExclamationTriangleIcon className="h-4 w-4" />
                        <span className="text-xs">High starting current</span>
                      </div>
                    )}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">
                    {appliance.avgWatts}W continuous • {appliance.startWatts}W starting • {appliance.hoursPerDay}h/day
                  </div>
                </div>
                
                <div className="flex items-center gap-x-3">
                  {quantity > 0 && (
                    <>
                      <div className="text-sm text-gray-500">
                        {(appliance.avgWatts * quantity).toLocaleString()}W total
                      </div>
                      <button
                        onClick={() => handleQuantityChange(appliance, -1)}
                        className="p-1 rounded-full bg-green-100 text-green-600 hover:bg-green-200"
                      >
                        <MinusIcon className="h-4 w-4" />
                      </button>
                      <span className="w-8 text-center font-medium">{quantity}</span>
                    </>
                  )}
                  <button
                    onClick={() => handleQuantityChange(appliance, 1)}
                    className={`
                      p-1 rounded-full
                      ${quantity > 0
                        ? 'bg-green-100 text-green-600 hover:bg-green-200'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }
                    `}
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Navigation */}
      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleContinue}
          disabled={selectedAppliances.length === 0}
          className="rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Continue
        </button>
      </div>
    </div>
  );
}