import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import Button from '../components/ui/Button';
import OutageStats from '../components/sections/OutageStats';
import TimeOfUseOptimization from '../components/sections/TimeOfUseOptimization';
import GridIntegration from '../components/sections/GridIntegration';
import TechnologyComparison from '../components/sections/TechnologyComparison';
import SanctuarySolution from '../components/sections/SanctuarySolution';
import TaxCredit from '../components/sections/TaxCredit';
import CTASection from '../components/CTASection';

export default function SmartEnergySolutions() {
  return (
    <div className="bg-white">
      <SEO 
        title="Smart Energy Solutions"
        description="Transform your home with comprehensive energy solutions. From backup power to cost savings, discover how our solutions can protect and optimize your home energy."
        canonical="/smart-energy-solutions"
      />

      {/* Hero Section */}
      <div className="relative isolate overflow-hidden pt-24">
        <div className="absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-gray-900/60" />
          <img
            src="https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?ixlib=rb-4.0.3&auto=format&fit=crop&w=2000&q=80"
            alt="Modern home with solar panels"
            className="h-full w-full object-cover"
          />
        </div>

        <Container className="py-24 sm:py-32">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Smart Energy Solutions for Your Home
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Experience true energy independence with our comprehensive solutions. 
              From backup power to cost savings, we have everything you need to power 
              your home smarter.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button href="https://agilio.go-kf.com/contact-us" variant="secondary">Get Started</Button>
              <Button href="#learn-more" variant="text" className="text-white">
                Learn More
              </Button>
            </div>
          </div>
        </Container>
      </div>

      {/* Stats Section */}
      <div id="learn-more">
        <OutageStats />
      </div>

      {/* Time of Use Optimization */}
      <TimeOfUseOptimization />

      {/* Grid Integration */}
      <GridIntegration />

      {/* Sanctuary Solution */}
      <SanctuarySolution />

      {/* Technology Comparison */}
      <TechnologyComparison />

      {/* Tax Credit Section */}
      <TaxCredit />

      {/* CTA Section */}
      <CTASection />
    </div>
  );
}