import React from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import StateLookup from '../../components/netMetering/StateLookup';
import { netMeteringArticle } from '../../utils/articles/netMetering';

export default function NetMetering() {
  return (
    <>
      <SEO 
        title={netMeteringArticle.title}
        description={netMeteringArticle.description}
        canonical="/learning-center/articles/net-metering"
      />
      <ArticleLayout article={netMeteringArticle}>
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Find Programs in Your Area
          </h2>
          <StateLookup />
        </div>

        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3>Ready to Learn More?</h3>
          <p>
            Talk to our experts about maximizing your solar and battery investment with net metering.
          </p>
          <div className="mt-4">
            <a
              href="https://agilio.go-kf.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-500"
            >
              Get Started
            </a>
          </div>
        </div>
      </ArticleLayout>
    </>
  );
}