import { BATTERY_SPECS } from './specs';
import { calculateSystemSize } from './calculations';

export function generateSolutions(powerNeeds, solarData) {
  try {
    // Validate input
    if (!powerNeeds || typeof powerNeeds !== 'object') {
      console.error('Invalid power needs:', powerNeeds);
      return null;
    }

    // Calculate base system size
    const systemSize = calculateSystemSize(powerNeeds);
    if (!systemSize) {
      console.error('Failed to calculate system size');
      return null;
    }

    // Generate solutions with fixed battery ratios per inverter
    const solutions = {
      good: generateSolution(systemSize, 1), // 1 battery per inverter
      better: generateSolution(systemSize, 2), // 2 batteries per inverter
      best: generateSolution(systemSize, 3) // 3 batteries per inverter (max)
    };

    console.log('Generated solutions:', solutions);
    return solutions;
  } catch (error) {
    console.error('Error generating solutions:', error);
    return null;
  }
}

function generateSolution(systemSize, batteriesPerInverter) {
  // Ensure we have at least one inverter
  const inverters = Math.max(systemSize.inverterCount, 1);
  
  // Calculate batteries based on fixed ratio
  const batteries = inverters * batteriesPerInverter;

  // Calculate system metrics
  const totalPower = Number((batteries * BATTERY_SPECS.power).toFixed(2));
  const totalCapacity = Number((batteries * BATTERY_SPECS.capacity).toFixed(2));
  
  // Calculate backup time based on daily usage
  const usableCapacity = totalCapacity * BATTERY_SPECS.roundTripEfficiency;
  const backupTime = Number(((usableCapacity / systemSize.dailyKwh) * 24).toFixed(1));

  return {
    inverters,
    batteries,
    backupTime,
    totalPower,
    totalCapacity
  };
}