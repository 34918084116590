import React from 'react';
import SEO from '../../components/SEO';
import Container from '../../components/layout/Container';
import { TruckIcon, BuildingOfficeIcon, ChartBarIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const benefits = [
  {
    name: 'Market Coverage',
    description: 'Expand your distribution network with our established presence.',
    icon: TruckIcon,
    features: [
      'Territory expansion',
      'Market penetration',
      'Local presence',
      'Coverage optimization'
    ]
  },
  {
    name: 'Supply Chain',
    description: 'Streamlined supply chain and inventory management.',
    icon: BuildingOfficeIcon,
    features: [
      'Inventory optimization',
      'Demand forecasting',
      'Quality control',
      'Logistics support'
    ]
  },
  {
    name: 'Performance Tracking',
    description: 'Comprehensive analytics and reporting tools.',
    icon: ChartBarIcon,
    features: [
      'Sales analytics',
      'Performance metrics',
      'Market insights',
      'Trend analysis'
    ]
  },
  {
    name: 'Partner Network',
    description: 'Access to qualified partners and customers.',
    icon: UserGroupIcon,
    features: [
      'Installer network',
      'Customer base',
      'Training programs',
      'Support resources'
    ]
  }
];

export default function Distributors() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Distributor Partnerships"
        description="Partner with Agilio to optimize your distribution network and expand market coverage."
        canonical="/partnerships/distributors"
      />
      
      <div className="relative isolate overflow-hidden bg-green-600 py-24">
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Distributor Partnerships
            </h1>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-green-100">
              Optimize your distribution network and expand market coverage through our established platform.
            </p>
          </div>
        </Container>
      </div>

      <Container className="py-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {benefits.map((benefit) => (
            <div key={benefit.name} className="relative p-8 bg-gray-50 rounded-2xl">
              <div className="flex items-center gap-x-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <benefit.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{benefit.name}</h3>
              </div>
              <p className="mt-4 text-gray-600">{benefit.description}</p>
              <ul className="mt-8 space-y-3">
                {benefit.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-x-3">
                    <div className="h-2 w-2 rounded-full bg-green-600" />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}