import React from 'react';
import SEO from '../../components/SEO';
import ArticleLayout from '../../components/articles/ArticleLayout';
import { maintenanceGuideArticle } from '../../utils/articles/maintenanceGuide';

export default function MaintenanceGuide() {
  return (
    <>
      <SEO 
        title={maintenanceGuideArticle.title}
        description={maintenanceGuideArticle.description}
        canonical="/learning-center/articles/maintenance-tips"
      />
      <ArticleLayout article={maintenanceGuideArticle}>
        {/* Call to Action */}
        <div className="mt-16 border-t border-gray-200 pt-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Need Professional Maintenance?</h3>
          <p className="text-gray-600 mb-6">
            Our expert technicians can help ensure your battery system performs optimally. We offer:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>Comprehensive system inspections</li>
            <li>Performance optimization</li>
            <li>Preventive maintenance</li>
            <li>24/7 monitoring and support</li>
          </ul>
          <a
            href="https://agilio.go-kf.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white hover:bg-green-500"
          >
            Schedule Maintenance
          </a>
        </div>
      </ArticleLayout>
    </>
  );
}