import React from 'react';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import BatteryCalculator from '../calculator/BatteryCalculator';
import { useCalculatorButton } from '../../hooks/useCalculatorButton';

export default function HeroCalculatorButton({ className = '' }) {
  const { isCalculatorOpen, setIsCalculatorOpen, handleCalculatorOpen } = useCalculatorButton('hero');

  return (
    <>
      <button
        onClick={handleCalculatorOpen}
        className={`inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white ${className}`}
      >
        <CalculatorIcon className="h-5 w-5" />
        Size Your System
      </button>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </>
  );
}