export const commonAppliances = [
  // Refrigeration
  {
    name: 'Refrigerator',
    category: 'Kitchen',
    avgWatts: 150,
    startWatts: 1200,
    hoursPerDay: 24,
    essential: true,
  },
  {
    name: 'Chest Freezer',
    category: 'Kitchen',
    avgWatts: 100,
    startWatts: 800,
    hoursPerDay: 24,
    essential: true,
  },

  // HVAC & Water
  {
    name: 'HVAC System',
    category: 'Climate',
    avgWatts: 3500,
    startWatts: 4900,
    hoursPerDay: 8,
    essential: true,
  },
  {
    name: 'Furnace Fan',
    category: 'Climate',
    avgWatts: 800,
    startWatts: 2000,
    hoursPerDay: 8,
    essential: true,
  },
  {
    name: 'Sump Pump',
    category: 'Essential',
    avgWatts: 800,
    startWatts: 2400,
    hoursPerDay: 1,
    essential: true,
  },
  {
    name: 'Well Pump',
    category: 'Essential',
    avgWatts: 1000,
    startWatts: 3000,
    hoursPerDay: 2,
    essential: true,
  },
  {
    name: 'Water Heater',
    category: 'Essential',
    avgWatts: 4500,
    startWatts: 4500,
    hoursPerDay: 3,
    essential: false,
  },

  // Kitchen Appliances
  {
    name: 'Microwave',
    category: 'Kitchen',
    avgWatts: 1000,
    startWatts: 1500,
    hoursPerDay: 0.5,
    essential: false,
  },
  {
    name: 'Electric Oven',
    category: 'Kitchen',
    avgWatts: 2400,
    startWatts: 2400,
    hoursPerDay: 1,
    essential: false,
  },
  {
    name: 'Electric Stove (per burner)',
    category: 'Kitchen',
    avgWatts: 1500,
    startWatts: 1500,
    hoursPerDay: 1,
    essential: false,
  },
  {
    name: 'Dishwasher',
    category: 'Kitchen',
    avgWatts: 1800,
    startWatts: 2400,
    hoursPerDay: 1,
    essential: false,
  },
  {
    name: 'Coffee Maker',
    category: 'Kitchen',
    avgWatts: 1000,
    startWatts: 1000,
    hoursPerDay: 0.5,
    essential: false,
  },
  {
    name: 'Toaster',
    category: 'Kitchen',
    avgWatts: 850,
    startWatts: 850,
    hoursPerDay: 0.2,
    essential: false,
  },

  // Laundry
  {
    name: 'Washing Machine',
    category: 'Laundry',
    avgWatts: 500,
    startWatts: 1200,
    hoursPerDay: 1.5,
    essential: false,
  },
  {
    name: 'Electric Dryer',
    category: 'Laundry',
    avgWatts: 3000,
    startWatts: 5000,
    hoursPerDay: 1.5,
    essential: false,
  },

  // Entertainment & Office
  {
    name: 'TV (LED 55")',
    category: 'Entertainment',
    avgWatts: 100,
    startWatts: 100,
    hoursPerDay: 4,
    essential: false,
  },
  {
    name: 'Computer & Monitor',
    category: 'Office',
    avgWatts: 200,
    startWatts: 200,
    hoursPerDay: 8,
    essential: false,
  },
  {
    name: 'Router/Modem',
    category: 'Office',
    avgWatts: 20,
    startWatts: 20,
    hoursPerDay: 24,
    essential: true,
  },
  {
    name: 'Gaming Console',
    category: 'Entertainment',
    avgWatts: 150,
    startWatts: 150,
    hoursPerDay: 2,
    essential: false,
  },

  // Lighting
  {
    name: 'LED Lights (10 bulbs)',
    category: 'Lighting',
    avgWatts: 70,
    startWatts: 70,
    hoursPerDay: 6,
    essential: true,
  },

  // Medical & Security
  {
    name: 'CPAP Machine',
    category: 'Medical',
    avgWatts: 60,
    startWatts: 60,
    hoursPerDay: 8,
    essential: true,
  },
  {
    name: 'Security System',
    category: 'Essential',
    avgWatts: 100,
    startWatts: 100,
    hoursPerDay: 24,
    essential: true,
  },
  {
    name: 'Garage Door Opener',
    category: 'Essential',
    avgWatts: 550,
    startWatts: 1100,
    hoursPerDay: 0.2,
    essential: true,
  },

  // Outdoor & Garage
  {
    name: 'Electric Car Charger',
    category: 'Garage',
    avgWatts: 7200,
    startWatts: 7200,
    hoursPerDay: 4,
    essential: false,
  },
  {
    name: 'Pool Pump',
    category: 'Outdoor',
    avgWatts: 1500,
    startWatts: 3000,
    hoursPerDay: 8,
    essential: false,
  }
];

export const applianceCategories = [
  'Essential',
  'Kitchen',
  'Climate',
  'Laundry',
  'Entertainment',
  'Office',
  'Medical',
  'Lighting',
  'Garage',
  'Outdoor'
];