export function getMetadata() {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    source: window.location.pathname,
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    referrer: document.referrer,
    pageUrl: window.location.href,
    submittedAt: new Date().toISOString(),
    browserLanguage: navigator.language,
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    utmSource: searchParams.get('utm_source') || '',
    utmMedium: searchParams.get('utm_medium') || '',
    utmCampaign: searchParams.get('utm_campaign') || '',
    utmTerm: searchParams.get('utm_term') || '',
    utmContent: searchParams.get('utm_content') || ''
  };
}