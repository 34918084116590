import { 
  BoltIcon, 
  CurrencyDollarIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';

export const solutions = [
  {
    name: 'Power Outage Protection',
    description: 'Keep your home running during blackouts with reliable backup power',
    href: '/outage',
    icon: BoltIcon,
    features: [
      'Instant power switchover',
      'Whole-home backup options',
      '24/7 monitoring',
    ]
  },
  {
    name: 'Energy Cost Savings',
    description: 'Reduce utility bills with smart energy management and solar integration',
    href: '/saveonenergy',
    icon: CurrencyDollarIcon,
    features: [
      'Time-of-use optimization',
      'Net metering benefits',
      'Peak rate avoidance',
    ]
  },
  {
    name: 'HVAC Solutions',
    description: 'Expert installation and service of premium heating and cooling systems',
    href: '/hvac',
    icon: WrenchScrewdriverIcon,
    features: [
      'Premium equipment brands',
      'Expert installation',
      'Professional maintenance',
    ]
  }
];