import React from 'react';
import { sanctuarySpecs } from '../../../../utils/productSpecs';
import { showcaseFeatures } from './features';
import FeatureCard from './FeatureCard';

export default function FeatureGrid() {
  return (
    <div className="space-y-8">
      {showcaseFeatures.map((feature) => (
        <FeatureCard 
          key={feature.name}
          feature={feature}
          specs={sanctuarySpecs}
        />
      ))}
    </div>
  );
}