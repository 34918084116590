import { useState } from 'react';
import { calculatePowerNeeds } from '../../../../utils/calculators/powerCalculations';
import { generateSolutions } from '../../../../utils/calculators/batteryCalculator/solutions';

export function useRecommendationStep(data) {
  const [error, setError] = useState(null);

  try {
    // Calculate power needs and solutions
    const powerNeeds = calculatePowerNeeds(data.appliances);
    const solutions = generateSolutions(powerNeeds, data.solar);

    return {
      powerNeeds,
      solutions,
      error
    };
  } catch (error) {
    console.error('Error calculating recommendations:', error);
    setError(error.message || 'Failed to calculate system requirements');
    return {
      powerNeeds: null,
      solutions: null,
      error
    };
  }
}