import React from 'react';
import { 
  ShieldCheckIcon, 
  BoltIcon, 
  BeakerIcon, 
  BuildingOfficeIcon,
  TrophyIcon
} from '@heroicons/react/24/outline';
import Container from '../layout/Container';
import { sanctuarySpecs } from '../../utils/productSpecs';

const advantages = [
  {
    name: 'Superior Battery Chemistry',
    description: 'Our Lithium Iron Phosphate (LiFePO4) batteries offer unmatched safety, longevity, and performance compared to traditional lithium-ion batteries.',
    icon: BeakerIcon,
    stats: {
      label: 'Battery Life',
      value: '6000+ cycles'
    }
  },
  {
    name: 'Industry-Leading Efficiency',
    description: `With ${sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency} round-trip efficiency and integrated inverter technology, the Sanctuary system maximizes every bit of stored energy.`,
    icon: BoltIcon,
    stats: {
      label: 'System Efficiency',
      value: sanctuarySpecs.detailedSpecs.performance.roundTripEfficiency
    }
  },
  {
    name: 'Best-in-Class Warranty',
    description: `Backed by a comprehensive ${sanctuarySpecs.detailedSpecs.warranty.standard} standard warranty, extendable to ${sanctuarySpecs.detailedSpecs.warranty.extended} for complete peace of mind.`,
    icon: ShieldCheckIcon,
    stats: {
      label: 'Warranty Coverage',
      value: sanctuarySpecs.detailedSpecs.warranty.standard
    }
  },
  {
    name: 'American Innovation',
    description: 'Designed and engineered in the USA with rigorous quality control and compliance with all major safety standards.',
    icon: BuildingOfficeIcon,
    stats: {
      label: 'Made In',
      value: 'USA'
    }
  },
  {
    name: 'Premium Performance',
    description: `Delivers ${sanctuarySpecs.basicSpecs[1].value} of continuous power with ${sanctuarySpecs.basicSpecs[2].value} peak capacity for demanding applications.`,
    icon: TrophyIcon,
    stats: {
      label: 'Continuous Power',
      value: sanctuarySpecs.basicSpecs[1].value
    }
  }
];

export default function WhyLionEnergy() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">Why Lion Energy</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The Lion Energy Advantage
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            When it comes to home energy storage, not all solutions are created equal. 
            Discover why Lion Energy Sanctuary stands above the competition.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {advantages.map((advantage) => (
            <div
              key={advantage.name}
              className="flex flex-col bg-white rounded-2xl shadow-sm ring-1 ring-gray-200 p-8"
            >
              <div className="flex items-center gap-x-4 mb-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                  <advantage.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{advantage.name}</h3>
              </div>
              
              <p className="flex-1 text-base text-gray-600 mb-6">
                {advantage.description}
              </p>

              <div className="rounded-lg bg-green-50 p-4">
                <div className="text-sm font-medium text-gray-500">{advantage.stats.label}</div>
                <div className="mt-1 text-2xl font-semibold text-green-600">{advantage.stats.value}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 rounded-3xl bg-green-600 px-8 py-16">
          <div className="mx-auto max-w-2xl text-center">
            <h3 className="text-2xl font-bold tracking-tight text-white">
              Ready to Experience the Difference?
            </h3>
            <p className="mt-4 text-lg text-green-100">
              Join thousands of satisfied homeowners who trust Lion Energy for their home energy storage needs.
            </p>
            <div className="mt-8">
              <a
                href="https://agilio.go-kf.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-50"
              >
                Get Started Today
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}