export const netMeteringArticle = {
  id: 'net-metering',
  title: 'Understanding Net Metering: A Complete Guide',
  description: 'Learn how net metering works, its benefits, and state-by-state policies.',
  content: {
    introduction: `
      Net metering is a powerful incentive that can significantly improve the economics of your solar investment. This billing mechanism allows you to earn credits for excess solar energy you send to the grid, effectively using the grid as a giant battery. Understanding how net metering works and the policies in your area is crucial for maximizing your solar and battery investment.
    `,
    sections: [
      {
        title: 'How Net Metering Works',
        content: `
          Net metering transforms how your home interacts with the power grid, creating a two-way relationship between your home and your utility company. Here's how the process works:

          During Peak Solar Production
          • Your solar panels generate more power than your home uses
          • Excess electricity flows back to the grid
          • Your meter runs backward, accumulating credits
          • These credits offset future energy use

          During Low/No Solar Production
          • Your home draws power from the grid as needed
          • Previously earned credits are applied to your bill
          • You only pay for your "net" energy use
          • The grid acts as virtual energy storage

          This system provides several key benefits:
          • Reduces or eliminates electricity bills
          • Maximizes the value of your solar investment
          • Provides grid stability benefits
          • Encourages renewable energy adoption
        `
      },
      {
        title: 'State Policies and Requirements',
        content: `
          Net metering policies vary significantly by state, with some offering more favorable terms than others. Here's a detailed look at key state programs:

          California (NEM-3.0)
          • Implemented April 2023
          • Credits based on "Avoided Cost Calculator"
          • Requires time-of-use rate plans
          • Export rates vary by time and season

          New York (VDER)
          • Value Stack tariff system
          • Credits based on multiple value components
          • Location-based compensation
          • Environmental benefit payments

          Massachusetts (SMART Program)
          • Fixed incentive rates for 10 years
          • Additional storage adder available
          • Block-based compensation structure
          • Income-based incentive multipliers

          Arizona
          • Modified net metering with reduced credit rates
          • Grid access fees may apply
          • Time-of-use rate requirements
          • Battery storage incentives available

          Nevada
          • Tiered rate structure for exports
          • 80% retail rate compensation
          • Time-of-use rate options
          • Battery integration benefits
        `
      },
      {
        title: 'Utility Programs',
        content: `
          Major utilities have developed specific programs to handle solar and battery integration. Here are some notable examples:

          Pacific Gas & Electric (PG&E)
          • NEM-3.0 program with time-varying rates
          • Smart rate options for battery systems
          • Self-generation incentive program (SGIP)
          • Virtual power plant opportunities

          Southern California Edison
          • NEM-3.0 with peak period focus
          • Battery storage incentives
          • Grid reliability programs
          • Commercial virtual net metering

          ConEdison (NY)
          • Value Stack compensation
          • Dynamic Load Management programs
          • Battery storage incentives
          • Community solar options

          Duke Energy
          • Varying rates by state
          • Smart meter requirements
          • Battery pilot programs
          • Time-of-use options
        `
      }
    ]
  }
};