import React from 'react';
import { CalculatorIcon } from '@heroicons/react/24/outline';
import BatteryCalculator from '../calculator/BatteryCalculator';
import { useCalculatorButton } from '../../hooks/useCalculatorButton';

export default function CalculatorButton({ variant = 'primary', className = '', location = 'nav' }) {
  const { isCalculatorOpen, setIsCalculatorOpen, handleCalculatorOpen } = useCalculatorButton(location);

  const buttonStyles = {
    primary: 'bg-green-600 text-white hover:bg-green-500',
    secondary: 'bg-white text-green-600 hover:bg-green-50'
  };

  return (
    <>
      <button
        onClick={handleCalculatorOpen}
        className={`inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${buttonStyles[variant]} ${className}`}
      >
        <CalculatorIcon className="h-5 w-5" />
        Size Your System
      </button>

      <BatteryCalculator 
        isOpen={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
      />
    </>
  );
}