import React from 'react';
import { useGetStarted } from '../../hooks/useGetStarted';

export default function GetStartedButton({ source, className = '' }) {
  const handleGetStarted = useGetStarted();

  return (
    <button
      onClick={() => handleGetStarted(source)}
      className={`rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${className}`}
    >
      Get Started
    </button>
  );
}