import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import TermlyWidget from '../components/TermlyWidget';

export default function PrivacyPolicy() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Privacy Policy"
        description="Agilio's Privacy Policy - Learn how we collect, use, and protect your personal information."
        canonical="/privacy-policy"
      />
      <Container>
        <div className="mx-auto max-w-4xl py-12">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
            Privacy Policy
          </h1>
          <TermlyWidget policyId="d03135e2-27c3-4d5e-badd-3d355c71b9bd" />
        </div>
      </Container>
    </div>
  );
}