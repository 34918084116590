import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { generateCoverPage } from './templates/coverPage';
import { generateRequirementsPage } from './templates/requirementsPage';
import { generateSolutionsPage } from './templates/solutionsPage';

export async function generateProposal(data, powerNeeds, solutions) {
  try {
    console.log('Starting PDF generation');
    
    // Create container for content
    const container = document.createElement('div');
    container.style.width = '794px'; // A4 width at 96 DPI
    container.style.margin = '0';
    container.style.padding = '40px';
    container.style.background = 'white';
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    container.style.top = '0';

    // Generate content for each page
    const pages = [
      generateCoverPage(),
      generateRequirementsPage(data, powerNeeds),
      generateSolutionsPage(solutions)
    ];

    // Add pages to container with proper page breaks
    container.innerHTML = pages.map(page => `
      <div style="height: 1123px; page-break-after: always;">
        ${page}
      </div>
    `).join('');

    // Add to DOM temporarily
    document.body.appendChild(container);

    try {
      // Wait for fonts and images to load
      await document.fonts.ready;
      await Promise.all(
        Array.from(container.getElementsByTagName('img'))
          .map(img => img.complete ? Promise.resolve() : new Promise(resolve => {
            img.onload = resolve;
            img.onerror = resolve;
          }))
      );

      // Create PDF
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      // Convert each page to canvas and add to PDF
      for (let i = 0; i < pages.length; i++) {
        const pageElement = container.children[i];
        
        // Convert page to canvas
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          backgroundColor: '#FFFFFF',
          logging: false
        });

        // Add new page if not first page
        if (i > 0) {
          pdf.addPage();
        }

        // Add image to PDF
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(
          imgData, 
          'JPEG', 
          0, 
          0, 
          pdf.internal.pageSize.getWidth(), 
          pdf.internal.pageSize.getHeight()
        );
      }

      // Save PDF
      pdf.save('energy-recommendation.pdf');
      console.log('PDF generated successfully');
      
      return true;
    } finally {
      // Cleanup
      document.body.removeChild(container);
    }
  } catch (error) {
    console.error('PDF generation failed:', error);
    throw error;
  }
}