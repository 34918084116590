import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function StateCard({ state }) {
  return (
    <div className="bg-gray-50 rounded-lg p-6 mb-8">
      <h4 className="text-xl font-semibold text-gray-900 mb-4">
        {state.name} Net Metering Policy
      </h4>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h5 className="text-sm font-medium text-gray-700 mb-2">Policy Details</h5>
          <ul className="space-y-2">
            <li className="flex items-center gap-x-2">
              <CheckIcon className="h-5 w-5 text-green-600" />
              <span className="text-sm text-gray-600">
                {state.hasNetMetering ? 'Net Metering Available' : 'Alternative Program Available'}
              </span>
            </li>
            <li className="flex items-center gap-x-2">
              <CheckIcon className="h-5 w-5 text-green-600" />
              <span className="text-sm text-gray-600">
                {state.creditRate} credit rate
              </span>
            </li>
            {state.aggregation && (
              <li className="flex items-center gap-x-2">
                <CheckIcon className="h-5 w-5 text-green-600" />
                <span className="text-sm text-gray-600">
                  Meter aggregation allowed
                </span>
              </li>
            )}
          </ul>
        </div>

        <div>
          <h5 className="text-sm font-medium text-gray-700 mb-2">Program Features</h5>
          <ul className="space-y-2">
            {state.features.map((feature) => (
              <li key={feature} className="flex items-center gap-x-2">
                <CheckIcon className="h-5 w-5 text-green-600" />
                <span className="text-sm text-gray-600">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {state.notes && (
        <div className="mt-6 bg-yellow-50 rounded-lg p-4">
          <p className="text-sm text-yellow-800">{state.notes}</p>
        </div>
      )}
    </div>
  );
}