import React from 'react';
import { 
  ExclamationTriangleIcon, 
  ClockIcon, 
  CurrencyDollarIcon,
  UserGroupIcon 
} from '@heroicons/react/24/outline';
import Container from '../../../components/layout/Container';

const challenges = [
  {
    name: 'Time-Consuming Research',
    description: 'Hours spent researching products, comparing options, and reading reviews. The overwhelming amount of information makes decision-making difficult.',
    icon: ClockIcon,
    stats: {
      label: 'Average Time Spent',
      value: '15-20',
      unit: 'hours'
    }
  },
  {
    name: 'Contractor Uncertainty',
    description: 'Finding reliable contractors is a gamble. Horror stories of poor workmanship, delays, and communication issues are all too common.',
    icon: UserGroupIcon,
    stats: {
      label: 'Contractor Issues',
      value: '35%',
      unit: 'of projects'
    }
  },
  {
    name: 'Hidden Costs',
    description: 'Unexpected expenses and price variations between contractors make budgeting difficult. Many face cost overruns during projects.',
    icon: CurrencyDollarIcon,
    stats: {
      label: 'Average Overrun',
      value: '23%',
      unit: 'of budget'
    }
  },
  {
    name: 'Project Coordination',
    description: 'Managing multiple contractors, schedules, and permits becomes a full-time job. Homeowners are left to coordinate everything themselves.',
    icon: ExclamationTriangleIcon,
    stats: {
      label: 'Project Delays',
      value: '45%',
      unit: 'of cases'
    }
  }
];

export default function MarketChallenges() {
  return (
    <div className="py-24 bg-gray-50">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <h2 className="text-base font-semibold leading-7 text-green-600">The Challenge</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why Home Improvements Are Hard
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Home improvement projects are often overwhelming, time-consuming, and stressful. 
            Here's why homeowners struggle with traditional approaches.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {challenges.map((challenge) => (
            <div 
              key={challenge.name}
              className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-sm ring-1 ring-gray-200"
            >
              <div className="flex items-center gap-x-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-green-600">
                  <challenge.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{challenge.name}</h3>
              </div>
              
              <p className="mt-6 text-gray-600">{challenge.description}</p>
              
              <div className="mt-8 inline-flex items-baseline rounded-full bg-green-50 px-4 py-2">
                <dt className="text-sm font-medium text-gray-600">{challenge.stats.label}:</dt>
                <dd className="ml-2">
                  <span className="text-2xl font-semibold text-green-600">{challenge.stats.value}</span>
                  <span className="text-sm text-gray-500 ml-1">{challenge.stats.unit}</span>
                </dd>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}