import React from 'react';
import { 
  ChatBubbleBottomCenterIcon, 
  ClipboardDocumentCheckIcon, 
  WrenchIcon 
} from '@heroicons/react/24/outline';
import Container from '../../../components/layout/Container';

const steps = [
  {
    id: 1,
    name: 'Consultation',
    description: 'Share your needs with our experts and get personalized recommendations.',
    icon: ChatBubbleBottomCenterIcon,
  },
  {
    id: 2,
    name: 'Selection',
    description: 'Choose from curated solutions that perfectly match your requirements.',
    icon: ClipboardDocumentCheckIcon,
  },
  {
    id: 3,
    name: 'Installation',
    description: 'Relax while our vetted professionals handle the entire installation.',
    icon: WrenchIcon,
  },
];

export default function ProcessSteps() {
  return (
    <div className="py-24 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-green-600">Simple Process</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How Agilio Works
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We've simplified the home improvement process into three easy steps.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3">
            {steps.map((step) => (
              <div key={step.id} className="flex flex-col items-center text-center">
                <div className="rounded-lg bg-green-100 p-4 mb-6">
                  <step.icon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{step.name}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}