import React from 'react';
import SEO from '../components/SEO';
import Container from '../components/layout/Container';
import TermlyWidget from '../components/TermlyWidget';

export default function TermsOfService() {
  return (
    <div className="bg-white pt-24">
      <SEO 
        title="Terms of Service"
        description="Agilio's Terms of Service - Read about our terms and conditions for using our services."
        canonical="/terms-of-service"
      />
      <Container>
        <div className="mx-auto max-w-4xl py-12">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
            Terms of Service
          </h1>
          <TermlyWidget policyId="3082cab6-1a0b-4a1e-b711-dc2c2827e2c8" />
        </div>
      </Container>
    </div>
  );
}