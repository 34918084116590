export const newYork = {
  name: 'New York',
  hasNetMetering: true,
  creditRate: 'Value Stack',
  aggregation: true,
  features: [
    'Value of Distributed Energy Resources (VDER)',
    'Community solar options',
    'Battery storage incentives',
    'Remote crediting available'
  ],
  notes: 'New York uses the Value Stack tariff system which provides compensation based on when and where electricity is exported to the grid.',
  utilities: [
    {
      name: 'Con Edison',
      description: 'Serves New York City and Westchester County',
      programUrl: 'https://www.coned.com/en/save-money/using-private-generation-energy-sources/solar-energy',
      features: [
        'Value Stack compensation',
        'Smart Home Rate',
        'Battery storage incentives',
        'Community solar options'
      ]
    },
    {
      name: 'National Grid',
      description: 'Serves upstate New York',
      programUrl: 'https://www.nationalgridus.com/upstate-ny-home/energy-saving-programs/solar-energy',
      features: [
        'Value Stack tariff',
        'Battery incentive program',
        'Community distributed generation',
        'Time-of-use rates'
      ]
    },
    {
      name: 'PSEG Long Island',
      description: 'Serves Long Island and the Rockaways',
      programUrl: 'https://www.psegliny.com/saveenergyandmoney/solarenergy',
      features: [
        'Value Stack program',
        'Dynamic Load Management',
        'Solar Communities program',
        'Energy storage programs'
      ]
    },
    {
      name: 'Central Hudson',
      description: 'Serves the mid-Hudson Valley',
      programUrl: 'https://www.cenhud.com/my-energy/save-energy-money/solar-energy/',
      features: [
        'Value of DER tariff',
        'Battery storage options',
        'Net crediting',
        'Time-of-use rates'
      ]
    },
    {
      name: 'Orange & Rockland',
      description: 'Serves parts of southeastern New York',
      programUrl: 'https://www.oru.com/en/save-money/using-private-generation-energy-sources/solar-energy',
      features: [
        'Value Stack billing',
        'Storage incentives',
        'Community solar',
        'Smart meter integration'
      ]
    },
    {
      name: 'Rochester Gas & Electric',
      description: 'Serves the Rochester region',
      programUrl: 'https://www.rge.com/wps/portal/rge/saveenergy/solarenergy',
      features: [
        'Value Stack compensation',
        'Battery programs',
        'Distributed generation',
        'Time-varying rates'
      ]
    },
    {
      name: 'New York State Electric & Gas',
      description: 'Serves parts of upstate New York',
      programUrl: 'https://www.nyseg.com/wps/portal/nyseg/saveenergy/renewableenergy',
      features: [
        'VDER program',
        'Energy storage options',
        'Community generation',
        'Smart energy programs'
      ]
    }
  ]
};